import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../../../services';

@Component({
  selector: 'auth-redirect-password',
  templateUrl: './auth-redirect.component.html',
  styleUrls: ['./auth-redirect.component.scss']
})
export class AuthRedirectComponent implements OnInit {

  protected readonly redirectWhitelist: string[] = [
    'https://help.orga.semco.app/'
  ];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthorizationService
  ) {
  }

  ngOnInit() {
    this.refreshToken()
      .then(_ => this.redirectToCaller())
      .catch(_ => this.redirectToHome());
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }

  redirectToCaller() {
    const url = decodeURI(this.route.snapshot.queryParamMap.get('r') || '').trim().toLowerCase();
    const isUrlWhitelisted = this.redirectWhitelist.some(w => url.startsWith(w));

    if (isUrlWhitelisted) {
      window.location.href = url;
    } else {
      this.redirectToHome();
    }
  }

  async refreshToken() {
    const refreshToken = this.authService.getToken();
    return this.authService.refreshLogin(refreshToken, ...this.authService.getSelectedRole());
  }
}
