import { AutocompleteService } from '../../services/autocomplete/autocomplete.service';
import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFilterField, IPageableReq } from '../interfaces/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, switchMap, finalize } from 'rxjs/operators';
import { TableConfig } from '../constants/table-config';
import { FilterDto } from '../models/server/DataTransferObject/Filters';

export class AutocompleteBase implements OnInit {

  @Input() defaultFilter: FilterDto[] = [];

  @Input() readonly: boolean;

  @Input() initId: number;

  @Input() types: string;

  @Input() url: string;

  @Input()
  set items(value) {
    this._initialValue = [...value].filter(el => !!el);
    this._items = [...this._items, ...this._initialValue];
  }

  @Input() multiSelect: boolean;

  @Input() initOnStart = true;

  @Input() selectFirst = false;

  @Input() sort;

  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();

  public idKey: string;

  public typeaheadValue$: Subject<string> = new Subject<string>();

  public selected: any;

  // protected url: string;

  public _items: Array<any> = [];

  public _loading: boolean;

  protected _items$: Observable<any[]>;

  protected _filter: Array<IFilterField> = [];

  private _initialValue = [];

  private _itemBeforeFetch = 10;

  private _currentPage = 1;

  private _totalPage: number;

  private _timer: any;

  constructor(
    protected autocompleteService: AutocompleteService,
    protected _pageSize: number = TableConfig.pageSize
  ) {
  }

  async getAll() {
    try {
      this._loading = true;
      var result = this.autocompleteService.getByCount(this.url, this._currentPage, this._filter, this._pageSize, this.sort)
        .pipe(
            tap((response: IPageableReq) => {
              this._items = response['items'];
              this._totalPage = response['paging'].totalPages;
            },
            switchMap(response => response['items']),
          ));

      await result.toPromise();   
    } finally {
      this._loading = false;
    }
  }

  filterItems(items: any[], idKey: string) {
    // if (items.length) {
    //   this._items = items.filter(el => {
    //     return !!this._initialValue.find(value => value[idKey] !== el[idKey]);
    //   });
    // }
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onClear(e) {
    this.changeValue.next('');
    event.stopPropagation();
  }

  async resetItems() {
    const result = await this.getAll();
    // if (result) {
    // this.typeaheadHandler(this._items, this.idKey);
    // }
    this.selected = [];
  }

  clearSelected() {
    this.initOnStart = false;
    this.selected = this.multiSelect ? [] : '';
  }

  typeaheadHandler(items: Array<any>, idKey: string) {
    this.idKey = idKey;
    if (this.multiSelect && this._items[0] && this.initOnStart) {
      // this.selected = [this._items[0][idKey]];
      // this.changeValue.next([this._items[0]]);
    } else if (!this.multiSelect && this._items[0] && this.initOnStart && this.selectFirst) {
      this.selected = this._items[0][idKey];
      this.changeValue.next(this._items[0]);
    }
  }

  onScroll({ end }) {
    if (this._loading || this._currentPage === this._totalPage) {
      return;
    }

    if (end + this._itemBeforeFetch >= this._items.length) {
      this.fetchMore();
    }
  }

  buildFilter(propertyName: string, value: any) {

    if (Array.isArray(value)) {
      this._filter = [];
      value.forEach(el => this._filter.push({ Property: propertyName, Value: el ? el : '' }));
    } else {
      this._filter = [{ Property: propertyName, Value: value ? value : '' }];
    }

    if (this.defaultFilter) {
      this._filter = [...this._filter, ...this.defaultFilter];
    }

    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      this._currentPage = 0;
      this._items = [];
      this.fetchMore();
    }, 600);
  }

  private fetchMore() {
    if(this._loading || !this._totalPage) return;
    
    if (!(this._currentPage === this._totalPage)) {
      this._loading = true;
      this._currentPage++;
      this.autocompleteService.getByCount(this.url, this._currentPage, this._filter, this._pageSize, this.sort)
        .subscribe((response: IPageableReq) => {
          this._responseHandling(response);
        }, err => {
          this._loading = false;
        });
      return;
    }
  }

  private _responseHandling(response: IPageableReq) {
    this._loading = false;
    if (response['paging'].pageNumber >= this._currentPage) {
      this._items = this._items.concat(response['items']);
    } else {
      this._items = response['items'];
    }
    this._currentPage = response['paging'].pageNumber;
  }

  addItems(items: any[], reset: boolean = false) {
    if (reset) {
      this._items = [];
    }
    this._items = [...this._items, ...items];
  }

  setValue<T>(value: any) {
    this.selected = value;
  }

  ngOnInit() {
  }

}
