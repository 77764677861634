import { Injectable } from '@angular/core';
import {IAlertBlock} from '../../../shared/interfaces/ui';
import {Subject} from 'rxjs';

@Injectable()
export class AlertBlockService {
  alerts: IAlertBlock[] = [];
  subscribe$: Subject<IAlertBlock[]> = new Subject<IAlertBlock[]>();

  constructor() { }

  subscription$() {
    return this.subscribe$.asObservable();
  }

  getAlerts() {
    this.subscribe$.next(this.alerts);
  }

  setAlert(alert: IAlertBlock) {
    this.alerts = [];
    this.alerts.push(alert);
    this.getAlerts();
  }
  
  setAlerts(alerts: IAlertBlock[]) {
    this.alerts = alerts;
    this.getAlerts();
  }


  clearAlerts() {
    this.alerts = [];
    this.getAlerts();
  }
}
