import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs/Observable';
import { AuthorizationService } from './authorization.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenDto } from '../../shared/models/server/DataTransferObject/Objects/Authentication';
import { of } from 'rxjs/observable/of';
import { LocalStorageKeys } from '../../shared/constants';
import { LocalStorageService } from '..';

const jwtHelper = new JwtHelperService();

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthorizationService,
    private storage: LocalStorageService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.toLowerCase().includes(`${environment.apiUrl.identity.toLowerCase()}/login/password`) ||
      request.url.toLowerCase().includes(`${environment.apiUrl.identity.toLowerCase()}/login/changepassword`) ||
      request.url.toLowerCase().includes(`${environment.apiUrl.identity.toLowerCase()}/login/resetpassword`) ||
      request.url.toLowerCase().includes(`${environment.apiUrl.identity.toLowerCase()}/permissionpackage`) ||
      request.url.startsWith('./')
    ) {
      return next.handle(request);
    }

    if (request.url.toLowerCase().includes(`${environment.apiUrl.identity.toLowerCase()}/login/refresh`)) {
      return next.handle(request).pipe(
        catchError(error => {
          if (this.isUnauthorizedError(error)) {
            this.authService.logout();
          }

          return of(error);
        })
      );
    }

    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(token => Boolean(token)),
        take(1),
        switchMap(token => next.handle(this.addTokenToHeader(request, token)))
      );
    }

    return this.handleAuthenticated(request, next).pipe(
      catchError(error => {
        if (error.code === 'TokenExpired' || error.code === 'NoToken') {
          return this.handleExpiredToken(request, next, error.loggedIn);
        }

        return Observable.throw(error);
      }),
      catchError(error => {
        switch (error.code) {
          case 'NoRefreshToken':
            if (error.loggedIn) {
              this.authService.logout();
            }
            return Observable.throw(error);
          default:
            return Observable.throw(error);
        }
      })
    );
  }

  private handleAuthenticated(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.isAuthenticated().pipe(
      mergeMap(authenticated => {
        if (authenticated) {
          return this.getToken().pipe(
            mergeMap(token => this.handleToken(request, next, token, true))
          );
        }

        return Observable.throw({ code: 'NoToken' });
      })
    );
  }

  private handleToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string,
    loggedIn = false
  ): Observable<HttpEvent<any>> {
    if (token) {
      if (!this.isTokenExpired(token)) {
        const requestWithAuth = this.addTokenToHeader(request, token);
        return this.handleResponse(requestWithAuth, next);
      }

      return Observable.throw({ code: 'TokenExpired', loggedIn });
    }

    return Observable.throw({ code: 'NoToken', loggedIn });
  }

  private handleExpiredToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    loggedIn = false
  ): Observable<HttpEvent<any>> {
    const refreshToken = this.authService.getToken();
    if (refreshToken) {
      return this.refreshToken(refreshToken).pipe(
        switchMap(({ token, restrictedRoleIds, info }) => {
          const requestWithAuth = this.addTokenToHeader(request, token);
          this.authService.filterRoles(info);
          this.authService.setPermissions(token);
          this.authService.saveData(token, restrictedRoleIds, info);
          this.authService.loggedIn = true;
          return this.handleResponse(requestWithAuth, next);
        }),
        catchError((refresHttpError) => {
          if (this.isUnauthorizedError(refresHttpError)) {
            if (this.isInvalidTokenError(refresHttpError)) {
              return Observable.throw({ code: 'NoRefreshToken', loggedIn });
            }
          }

          return Observable.throw(refresHttpError);
        })
      );
    }

    return Observable.throw({ code: 'NoRefreshToken', loggedIn });
  }

  private handleResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(httpError => {
        if (this.isUnauthorizedError(httpError)) {
          if (this.isInvalidTokenError(httpError)) {
            return this.handleExpiredToken(request, next);
          }
        }

        return Observable.throw(httpError);
      })
    );
  }

  private isAuthenticated(): Observable<boolean> {
    return of(this.authService.loggedIn);
  }

  private getToken(): Observable<string> {
    return of(this.authService.token);
  }

  private addTokenToHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private isTokenExpired(token: string): boolean {
    try {
      return !token || jwtHelper.isTokenExpired(token);
    } catch (_) {
      return true;
    }
  }

  private refreshToken(refreshToken: string): Observable<TokenDto> {
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);
    return this.authService.refreshLogin$(refreshToken, ...this.authService.getSelectedRole()).pipe(
      map(response => {
        this.authService.saveToken(response.refreshToken);
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(response.token);
        return response;
      }),
      catchError(error => {
        this.storage.remove(LocalStorageKeys.selectedRoles);
        return of(error);
      })
    );
  }

  private isUnauthorizedError(httpError: any) {
    return httpError instanceof HttpErrorResponse && httpError.status === 401;
  }

  private isInvalidTokenError(error: any): boolean {
    if (this.isUnauthorizedError(error) && error.error && error.error.length > 0) {
      const errorCode = error.error[0].Code;
      return errorCode === 'InvalidRefreshToken';
    }

    return false;
  }
}
