import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ToasterService } from 'angular2-toaster';
import { BreadcrumbsService } from '../../../../core/services/breadcrumbs/breadcrumbs.service';
import { DatatableService } from '../../../../services/datatable/datatable.service';
import { MasterDataService } from '../../../../services/masterdata/masterdata.service';
import { StereotypeService } from '../../../../services/stereotype/stereotype.service';
import { ContactListDto } from '../../../../shared/models/server/DataTransferObject/Objects/MasterData/Contact';
import { Contact } from '../../../../shared/models/tenant/contact';
import { ContactInfo } from '../../../../shared/models/tenant/contact-info';
import { UtilsService } from '../../../../shared/utils/utils.service';
import { MasterDataTable } from '../../master-data-table';
import { AlertService } from '../../../../services/ui/alerts/alert.service';
import { ActionBarService } from '../../../../core/services/action-bar/action-bar.service';
import {AlertBlockService} from '../../../../core/services/alert-block/alert-block.service';
import {TableConfig} from '../../../../shared/constants/table-config';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
  providers: [MasterDataService]
})
export class ContactsListComponent extends MasterDataTable {
  @Output() selectEditContact: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() removeContactEmitter: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() addContactEmitter: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Input() filter: Array<number> = [];
  contactGroupId: number;

  contacts = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('myTable') tableExp: any;

  constructor(protected masterDataService: MasterDataService,
              protected toasterService: ToasterService,
              protected utilsService: UtilsService,
              protected datatableService: DatatableService,
              protected router: Router,
              protected breadcrumbsService: BreadcrumbsService,
              protected stereotypesService: StereotypeService,
              protected ts: TranslateService,
              protected alertService: AlertService,
              protected actionbarService: ActionBarService,
              protected alertBlockService: AlertBlockService) {
    super(utilsService, toasterService, datatableService, router, breadcrumbsService, stereotypesService, ts, alertService, actionbarService, alertBlockService);
    this.tableObject = new ContactListDto();
    this.config = {
      title: 'TITLE.contactsTable',
      filter: [],
      resource: 'contact',
      resourceIdName: 'contactId',
      parentResource: 'company',
      pageSize: TableConfig.pageSize,
      excludedColumns: {
        entityCustomValues: true,
        isInRelation: true,
        phone: true,
        mobile: true,
        position: true,
        tenantId: true,
        stereotypeId: true,
        stereotypeRowVersion: true,
        customPropertyValues: true,
        locations: true
      },
      buttons: {
        edit: true,
        delete: true,
        view: true,
      }
    };
    this.createColumns();
  }

  toContact(contactName: string, contactLastname: string, contactId: string) {
    this.breadcrumbsService.setResourceName(`${contactName} ${contactLastname}`);
    this.router.navigate(['masterdata/contacts/contact/', contactId]);
  }

  toEdit(contactName: string, contactLastname: string, contactId: string) {
    this.breadcrumbsService.setResourceName(`${contactName} ${contactLastname}`);
    this.router.navigate([`masterdata/contacts/contact/${contactId}`], { queryParams: { edit: 1 } });
  }

  setContacts(contacts: Array<ContactInfo>  = [], id: string = null) {
    if (id) {
      this.resourceId = id;
      this.dataId = id;
    }
    this._getResource(this.currentParams, this.currentSortOption);
  }

  onSelect(e) {
    if (e.type === 'click') {
      if(e.column.name == "pin") {
        e.row.showPin = true;
      } else {
        const data = e.row;
        this.toContact(data.firstName, data.lastName, data.contactId);
      }
    }
  }

}
