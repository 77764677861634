import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperFirst'
})
export class UpperFirstPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value) {
      return value[0].toUpperCase() + value.slice(1, value.length);
    } else {
      return '';
    }
  }

}
