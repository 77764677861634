import { Injectable } from '@angular/core';
import {IService} from '../../shared/interfaces/ui-services';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class LoaderService implements IService{
  _status = false;
  _subscribe: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  subscription$() {
    return this._subscribe.asObservable();
  }

  start() {
    this._subscribe.next();
  }

  stop() {
    this._subscribe.next(false);
  }

  get() {
    this._subscribe.next(this._status);
  }

}
