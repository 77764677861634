//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { ILocationGroupRelationDto } from '../MasterData';
import { LocationDto } from './Locations';

export class LocationGroupSimpleDto
{
	@Column() public locationGroupId: number;
	@Column() public name: string;
}
export class DisplayLocationGroupListDto
{
	@Column() public locationGroupId: number;
	@Column() public isSystem: boolean;
	@Column() public name: string;
}
export class LocationGroupDto extends ValidationDto implements IIdentityData
{
	@Column() public locationGroupId: number;
	@Column() public name: string;
	@Column() public type: number;
	@Column() public rowVersion: number[];
	@Column() public tenantId: number;
	@Column() public externalId: string;
}
export class LocationGroupInfoDto implements ILocationGroupRelationDto
{
	@Column() public infoId: number;
	@Column() public locationGroupId: number;
}
export class LocationGroupListDto implements IIdentityData
{
	@Column() @Filterable() public locationGroupId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public isSystem: boolean;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public externalId: string;
	@Column() public rowVersion: number[];
}
export class LocationGroupLocationDto
{
	@Column() public locationId: number;
	@Column() public location: LocationDto;
	@Column() public locationGroupId: number;
	@Column() public locationGroup: LocationGroupDto;
}
export class LocationGroupRelationDto
{
	@Column() public locationGroupId: number;
	@Column() public locationGroup: LocationGroupSimpleDto;
}
export class LocationGroupTaskDto implements ILocationGroupRelationDto
{
	@Column() public taskId: number;
	@Column() public locationGroupId: number;
}
export class DisplayLocationGroupDto extends LocationGroupDto implements IIdentityData
{
}
export class LocationGroupListForContactDto extends LocationGroupListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class LocationGroupListForLocationDto extends LocationGroupListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class LocationGroupOrgaDto extends ValidationDto implements IIdentityData
{
	@Column() public locationGroupId: number;
	@Column() public name: string;
	@Column() public infoId: number;
	@Column() public tenantId: number;
	@Column() public externalId: string;
}
