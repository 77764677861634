import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

interface DropDownValues {
  label: string;
  value: string | boolean;
}

@Component({
  selector: 'app-boolean-dropdown',
  templateUrl: './boolean-dropdown.component.html',
  styleUrls: ['./boolean-dropdown.component.scss']
})
export class BooleanDropdownComponent implements OnInit {

  @Input() readonly: boolean;

  @Output() selected: EventEmitter<string | boolean> = new EventEmitter<string | boolean>();

  values: Array<DropDownValues> = [
    { label: 'BOOLEAN.empty', value: '' },
    { label: 'BOOLEAN.true', value: true },
    { label: 'BOOLEAN.false', value: false }
  ];

  selectedItem: DropDownValues = this.values[0];

  constructor() { }

  ngOnInit() {
  }

  selectedValue(item: DropDownValues) {
    this.selected.next(item.value + '');
  }

}
