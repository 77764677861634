import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ICreateComponent, IDescriptionEvent } from '../../interfaces/ui';
import { FileService } from '../../../services/files/file.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../../../services';
import {TranslatorService} from '../../../core/services';
import {AlertService} from '../../../services/ui/alerts/alert.service';
declare var $: any;

@Component({
  selector: 'app-summernote',
  templateUrl: './summernote.component.html',
  styleUrls: ['./summernote.component.scss']
})
export class SummernoteComponent implements OnInit, AfterViewChecked {
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() descriptionHTML: string;
  @Input() config: ICreateComponent = {
    title: 'TITLE.createContent',
    resourceIdName: 'ticketId',
    editMode: true
  };
  @ViewChild('summernote') summernote: ElementRef;

  $summernote: any;
  hashTags: string;

  hashTagsArray: Array<string> = [];
  contents = '';
  oldContents = '';
  show: boolean;
  options: any = {
    height: 380,
    dialogsInBody: true,
    callbacks: {
      onImageUpload: (e) => this.onImageUpload(e),
      onChange: (e) => this.onChange.emit(e)
    },
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'style', 'clear']],
      ['para', ['ul', 'ol']],
      ['insert', ['table']],
      ['misc', ['undo', 'redo', 'codeview']],
      ['mybutton', ['myVideo', 'myImage']]
    ],
    buttons: {
      myVideo: (e) => this.onLoadVideo(e),
      myImage: (e) => this.onLoadImage(e),
    },
    styleTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private fileService: FileService,
    private translate: TranslateService,
    private authService: AuthorizationService,
    private translatorService: TranslatorService,
    private alertService: AlertService
  ) {
    this.oldContents = this.contents;
    setTimeout(() => {
      if (this.contents) {
        this.createMode();
        return;
      } else {
        this.editMode();
      }
    }, 0);
  }

  onLoadImage(context: any) {
    const self = this;
    const ui = $.summernote.ui;
    const button = ui.button({
      contents: '<i class="fa fa-image"/>',
      container: false,
      tooltip: 'image',
      click: async function() {
        const arr = [];
        const result = await self.alertService.inputFile('EDITOR.UPLOADIMAGE', 'success', 'EDITOR.NOIMAGEMSG');
        arr.push(result['value']);
        if (result.hasOwnProperty('value') && result['value']) {
          self.onImageUpload(arr);
        }
      }
    });
    return button.render();
  }

  onLoadVideo(context: any) {
    const self = this;
    const ui = $.summernote.ui;
    const button = ui.button({
      contents: '<i class="fa fa-video-camera"/>',
      container: false,
      tooltip: 'video',
      click: async function() {
        const result = await self.alertService.inputDialog('EDITOR.VIDEOUPLOAD', 'success', 'EDITOR.VIDEOMSG');
        const div = document.createElement('div');
        const iframe = document.createElement('iframe') ;

        if (result) {
          div.classList.add('embed-container');
          iframe.src = result.replace('watch?v=', 'embed/');
          iframe.setAttribute('frameborder', '0');
          iframe.setAttribute('width', '640');
          iframe.setAttribute('height', '360');
          iframe.setAttribute('allowfullscreen', 'true');
          div.appendChild(iframe);
          context.invoke('editor.insertNode', div);
        }
      }
    });
    return button.render();
  }

  async onImageUpload(files) {
    if (files.length != 1 || !(files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg")) {
      var text = await this.translate.get('EDITOR.NOIMAGEMSG').toPromise();
      alert(text);
      return;
    }

    try {
      $('#imgupload-loading').modal('show');

      var imgNode = new Image();
      imgNode.src = (await this.fileService.post(this.authService.selectedTenant.tenantId, files[0])).uri;
      this.$summernote.summernote('insertNode', imgNode);
    } catch(error) {
      console.error(error);
      $('#imgupload-loading').modal('hide');
      var text = await this.translate.get('EDITOR.UPLOADERROR').toPromise();
      alert(text);
    }
    finally {
      $('#imgupload-loading').modal('hide');
    }
  }

  editMode() {
    this.oldContents = this.contents;
    this.config.editMode = false;
    this.config.title = 'TITLE.createContent';
  }

  createMode() {
    this.config.editMode = true;
    this.config.title = 'TITLE.createContent';
  }

  saveDescription() {
    this.config.editMode = true;
    this.config.title = 'TITLE.createContent';
    this.oldContents = '';
    this.onChange.emit(this.contents);
    this.setDescripiton(this.contents);
  }

  setDescripiton(description) {
    this.contents = description;
    this.initSummernote(this.options);
    $('.summernote').summernote('code', this.contents);
    this.config.editMode = false;
  }

  resetDescription() {
    if (this.oldContents) {
      this.setDescripiton(this.oldContents);
    }
  }

  getHashTags(text: string) {
    if (text) {
      const regExp: RegExp = /(#\w+)/gi;
      this.hashTagsArray = text.match(regExp);
      if (this.hashTagsArray) {
        this.hashTags = this.hashTagsArray.toString();
        return;
      }
      this.hashTags = '';
      return;
    }
  }

  ngOnInit() {
    if (localStorage.getItem('selectedLang')) {
      this.options['lang'] = localStorage.getItem('selectedLang');
    }
    this.initSummernote(this.options);
  }

  initSummernote(options: any) {
    this.$summernote = $(this.summernote.nativeElement);
    this.$summernote.summernote(options);

    $('.note-popover.note-table-popover').hide();
    $('.note-popover.note-link-popover,.note-popover.note-image-popover, div.note-group-image-url,.note-popover+.modal .modal-title').remove();

  }

  ngAfterViewChecked() {
    const show = this.isShowExpand();
    if (show !== this.show) { // check if it change, tell CD update view
      this.show = show;
      this.cdRef.detectChanges();
    }
  }

  isShowExpand() {
    return !!this.hashTags
  }

}
