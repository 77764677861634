import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
const swal2 = require('sweetalert2');

@Injectable()
export class AlertService {
  public static readonly COLOR_DANGER = '#DD6B55';

  constructor(
    protected translate: TranslateService
  ) { }

  public async confirmDelete() {
    return this.confirm('ALERT.DELETE.TITLE', 'ALERT.DELETE.MSG', AlertService.COLOR_DANGER);
  }

  public confirm(title: string, text: string, color: string) {
    return new Promise<boolean>(async (resolve) => {
      swal2(
        {
          title: await this.translate.get(title).toPromise(),
          text: await this.translate.get(text).toPromise(),
          showCancelButton: true,
          confirmButtonColor: color,
          reverseButtons: true,
          cancelButtonText: await this.translate.get('ALERT.CONFIRM.CANCEL').toPromise(),
          confirmButtonText: await this.translate.get('ALERT.CONFIRM.YES').toPromise(),
          closeOnConfirm: true,
          animation: false,
        },
        // resolve
      ).then(val => {
        if (val.hasOwnProperty('value')) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public inputDialog(title: string, color: string, text: string = '') {
    return new Promise<string>(async(resolve) => {
      swal2(
        {
          title: await this.translate.get(title).toPromise(),
          // type: 'input',
          input: 'text',
          text: await this.translate.get(text).toPromise(),
          showCancelButton: true,
          confirmButtonColor: color,
          reverseButtons: true,
          cancelButtonText: await this.translate.get('ALERT.CONFIRM.CANCEL').toPromise(),
          confirmButtonText: await this.translate.get('ALERT.CONFIRM.YES').toPromise(),
          closeOnConfirm: true,
          animation: false
        }
      ).then(val => {
        if (val.hasOwnProperty('value')) {
          resolve(val.value);
        }
      });
    });
  }

  public inputFile(title: string, color: string, text: string = '') {
    return new Promise<string>(async(resolve) => {
      swal2(
        {
          title: await this.translate.get(title).toPromise(),
          input: 'file',
          text: await this.translate.get(text).toPromise(),
          inputAttributes: { 'accept': 'image/*' },
          animation: false,
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: await this.translate.get('ALERT.CONFIRM.CANCEL').toPromise(),
          confirmButtonText: await this.translate.get('ALERT.CONFIRM.YES').toPromise(),
        }
      ).then(val => resolve(val));
    });
  }

  public alert(title: string, text: string) {
    return new Promise<boolean>(async (resolve) => {
      swal2(
        {
          title: await this.translate.get(title).toPromise(),
          text: await this.translate.get(text).toPromise(),
          confirmButtonColor: '#f1b143',
          confirmButtonText: await this.translate.get('ALERT.CONFIRM.OK').toPromise(),
          closeOnConfirm: true,
          animation: false,
        }
      ).then(val => resolve(val));
    });
  }

}
