import { Injectable } from '@angular/core';
import {of} from 'rxjs/observable/of';
import {TicketCategory} from '../../../shared/models/ticket/ticket-category';

@Injectable()
export class StateService {
  _states: Array<any> = [
    { stateId: 1, title: 'open' },
    { stateId: 2, title: 'close' },
    { stateId: 3, title: 'waiting' },
    { stateId: 4, title: 'overdue' },
  ];
  constructor() { }

  getStatesList() {
    return of(this._states.slice());
  }

  getStateById(id: number) {
    return of(this._states.find(el => el.id === id));
  }
}
