import { Component, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss']
})
export class DatetimePickerComponent implements OnInit {

  public readonly ASPNET_DATETIME_FORMAT: string = 'YYYY-MM-DD';
  public readonly DISPLAY_DATETIME_FORMAT: string = 'DD.MM.YYYY';
  @Input() readonly: boolean = false;
  @Input() config = {
    showDate: true,
    showTime: true,
    hideLabels: false
  };
  @Input()
  set setDatetime(value: Date) {
    if (value) {
      this.datePicker = moment.utc(value).local().toDate();
      this.timePicker = moment.utc(value).local().toDate();
    }
  }

  @Output() onChange: Subject<any> = new Subject<any>();

  public datePicker: Date = new Date();
  public timePicker: Date = new Date();

  public time: any;
  public date: any;
  public dateTime: any;

  constructor() {
    this.timePicker.setMinutes(0);
    this.timePicker.setSeconds(0);
    this.timePicker.setMilliseconds(0);

    this.datePicker.setHours(0);
    this.datePicker.setMinutes(0);
    this.datePicker.setSeconds(0);
    this.datePicker.setMilliseconds(0);
  }

  ngOnInit() {
  }

  onDateTimeChanged() {
    if (this.time) {
     this.dateTime = this.date + 'T' + this.time;
    } else {
      this.dateTime = this.date + 'T00:00:00';
    }
    this.onChange.next(this.dateTime);
  }

  onDateChanged(event) {
    if (event) {
      this.date = moment(event, [this.DISPLAY_DATETIME_FORMAT]).format(this.ASPNET_DATETIME_FORMAT);
      this.onDateTimeChanged();
    }
  }

  onTimeChanged(event) {
    this.time = moment.utc(event).format("HH:mm:00");
    this.onDateTimeChanged();
  }


}
