import { Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { ActionBarService } from '../../core/services/action-bar/action-bar.service';
import { BreadcrumbsService } from '../../core/services/breadcrumbs/breadcrumbs.service';
import { AuthorizationService } from '../../services';
import { DatatableService } from '../../services/datatable/datatable.service';
import { IActionButton, ICreateComponent, IAlertBlock } from '../interfaces/ui';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertBlockService} from '../../core/services/alert-block/alert-block.service';

export class DataDetailBase {
  @Input() config: ICreateComponent;

  currentId: any;

  currentActionState = false;

  errorDescriptions: string[] = [];

  lastAction: () => {};

  hasError = false;

  form: FormGroup;

  fields: any;

  data: any;

  buttons: Array<IActionButton> = [
    {
      label: 'BUTTON.back',
      class: 'btn-primary',
      icon: 'fa-arrow-left',
      type: 'button',
      callback: this.cancelEdit.bind(this),
    }
  ];

  isChanged: boolean;

  isCreated: boolean;

  isLoading: boolean;

  listPageUrl: string;

  dataId: string;

  constructor(protected datatableService: DatatableService,
    protected toasterService: ToasterService,
    protected breadcrumbsService: BreadcrumbsService,
    protected _fb: FormBuilder,
    protected translate: TranslateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected actionBarService: ActionBarService,
    protected authService: AuthorizationService,
    protected alertBlockService: AlertBlockService) {
  }

  initForm(fields: any = this.fields) {
    this.form = this._fb.group(fields);
  }

  editMode(data: any) {
    if (data) {
      this.config.editMode = true;
      this.form.patchValue(data);
    }
  }

  retry() {
    this.lastAction();
  }

  cancelEdit(path: string = this.listPageUrl) {
    this.router.navigate([path]);
  }

  showToaster(type: string, msg: string, title: string = '') {
    this.translate.get(msg).subscribe(response => {
      this.toasterService.pop(type, title, response);
    });
  }

  protected async _errorHandler(httpError: HttpErrorResponse, lastAction: any) {
    if (httpError.error && httpError.error.Errors) {
      this.errorDescriptions = (<{
        Description: string;
      }[]>httpError.error.Errors)
        .filter(x => x && x.Description && x.Description.length > 0)
        .map(x => x.Description);
    }

    if (httpError.error && httpError.error.error) {
      this.errorDescriptions = [httpError.error.error];
    }
    
    if (httpError.error && Array.isArray(httpError.error) && httpError.error.length > 0) {
      this.errorDescriptions = httpError.error
        .filter(x => x && x.Description && x.Description.length > 0)
        .map(x => x.Description);
    }

    if (httpError.status === 403) {
      this.errorDescriptions = [ await this.translate.get('ERROR.HTTP.403').toPromise() ];
    }

    if (httpError.status === 404) {
      this.errorDescriptions = [ await this.translate.get('ERROR.HTTP.404').toPromise() ];
    }

    if (httpError.status === 500) {
      this.errorDescriptions = [ await this.translate.get('ERROR.HTTP.500').toPromise() ];
    }

    this.isLoading = false;
    this.hasError = true;

    this.alertBlockService.setAlerts(this.errorDescriptions.map(x => <IAlertBlock>{ 
      error: x,
      callback: lastAction.bind(this)
    }));
  }

  private setLastAction(action: () => {}) {
    this.lastAction = action;
  }

  fetchData<T>(id: string, success: (T) => void = () => {}, err: (T) => void = () => {}) {
    this.hasError = false;
    this.currentId = id;
    this.isLoading = true;
    this.datatableService.getOne<T>(this.config.resource, id, this.config.sortDirection, this.config.localSortField)
      .subscribe((resp: T) => {
        this.isLoading = false;
        this.data = resp;
        this.editMode(resp);
        success(resp);
      }, error => {
        this._errorHandler(error, this.fetchData);
        this.isLoading = false;
        err(error);
      });
  }

  create<T>(form: FormGroup = this.form, success: any = () => {}, err: any = () => {}, addAfterCreate: boolean = this.currentActionState) {
    this.hasError = false;
    this.currentActionState = addAfterCreate;
    this.isCreated = true;
    this.errorDescriptions = [];
    const data = form.value;

    data['tenantId'] = this.authService.selectedTenant.tenantId;
    this.datatableService.post<T>(this.config.resource, data)
      .subscribe((response: T) => {
        this.isCreated = false;
        this.showToaster('success', 'SUCCESS.created');
        if (addAfterCreate) {
          this.datatableService.addToLocal(response, this.config.resourceIdName);
        }
        success(response);
        if (this.listPageUrl) {
          this.router.navigate([this.listPageUrl]);
        }
      }, error => {
        this._errorHandler(error, this.create);
        this.isCreated = false;
        err(error);
        this.showToaster('error', 'ERROR.notCreated');
      });
  }

  save<T>(id: string, success: any = () => {}, err: any = () => {}, putAfterCreate: boolean = this.currentActionState) {
    this.hasError = false;
    this.currentActionState = putAfterCreate;
    this.currentId = id;
    this.isChanged = true;
    this.errorDescriptions = [];

    const data = this.form.value;
    data['tenantId'] = this.authService.selectedTenant.tenantId;
    this.datatableService.put(this.config.resource, id, data)
      .subscribe((response: T) => {
        this.isChanged = false;
        this.showToaster('success', 'SUCCESS.updated');
        if (putAfterCreate) {
          this.datatableService.putToLocal(response, this.config.resourceIdName);
        }
        success(response);
      }, error => {
        this._errorHandler(error, this.save);
        this.isChanged = false;
        err(error);
        this.showToaster('error', 'ERROR.notUpdated');
      });
  }

}
