//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../Attributes';

export class FilterDto
{
	@Column() public property: string;
	@Column() public value: string;
	@Column() public operator: number;
	@Column() public type: number;
}
export class CustomFilterDto extends FilterDto
{
}
