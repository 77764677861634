import { Injectable } from '@angular/core';
import {FilterDto} from '../models/server/DataTransferObject/Filters';

@Injectable()
export class UtilsService {
  private  _columnFilter: Array<string> = [
    'rowVersion',
    'tenantId',
    'isInRelation',
    'locationId',
    'concurrencyStamp',
    'isSystemRole',
    'defaultRoleId',
    'loginProviderRoleId',
    'entityCustomValues',
    'roleId',
    'loginProviderId',
    'loginProvider',
    'defaultRole'];

  public createColums(items: Array<any>) {
    const out = [];
    for (const obj in items[0]) {
      if (obj) {
        out.push({name: obj});
      }
    }
    // return out.filter(el => el.name !== 'rowVersion');
    return out.filter(el => !(this._columnFilter.indexOf(el.name) >= 0));
  }

  public buildFilterBody(column, event, isCustom: boolean = false) {
    let filter;

    if (event.hasOwnProperty('operator') && !event.value) {
      return null;
    }

    if (event.hasOwnProperty('operator') && event.value !== '') {
      filter = new FilterDto();
      filter.Property = column;
      filter.Value = event.value;
      filter.Operator = event.operator;

      return filter;
    }

    if (event && !isCustom) {
      filter = {
        Value: event ? event : null,
        Property: column[0] + column.slice(1, column.length)
      };
    }

    if (event && isCustom) {
      filter = {
        Property: column,
        Value: event || null,
        Type: 'Custom'
      };
    }

    return filter;
  }

  public queryBuilder(queryArray: Array<{ key: string, value: any}>, pageSize: number = 25) {
    let queryString = '';
    queryArray = queryArray || [];

    if(queryArray.some(x => x.key.toLowerCase() == 'pagesize') == false) {
      queryArray.push({
        key: "pageSize",
        value: pageSize
      });
    }

    queryArray = queryArray.filter(x => !!x.value);

    queryArray.forEach((query, index) => {
      if (query.value) {
        queryString += queryString.length === 0 ? '?' : '';
        queryString += `${query.key}=${query.value}`;
        if (queryArray.length > index + 1) {
          queryString += '&';
        }
      }
    });
    
    return queryString;
  }

}
