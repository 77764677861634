import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import { HttpRequestService } from '../../../services';
import { AutocompleteBase } from '../../classes/autocomplete-base';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-knowledgearticle-autocomplete',
  templateUrl: './knowledgearticle-autocomplete.component.html',
  styleUrls: ['./knowledgearticle-autocomplete.component.scss'],
  providers: [AutocompleteService, HttpRequestService]
})
export class KnowledgearticleAutocompleteComponent extends AutocompleteBase implements OnInit {

  @Input()
  set items(value) {
    this._items = [...this._items, ...value];
  }


  @Input() public initId: number;
  @Input() public initLabel: string;

  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();
  public selected: number;
  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(
    protected autocompleteService: AutocompleteService
  ) {
    super(autocompleteService);
    this.url = 'knowledgearticle';
  }

  async ngOnInit() {
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter('title', value)
    });
    await this.getAll();
    if (!this.selected && this._items && this._items.length > 0) {
      this.selected = this.initId || this._items[0].knowledgeArticleId;
      let item = this._items.find(x => x.knowledgeArticleId == this.selected);

      if (!item) {
        item = {
          knowledgeArticleId: this.selected,
          title: this.initLabel || this.selected
        };
        this._items.push(item);
      }
      
      this.changeValue.next(item);
    }
  }

  onChange(element) {
    this.changeValue.next(element);
  }

}
