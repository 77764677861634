import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterDto} from '../../models/server/DataTransferObject/Filters';
import {FilterOperators} from '../../models/server/Enums';
import * as moment from 'moment';

export class SearchTypes {
  type: 'date' | 'text' | 'boolean' | 'list';
  values?: Array<any>;
}

export interface IconOption {
  value: string;
  icon: string;
}

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Input() searchType: SearchTypes = new SearchTypes();

  @Input() data: FilterDto = new FilterDto();

  @Input() columnName: string;

  @Output() searchBy: EventEmitter<any> = new EventEmitter<any>();

  currentValue = '';

  direction: string = 'Default';

  mask = {
    mask: [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /\d/, /\d/],
    guide: true
  };

  directions: Array<IconOption> = [
    { value: 'Default', icon: '='},
    // { value: 'NotEqual', icon: '≠'},
    { value: 'GreaterThan', icon: '>'},
    { value: 'LessThan' , icon: '<'}
  ];

  constructor() {
    this.searchType.type = 'text';
  }

  checkDataTime(name, e) {
    const value = e.target.value;
    this.currentValue = e.target.value;

    this.data.value = e.target.value;
    this.data.property = this.columnName;
    this.data.operator = this.direction ? FilterOperators[this.direction] : FilterOperators.Default;

    if (value.indexOf('_') === -1 && moment(value)) {
      this.searchBy.next(this.data);
    }
    if (!value) {
      this.searchBy.next(this.data);
    }
  }

  changeFilter(value) {
    this.currentValue = value;
    this.data.value = value;
    this.data.operator = this.direction ? FilterOperators[this.direction] : FilterOperators.Default;
    this.data.property = this.columnName;
    this.searchBy.next(this.data);
  }

  changeDirection(direction) {
    this.direction = direction;
    this.data.value = this.currentValue;
    this.data.operator = this.direction ? FilterOperators[this.direction] : FilterOperators.Default;
    this.data.property = this.columnName;

    if (!this.data.value) {
      this.searchBy.next('');
    } else {
      this.searchBy.next(this.data);
    }
  }

  ngOnInit() {

  }

}
