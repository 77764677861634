import {AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {StateService} from '../../../services/optional/state/state.service';
import {IDropdownConfig} from '../../interfaces/ui';

@Component({
  selector: 'app-state-dropdown',
  templateUrl: './state-dropdown.component.html',
  styleUrls: ['./state-dropdown.component.scss']
})
export class StateDropdownComponent implements OnInit, AfterContentInit {
  stateList: Array<any> = [];
  @Input() config: IDropdownConfig = {
    disabled: false,
    selected: this.stateList[0]
  };
  @Output() data: EventEmitter<any> = new EventEmitter<any>();
  constructor(private stateService: StateService) {}

  ngOnInit() {
  }

  refreshValue(value) {
    this.data.emit(value.target.value);
  }

  ngAfterContentInit() {
    this.stateService.getStatesList()
      .subscribe(states => this.stateList = states);
  }
}
