import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {AutocompleteBase} from '../../classes/autocomplete-base';
import {AutocompleteService} from '../../../services/autocomplete/autocomplete.service';

@Component({
  selector: 'app-resource-autocomplete',
  templateUrl: './resource-autocomplete.component.html',
  styleUrls: ['./resource-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class ResourceAutocompleteComponent extends AutocompleteBase implements OnInit {
  @Input() readonly = false;
  @Input()
  set resourcesIds(value) {
    this.selectedResourceId = value;
  }

  @Input()
  set items(value) {
    this._items = [...this._items, ...value];
  }

  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  // items: any;

  selectedResourceId: any;

  resourceTypeValue$: Subject<string> = new Subject<string>();

  data: Array<any> = [];

  constructor(protected autocompleteService: AutocompleteService) {
    super(autocompleteService);
    this.url = 'resource';
  }

  ngOnInit() {
    this.resourceTypeValue$
      .subscribe(value => {
        this.buildFilter('name', value)
      });
  }

  refreshValue(event) {
    this.changeValue.next(event.map(element => {
      return { resourceId: element.resourceId };
    }));
  }

  removed(event) {
  }
}
