import { Component, OnInit, Injector, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var $: any;

import { SettingsService } from '../../shared/settings/settings.service';
import { MenuService } from '../../core/services';
import { PermissionService } from '../../services/permission/permission.service';
import { AuthorizationService, LocalStorageService } from '../../services';
import { Observable, from, of, merge } from 'rxjs';
import { first, filter, switchMap } from 'rxjs/operators';
import { GeneralSettingDto } from '../../shared/models/server/DataTransferObject/Objects/Settings';
import { GeneralSettingService } from '../../services/setting/general-setting.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ GeneralSettingService ]
})
export class SidebarComponent implements OnInit {

    menu: Array<any>;
    logoPath: string;
    router: Router;

    public generalSetting: Observable<GeneralSettingDto>;

    constructor(
        public menuService: MenuService,
        public settings: SettingsService,
        public injector: Injector,
        public permissionService: PermissionService,
        public authService: AuthorizationService,
        public storage: LocalStorageService,
        public generalSettingService: GeneralSettingService
    ) {

        this.menu = menuService.getMenuSorted();
        this.logoPath = settings.app.logo;
        this.initSettings();
    }

    public checkPermission(item): Observable<boolean> {
      return this.authService.hasPermission$(item.permission).pipe(first());
    }

    private initSettings() {
        const settingsStorageKey = "GeneralSettings" + this.authService.selectedTenant.tenantId.toString();
    
        const storedSetting = of(this.storage.get(settingsStorageKey)).pipe(
          filter(x => x !== null),
          switchMap(x => of(JSON.parse(x as string) as GeneralSettingDto))
        );
        
        this.generalSetting = merge(storedSetting, this.generalSettingService.setting$);
    
        this.generalSetting.subscribe(general => {
          this.storage.set(settingsStorageKey, JSON.stringify(general));
        });
      }

    ngOnInit() {
        this.router = this.injector.get(Router);
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe((event) => {
                this.settings.app.sidebar.visible = false;
                this.settings.app.sidebar.coverModeVisible = false;
            });
    }

    closeSidebar() {
        this.settings.app.sidebar.coverModeVisible = false;
        this.settings.app.sidebar.visible = false;
    }

    handleSidebar(event) {
        let item = this.getItemElement(event);
        // check click is on a tag
        if (!item) return;

        let ele = $(item),
            liparent = ele.parent()[0];

        let lis = ele.parent().parent().children(); // markup: ul > li > a
        // remove .active from childs
        lis.find('li').removeClass('active');
        // remove .active from siblings ()
        $.each(lis, function(key, li) {
            if (li !== liparent)
                $(li).removeClass('active');
        });
        let next = ele.next();
        if (next.length && next[0].tagName === 'UL') {
            ele.parent().toggleClass('active');
            event.preventDefault();
        }
    }

    // find the a element in click context
    // doesn't check deeply, asumens two levels only
    getItemElement(event) {
        let element = event.target,
            parent = element.parentNode;
        if (element.tagName.toLowerCase() === 'a') {
            return element;
        }
        if (parent.tagName.toLowerCase() === 'a') {
            return parent;
        }
        if (parent.parentNode.tagName.toLowerCase() === 'a') {
            return parent.parentNode;
        }
    }
}
