import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-modals-message',
  templateUrl: './modals-message.component.html',
  styleUrls: ['./modals-message.component.scss']
})
export class ModalsMessageComponent implements OnInit {
  title: string;
  msg: string;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
  }

}
