import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthorizationService} from '../../services';

@Injectable()
export class HelpProxyGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthorizationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    window.open('http://help.orga.semco.app', '_self');
    return true;
  }
}
