import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpRequestService } from '..';
import { FileDto } from '../../shared/models/server/DataTransferObject/Objects/Files';

@Injectable()
export class FileService {

  constructor(
    private apiService: HttpRequestService
  ) { }

  public get(fileId: number) : Promise<FileDto> {
    return this.apiService
      .get(['file', fileId].join('/'))
      .pipe(map((response: HttpResponse<FileDto>) => response.body))
      .toPromise();
  }

  public post(tenantId: number, file: Blob) : Promise<FileDto> {
    let body = new FormData();
    body.append('file', file);

    return this.apiService
      .post(['file', 'tenant', tenantId].join('/'), body)
      .pipe(map((response: HttpResponse<FileDto>) => response.body))
      .toPromise();
  }
}
