import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { OrgaFolderItemDto } from '../../models/server/DataTransferObject/Objects/Documents';
import { StereotypeService } from '../../../services/stereotype/stereotype.service';
import { ActionBarService } from '../../../core/services/action-bar/action-bar.service';
import { FormControl, Validators } from '@angular/forms';
import { DatatableService } from '../../../services/datatable/datatable.service';
import { AlertBlockService } from '../../../core/services/alert-block/alert-block.service';
import { BreadcrumbsService } from '../../../core/services/breadcrumbs/breadcrumbs.service';
import { MasterDataTable } from '../../../routes/master-data/master-data-table';
import { TableConfig } from '../../constants/table-config';
import { IActionButton } from '../../interfaces/ui';
import { AlertService } from '../../../services/ui/alerts/alert.service';
import { IPageableReq } from '../../interfaces/http';
import { UtilsService } from '../../utils/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderCreateComponent } from '../../../routes/documents/folder-create/folder-create.component';
import { AuthorizationService } from '../../../services';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-folder-manager',
  templateUrl: './folder-manager.component.html',
  styleUrls: ['./folder-manager.component.scss']
})
export class FolderManagerComponent extends MasterDataTable implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  @Output() onSelectedPath: EventEmitter<any> = new EventEmitter<any>();

  @Output() onCreateAttachment: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('file') fileInput;

  @ViewChild(FolderCreateComponent)
  createComponent: FolderCreateComponent;

  prevDirectory: string;

  currentDirectory: string;

  backButton: IActionButton[] = [
    {
      label: 'BUTTON.back',
      type: 'button',
      class: 'btn-primary',
      icon: 'fa-arrow-left',
      callback: this.btnPrevAction.bind(this)
    }
  ];

  addButton: IActionButton[] = [
    {
      label: 'BUTTON.addFolder',
      type: 'button',
      class: 'btn-primary',
      icon: 'fa-plus',
      callback: this.addFolder.bind(this)
    }
  ];

  createFolderButton: IActionButton[] = [
    {
      label: 'BUTTON.createFolder',
      type: 'button',
      class: 'btn-primary',
      icon: 'fa-plus',
      callback: this.createFolder.bind(this)
    }
  ];

  cancelActionButton: IActionButton[] = [
    {
      label: 'BUTTON.cancelAction',
      type: 'button',
      class: 'btn-primary',
      icon: 'fa-arrow-left',
      callback: this.cancelAction.bind(this)
    }
  ];

  addDocumentButton: IActionButton[] = [
    {
      label: 'BUTTON.addDocument',
      type: 'button',
      class: 'btn-primary',
      icon: 'fa-plus',
      callback: this.addDocument.bind(this)
    }
  ];

  permissionButton: IActionButton[] = [
    {
      label: 'BUTTON.permissionButton',
      type: 'button',
      class: 'btn-primary',
      icon: 'fa-shield',
      callback: this.openPermmission.bind(this)
    }
  ];

  currentPath: string;

  regexp = /(\/)(?!.*\b\1\b)(\S.*)/;

  formData: FormData = new FormData();

  formControlName: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9_-]*')]));

  folderName: string;

  private backFolderName = '...';

  constructor(protected toasterService: ToasterService,
    protected utilsService: UtilsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected datatableService: DatatableService,
    protected breadcrumbsService: BreadcrumbsService,
    protected stereotypesService: StereotypeService,
    protected ts: TranslateService,
    protected alertService: AlertService,
    protected actionbarService: ActionBarService,
    protected authService: AuthorizationService,
    protected alertBlockService: AlertBlockService) {
    super(utilsService, toasterService, datatableService, router, breadcrumbsService, stereotypesService, ts, alertService, actionbarService, alertBlockService);
    this.tableObject = new OrgaFolderItemDto();
    this.config = {
      title: 'TITLE.documentsTable',
      resource: 'document',
      resourceIdName: 'documentId',
      operation: 'All',
      pageSize: 10,
      excludedColumns: {
        path: true,
        file: true,
        access: true,
        type: true
      },
      buttons: {
        view: false,
        edit: false,
        delete: false
      }
    };
    this.createColumns();

    this.checkPath();

    this.formControlName
      .valueChanges
      .subscribe(val => {
        this.setState();
        this.folderName = val;
      });

    this.ts.get('BUTTON.folderBack').toPromise().then(x => this.backFolderName = x);
  }

  openPermmission() {
    this.toDetailPage(this.currentPath);
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  sort(e) {
  }

  checkPath() {
    if (this.route.snapshot.queryParams.hasOwnProperty('path') && this.route.snapshot.queryParams.path) {
      setTimeout(() => {
        const data = this.route.snapshot.queryParams;
        const arr = data.path.split('/');

        arr.forEach(el => {
          const replaceReg = new RegExp(`(?<=${el})\/.*`);
          const formatedPath = data.path.replace(replaceReg, '');

          this.breadcrumbsService.addPath({
            label: this.getPathName(el),
            path: formatedPath,
            callback: this.prevBreadcrumbsPage.bind(this)
          });
        });
      }, 350);

      this.refreshDatatable(this.route.snapshot.queryParams.path);
    }
  }

  setState() {
    const state = this.formControlName.valid ? 'enabled' : 'disabled';
  }

  addFolder() {
    this.createComponent.editMode();
    this.setState();
  }

  createFolder() {
    this.createComponent.viewMode();
    const breadcrumbPath = encodeURIComponent(this.breadcrumbsService.getPath());
    const path = `${this._getRequestControllerPath()}${'/folder'}?path=${breadcrumbPath}&name=${this.folderName}`;

    if (this.folderName) {
      const currentPath = this.currentDirectory ? this.currentDirectory : '';
      this.lastAction = this.createFolder.bind(this);

      this.datatableService.post(path, '')
        .subscribe({
          next: value => {
            this.refreshDatatable(currentPath);
            this.cancelAction();
          },
          error: err => {
            this._errorHandler(err);
            this.addFolder();
          }
        });
    }
  }

  addDocument() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event) {
    const breadcrumbPath = encodeURIComponent(this.breadcrumbsService.getPath());
    const path = `${this._getRequestControllerPath()}${'/file'}?path=${breadcrumbPath}`;
    const currentPath = this.currentDirectory ? this.currentDirectory : '';

    if (event.target.files.length > 0) {
      this.isLoading = true;
      const file = event.target.files[0];

      this.formData = new FormData();
      this.formData.append('data', file, file.name);
      this.datatableService.post(path, this.formData)
        .subscribe({
          next: () => this.refreshDatatable(currentPath),
          error: () => this.isLoading = false
        });
    }   
  }

  ngOnInit() {
    // this.breadcrumbsService.resetCustomPath();
  }

  protected _getRequestControllerPath(): string {
    return this.config.resource + '/tenant/' + this.authService.selectedTenant.tenantId.toString();
  }

  _responseHandling(response: IPageableReq, resourceIdName: string = '') {
    this.isLoading = false;
    this.pageable = response.paging;
    response.items = this.filterResponse(response.items);
    if (!this.filterList.length || !this.rows.length) {
      this.createColumns();
    }
    if (!this.columns.length) {
      if (this.tableObject) {
        this.createColumns();
      } else {
        this.columns = this.utilsService.createColums(response.items);
      }
    }
    response.items.forEach(el => {
      this.animationActionArray[el[resourceIdName]] = {
        isLoading: false,
        isDeleting: false,
        isAdding: false,
        isEditing: false
      };
    });
    this.rows = this._clone(response.items);
    this.temp = this._clone(response.items);
    if (this.currentDirectory) {
      this.setPrevDirectory(this.currentDirectory);
    }
    if (this.prevDirectory) {
      // this.rows.unshift({ name: this.backFolderName, path: this.prevDirectory, type: 2 });
      // this.temp.unshift({ name: this.backFolderName, path: this.prevDirectory, type: 2 });
      // this.pageable.totalItems++;
    }
    this._buildEntityColumn();
    this.pageable.pageNumber--;
  }

  createAttachment() {
    if (!this.currentPath) {
      // this.onCreateAttachment.next('');
      // this.close();
      return;
    }
    this.onCreateAttachment.next(this.currentPath);
    this.close();
  }

  onSelect(e) {
    if (e.type === 'click' && !this.isLoading) {
      const data = e.row;
      if (data.type === 2) {
        this.currentPath = data.path;
        this.onSelectedPath.next(this.currentPath);
        this.refreshDatatable(data.path);
      }
    }
  }

  private getPathName(path: string): string {
    if (path) {
      return path.match(this.regexp) ? path.match(this.regexp)[0].replace('/', '') : path.replace('/', '');
    } else {
      return '';
    }
  }

  toDetailPage(resourceId: string = null) {
    let path = resourceId;
    if (!resourceId || resourceId.length === 1) {
      path = '';
    }
    this.router.navigate(['/documents'], { queryParams: { path: this.currentPath } });
    this.router.navigate([`/documents/document-access`], { queryParams: { path: path } });
  }

  setPrevDirectory(path: string) {
    this.currentDirectory = path;
    this.prevDirectory = this.currentDirectory.replace(this.regexp, '');
    if (this.currentDirectory === this.prevDirectory) {
      this.prevDirectory = ' ';
    }
    if (this.currentDirectory === ' ') {
      this.prevDirectory = null;
    }
  }

  prevBreadcrumbsPage(path: string) {
    if (path !== this.currentDirectory) {
      this.breadcrumbsService.removeCustomPath(path);
      this.prevPage(path);
    }
  }

  btnPrevAction() {
    if (this.prevDirectory) {
      this.prevPage(this.prevDirectory);
      this.breadcrumbsService.removeCustomPath(this.prevDirectory);
    }
  }

  cancelAction() {
    this.createComponent.hide();
  }

  prevPage(path = this.prevDirectory) {
    if (path) {
      this.refreshDatatable(path);
    }
  }

  refreshDatatable(path: string = this.prevDirectory) {
    this.isLoading = true;
    this.colsArray = [];
    this.currentDirectory = path;
    this.colsArray.push({ key: 'path', value: encodeURIComponent(this.currentDirectory) });
    this._getDatatable();
  }

  close() {
    this.onClose.next(null);
  }

  isRoot() {
    return this.currentPath ? !!this.currentPath.trim() : false;
  }

}
