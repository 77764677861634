import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthorizationService, LocalStorageService } from '../../services';
import { LocalStorageKeys } from '../constants';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { from, throwError } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthorizationService,
    private storage: LocalStorageService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.authService.loggedIn) {
      if (state.url === '/signin') {
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    const refreshToken = this.storage.get(LocalStorageKeys.refreshToken);

    if (refreshToken) {
      return this.refreshLogin(refreshToken, state);
    }

    return this.handle(state);
  }

  private refreshLogin(refreshToken: string, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.refreshLogin$(refreshToken, ...this.authService.getSelectedRole()).pipe(
      first(),
      switchMap(({ token, restrictedRoleIds, info }) => from(this.authService.setPermissions(token)).pipe(
        map(() => ({ token, restrictedRoleIds, info }))
      )),
      map(({ token, restrictedRoleIds, info }) => {
        this.authService.filterRoles(info);
        this.authService.saveData(token, restrictedRoleIds, info);
        this.authService.loggedIn = true;
        return this.handle(state, true);
      }),
      catchError(() => {
        this.storage.remove(LocalStorageKeys.selectedRoles);
        this.storage.remove(LocalStorageKeys.refreshToken);
        return of(this.handle(state));
      })
    );
  }

  private handle(state: RouterStateSnapshot, didLogin: boolean = false): boolean {
    if (didLogin) {
      if (state.url === '/signin') {
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    if (state.url !== '/signin') {
      this.router.navigate(['/signin']);
      return false;
    }

    return true;
  }
}
