import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../services';
import { AppPermissions } from '../../models/server/Enums/Permissions';
import { PermissionService } from '../../../services/permission/permission.service';

@Directive({
  selector: '[appPermission]',
  providers: [PermissionService]
})
export class PermissionDirective implements OnInit {
  @Input('appPermission') appPermission: AppPermissions;

  constructor(
    private authService: AuthorizationService,
    private elementRef: ElementRef
  ) {
  }

  private _toggle(hide: boolean) {
    if (hide) {
      this.elementRef.nativeElement.remove();
    }
  }

  async ngOnInit() {
    const hide = await this.authService.hasPermission(this.appPermission) == false;
    this._toggle(hide);
  }

}
