import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MenuService } from '../core/services';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from './user/user.module';

import { menu } from './menu';
import { routes } from './routes';
import { AuthGuard } from '../shared/guards';
import { StateService } from '../services/optional/state/state.service';
import { KnowledgeService } from '../services/knowledge/knowledge.service';
import { BreadcrumbsService } from '../core/services/breadcrumbs/breadcrumbs.service';
import {AlertBlockService} from '../core/services/alert-block/alert-block.service';
import {HelpProxyGuard} from '../shared/guards/help-proxy.guard';
import {CookieService} from 'ngx-cookie-service';
import {TextMaskModule} from 'angular2-text-mask';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes),
        UserModule,
        TextMaskModule,
    ],
    providers: [
        AuthGuard,
        StateService,
        BreadcrumbsService,
        KnowledgeService,
        HelpProxyGuard,
        CookieService,
        AlertBlockService
    ],
    exports: [
        RouterModule
    ]
})
export class RoutesModule {
    constructor(private menuService: MenuService) {
        this.menuService.addMenu(menu);
    }
}
