import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable()
export class BreadcrumbsService {
  resourceName: string = '';
  customPath: Array<any> = [];
  subscribe$: object = {
    resourceName: new Subject<any>(),
    customPath: new Subject<any>()
  };

  constructor() { }

  subscription$(path: string) {
    return this.subscribe$[path].asObservable();
  }

  setResourceName(name: string) {
    this.resourceName = name;
    this.subscribe$['resourceName'].next(this.resourceName);
  }

  addPath(path: any) {
    this.customPath.push(path);
    this.subscribe$['customPath'].next(this.customPath);
  }

  removeCustomPath(name) {
    const index = this.customPath.findIndex(el => el.path === name);

    if (this.customPath.length > 0 && index  === this.customPath.length - 1) {
      this.customPath.length = this.customPath.length - 1;
    } else {
      this.customPath.length = index + 1;
    }

    this.subscribe$['customPath'].next(this.customPath);
  }

  getPath() {
    if (this.customPath.length > 0 ) {
      return this.customPath[this.customPath.length - 1].path;
    } else {
      return '';
    }
  }

  resetCustomPath() {
    this.customPath = [];
    this.subscribe$['customPath'].next(this.customPath);
  }

  resetResourceName() {
    this.resourceName = '';
    this.subscribe$['resourceName'].next(this.resourceName);
  }

}
