import {Component, Input, OnInit} from '@angular/core';
import {Params, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-data-table-detail-button',
  templateUrl: './data-table-detail-button.component.html',
  styleUrls: ['./data-table-detail-button.component.scss']
})
export class DataTableDetailButtonComponent implements OnInit {

  @Input() url: string;

  @Input()
  set setParams(value: Params) {
    this.params = value;
    this.paramsUrl = `${this.url}?path=${this.params['path']}`;
  }

  @Input() iconStyle = true;

  params: Params;

  paramsUrl: string;

  constructor(private router: Router) { }

  toDetail(url: string) {
    this.router.navigate([url], { queryParams: this.params });
    event.preventDefault();
  }

  ngOnInit() {
  }

}
