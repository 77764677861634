import { Injectable } from '@angular/core';
import { AppFunctions } from '../../shared/models/server/Enums/Permissions';
import { PermissionPackageDto } from '../../shared/models/server/DataTransferObject/Objects/Authentication';
import { HttpRequestLoginService } from '../http-request-login/http-request-login.service';
import { Observable } from 'rxjs';
import { first, map, retry, shareReplay, timeout } from 'rxjs/operators';

@Injectable()
export class PermissionService {
  public permissions$: Observable<PermissionPackageDto[]>;

  constructor(
    private loginService: HttpRequestLoginService
  ) {
  }

  public init(): void {
    this.permissions$ = this.loadPermissions();
  }

  public loadPermissions(): Observable<PermissionPackageDto[]> {
    return this.loginService.get<PermissionPackageDto[]>(
      'permissionpackage',
      null,
      null,
      true
    ).pipe(
      map(response => response.body),
      shareReplay(1)
    );
  }

  public mergePermissions(permissions: PermissionPackageDto[]): AppFunctions[] {
    let permissionArray: AppFunctions[] = [];
    permissions.forEach((permission: PermissionPackageDto) => permissionArray.push(...permission.permissions));
    permissionArray = [...permissionArray];
    return permissionArray;
  }
}
