import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { TranslatorService } from './core/services';
import { LoaderService } from './services/loader/loader.service';
import { Subscription } from 'rxjs/Subscription';
import { ToasterConfig } from 'angular2-toaster';
import { TicketDto, TicketListDto } from './shared/models/server/DataTransferObject/Objects/Tickets';
import { getColumns, getFilterableColumns } from '../app/shared/models/server/Attributes';
import { AuthorizationService } from './services';
import { PermissionService } from './services/permission/permission.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  subscribe: Subscription;
  loader = true;
  public toasterConfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right'
  });

  constructor(
    private translatorService: TranslatorService,
    private loaderService: LoaderService,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit() {
    this.subscribe = this.loaderService.subscription$()
      .subscribe(res => {
        this.loader = res;
      });

    this.permissionService.init();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
}
