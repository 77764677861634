import {Component, OnInit, Output} from '@angular/core';
import {ContactGroupDto} from '../../models/server/DataTransferObject/Objects/MasterData/ContactGroups';
import {Subject} from 'rxjs';
import {ContactGroupService} from '../../../services/contactgroup/contactgroup.service';
import {FormService} from '../../../services/forms/form.service';

@Component({
  selector: 'app-forms-select',
  templateUrl: './forms-select.component.html',
  styleUrls: ['./forms-select.component.scss']
})
export class FormsSelectComponent implements OnInit {
  @Output() onChanged: Subject<number> = new Subject<number>();
  contactGroup;
  contactGroups;
  constructor(private formService: FormService) {
  }

  selectedGroup(groupId) {
    this.onChanged.next(groupId);
  }

  async ngOnInit() {
    try {
      const result = await this.formService.getList();
      if (result) {
        this.contactGroups = result;
        this.contactGroups.unshift({ name: 'All', contactGroupId: null })
        this.contactGroup = this.contactGroups[0].contactGroupId;
      }
    } catch(e) {

    }

  }
}
