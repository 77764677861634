import {AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TabComponent} from './tab/tab.component';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit, AfterContentInit {
  @Input() title: string;
  @Input() onlyTab: boolean;
  @Output() changeTab: EventEmitter<TabComponent> = new EventEmitter<TabComponent>();
  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;
  type: string;
  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  setActiveTab(tab: TabComponent = this.tabs.first) {
    if (tab) {
      this.router.navigate([], { queryParams: { tab: tab.title }});
      this.removeActiveTabs();
      this.changeTab.next(tab);
      tab.selected = true;
    }
  }

  removeActiveTabs() {
    this.tabs.forEach(el => el.selected = false);
  }

  ngAfterContentInit() {
    if (this.tabs.filter(element => element.selected).length === 0 && this.tabs.first) {
      const tab = this.tabs.first;
      tab.selected = true;
      this.router.navigate([], { queryParams: { tab: tab.title }});
    }
  }

}
