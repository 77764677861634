import { Injectable } from '@angular/core';
import {UtilsService} from '../../shared/utils/utils.service';
// import {HttpRequestService} from '../index';
import {Subject} from 'rxjs/Subject';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {IFilterField, IOperation, IPageableReq, IPaging, ISortObject} from '../../shared/interfaces/http';
import {HttpRequestService} from '../index';

@Injectable()
export class MasterDataService {

  resources$: Array<Subject<any>> = [];

  resource: IPageableReq;

  resourceName: string;

  constructor(private apiService: HttpRequestService,
                        private utilsService: UtilsService) { }
  subscription(resourceName: string) {
    this.resourceName = resourceName;
    this.resources$[resourceName] = new Subject<any>();
    return this.resources$[resourceName].asObservable();
  }

  getOne<T>(controllerPath: string, resourceId: string): Observable<T> {
    return this.apiService.get<T>([controllerPath, resourceId].join('/'))
      .pipe(map((response: HttpResponse<T>) => response.body));
  }


  filterByFields(controllerPath: string, filter: Array<IFilterField>, paging: IPaging, operation: IOperation['type'] = 'All', sortOptions: ISortObject = { sortField: null, sort: null } ) {
    const query = this.utilsService.queryBuilder([
      { key: 'operation', value: operation},
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
      { key: 'pageSize', value: paging.pageSize },
      { key: 'pageNumber', value: paging.pageNumber },
    ]);
    this.apiService.post<IPageableReq>([controllerPath, 'filter', query].join('/'), filter)
      .pipe(map((response: HttpResponse<IPageableReq>) => response.body ),
        tap(response => this.resource = response))
      .subscribe(response => this.resources$[this.resourceName].next(response));
  }

  deleteResource<T>(controllerPath: string, resourceId: string): Observable<T> {
    return this.apiService.delete<T>(controllerPath, resourceId)
      .pipe(map((response: HttpResponse<T>) => {
        return response.body;
      }));
  }

  removeResourceFromParent<T>(controllerPath: string, resourceId: string, parentResource: string, parentId: string) {
    return this.apiService.delete<T>([parentResource, parentId, controllerPath].join('/'), resourceId)
      .pipe(map((response: HttpResponse<T>) => {
        return response.body;
      }));
  }

  addResourceToParent<T>(controllerPath: string, resourceId: string, parentResource: string, parentId: string) {
    return this.apiService.post<T>([parentResource, parentId, controllerPath, resourceId].join('/'), {})
      .pipe(map((response: HttpResponse<T>) => {
        return response.body;
      }));
  }

}
