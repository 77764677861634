
export enum LocalStorageKeys {
  refreshToken = "refresh_token",
  loginEmail = "login_email",
  loginProvider = "login_provider",
  activeLanguage = "active_language",
  selectedRoles = 'selected_roles'
}


