import { Component, OnInit } from '@angular/core';
import {IAlertBlock} from '../../../shared/interfaces/ui';
import {AlertBlockService} from '../../services/alert-block/alert-block.service';
import {ActivationStart, Router} from '@angular/router';

@Component({
  selector: 'app-alert-block',
  templateUrl: './alert-block.component.html',
  styleUrls: ['./alert-block.component.scss']
})
export class AlertBlockComponent implements OnInit {

  alerts: IAlertBlock[] = [];

  constructor(private alertService: AlertBlockService,
              private router: Router) {
    this.router.events
      .filter(res => res instanceof ActivationStart)
      .subscribe(res => {
        this.alertService.clearAlerts();
      });
  }

  ngOnInit() {
    this.alertService.subscription$()
      .subscribe({
        next: (res) => this._successHandler(res)
      });
    this.alertService.getAlerts();
  }

  private _successHandler(response: IAlertBlock[]) {
    this.alerts = response;
  }

}
