import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { distinctUntilChanged } from 'rxjs/operators';
import { ActionBarService } from '../../../core/services/action-bar/action-bar.service';
import { StereotypeService } from '../../../services/stereotype/stereotype.service';
import { IActionButton } from '../../interfaces/ui';
import { ContactDto } from '../../models/server/DataTransferObject/Objects/MasterData/Contact';
import { ContactGroupDto } from '../../models/server/DataTransferObject/Objects/MasterData/ContactGroups';
import { LocationDto } from '../../models/server/DataTransferObject/Objects/MasterData/Locations';
import { AppRoleListDto } from '../../models/server/DataTransferObject/Objects/Permissions';
import * as Attr from './../../models/server/Attributes';
import { StereotypeDto } from '../../models/server/DataTransferObject/Objects/Stereotyping/Stereotypes';

@Component({
  selector: 'app-table-config',
  templateUrl: './table-config.component.html',
  styleUrls: ['./table-config.component.scss'],
  providers: [StereotypeService]
})
export class TableConfigComponent implements OnInit {
  buttons: IActionButton[] = [
  ];
  config: any = {
    title: 'title',
  };
  tableObject: any;
  isCreated: boolean;
  isLoading = true;
  _form: FormGroup;
  _fields: any = {};
  _prfx = '';
  _columns: Array<string> = [];
  _stereotypeView: StereotypeDto;
  _cacheDefault: Array<string> = [];
  _cacheStereotypes: Array<number> = [];
  type: string;
  stereotype: string;
  stereotypeProperties: any = {};

  constructor(private stereotypeService: StereotypeService,
              private actionBarService: ActionBarService,
              private route: ActivatedRoute,
              private router: Router,
              private toasterService: ToasterService,
              private _fb: FormBuilder,
              private ts: TranslateService) {
    this.actionBarService.setActionButtons(this.buttons);
  }

  stereotypeLoading(value) {
    this.isLoading = value;
  }

  changeStereotypesSet() {
    this._cacheStereotypes = [];
    for (const obj in this.stereotypeProperties) {
      if (this.stereotypeProperties.hasOwnProperty(obj) && this.stereotypeProperties[obj]) {
        this._cacheStereotypes.push(+obj);
      }
    }
  }

  setStereotypeSet(properties: Array<number>) {
    properties.forEach(prop => this.stereotypeProperties[prop] = true);
    this._cacheStereotypes = properties;
  }

  selectStereotype(stereotypeId: number) {
    this.isLoading = true;
    this.stereotypeService.getStereotypeSets(stereotypeId + '')
      .subscribe(response => {
        this.isLoading = false;
        this._stereotypeView = response;
      });
  }

  valueHandler(value): Array<string> {
    this._cacheDefault = [];
    for (const obj in value) {
      if (value.hasOwnProperty(obj) && value[obj]) {
        this._cacheDefault.push(obj);
      }
    }
    return this._cacheDefault;
  }

  setFormValue(value: Array<string> = []) {
    if (JSON.parse(localStorage.getItem(this.type)) && this._form) {
      const values = JSON.parse(localStorage.getItem(this.type));
      this._cacheDefault = values;
      values.forEach(el => {
        if (this._form.get(el)) {
          this._form.get(el).patchValue(true)
        }
      });
    }
    if (JSON.parse(localStorage.getItem(`${this.type}_stereotype`))) {
      const values = JSON.parse(localStorage.getItem(`${this.type}_stereotype`));
      this.setStereotypeSet(values);
    }
  }

  setColumns(type: string) {
    this._prfx = '';
    switch (type) {
      case 'contacts':
        this.stereotype = 'ticket';
        this.tableObject = new ContactDto();
        this._prfx = 'masterdata/';
        break;
      case 'contactgroups':
        this.stereotype = 'contactgroup';
        this.tableObject = new ContactGroupDto();
        this._prfx = 'masterdata/';
        break;
      case 'locations':
        this.stereotype = 'location';
        this.tableObject = new LocationDto();
        this._prfx = 'masterdata/';
        break;
      case 'roles':
        this.stereotype = 'role';
        this.tableObject = new AppRoleListDto();
        break;
      default:
        this.stereotype = type;
        break;
    }
    if (this.tableObject) {
      const cols = Attr.getColumns(this.tableObject);
      for (const obj in cols) {
        if (cols.hasOwnProperty(obj) && cols[obj]) {
          this._columns.push(obj);
        }
      }
    }
  }

  save() {
    // this.isCreated = true;
    localStorage.setItem(this.type, JSON.stringify(this._cacheDefault));
    localStorage.setItem(`${this.type}_stereotype`, JSON.stringify(this._cacheStereotypes));
    this.showToaster('success', 'SUCCESS.saved');
  }


  initForm() {
    this._form = this._fb.group(this._fields);
  }

  showToaster(type: string, msg: string, title: string = '') {
    this.ts.get(msg).subscribe(response => {
      this.toasterService.pop(type, title, response);
    });
  }

  ngOnInit() {
    this.route.params.subscribe(value => this.type = value.type);
    this.config.title = 'TITLE.tableConfig.' + this.type;
    this.setColumns(this.type);
    this._columns.forEach(el => this._fields[el] = ['']);
    this.initForm();
    this._form.valueChanges
      .pipe(distinctUntilChanged())
      .debounceTime(400)
      .subscribe(value => {
        this.valueHandler(value);
      });
    this.setFormValue();
  }

}
