import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoleService} from '../../../services/role/role.service';
import {AppPermissionDto, AppRoleDto} from '../../models/server/DataTransferObject/Objects/Permissions';
import {IDropdownItem} from '../../interfaces/ui';
import {AutocompleteBase} from '../../classes/autocomplete-base';
import {AutocompleteService} from '../../../services/autocomplete/autocomplete.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-roles-autocomplete',
  templateUrl: './roles-autocomplete.component.html',
  styleUrls: ['./roles-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class RolesAutocompleteComponent extends AutocompleteBase implements OnInit {
  @Output() changeRole: EventEmitter<AppRoleDto> = new EventEmitter<AppRoleDto>();
  @Input() roleId_: number;

  selectedRoleId: number;
  typeaheadValue$: Subject<string> = new Subject<string>();

  set roleId(value: number) {
    this.setRole(value);
  }

  get roleId() {
    return this.roleId_;
  }

  constructor(protected autocompleteService: AutocompleteService) {
    super(autocompleteService);
    this.url = 'role';
    this.getAll();
  }

  setRole(roleId: AppRoleDto | number ) {
    if (roleId.hasOwnProperty('appRoleId')) {
      this.selectedRoleId = roleId['appRoleId'];
      return;
    } else {
      this.selectedRoleId = roleId as number;
    }
  }

  reset() {
    this.selectedRoleId = undefined;
    this.roleId_ = undefined;
  }

  onChangeRole(event) {
    this.changeRole.next(event);
  }

  ngOnInit() {
    this.typeaheadValue$
      .subscribe(value => {
        this.buildFilter('name', value);
      });
  }

}
