import { Injectable } from '@angular/core';
import {IService} from '../../shared/interfaces/ui-services';
import {Subject} from 'rxjs/Subject';
import {HttpRequestService} from '../';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {ContactGroup} from '../../shared/models/tenant/contact-group';
import {map, tap} from 'rxjs/operators';
import {IFilterField, IOperation, IPageableReq, IPaging, ISortObject} from '../../shared/interfaces/http';
import {UtilsService} from '../../shared/utils/utils.service';

@Injectable()
export class ContactGroupService implements IService {
  apiContactGroupControllerPath = 'contactgroup';
  _subscribe: Subject<any> = new Subject<any>();
  constructor(private apiService: HttpRequestService,
                        private utilsService: UtilsService) { }

  subscription$() {
    return this._subscribe.asObservable();
  }

  getContactGroupListByQuery(query: string) {
    const body = { Property: 'name', Value: query };
    return this.apiService.post([this.apiContactGroupControllerPath, 'filter'].join('/'), body)
      .debounceTime(400)
      .pipe(map((response: HttpResponse<IPageableReq>) => response.body.items ))
  }

  getContactGroupList() {
    return this.apiService.get([this.apiContactGroupControllerPath].join('/'))
      .pipe(map((response: HttpResponse<IPageableReq>) => response.body.items));
  }

  getContactGroups(params: HttpParams = null, sortOptions: ISortObject = { sortField: null, sort: null }) {
    const query = this.utilsService.queryBuilder([
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
    ]);
    this.apiService.get([this.apiContactGroupControllerPath, query].join('/'), null, params)
      .pipe(map((response: HttpResponse<any>) => response.body))
      .subscribe(response => {
        this._subscribe.next(response);
      });
  }

  addContactToGroup(contactGroupId: number, contactId: number) {
    return this.apiService.post<any>([this.apiContactGroupControllerPath, contactGroupId, 'contact', contactId].join('/'), {})
  }

  removeContactFromGroup(contactGroupId: number, contactId: number) {
    return this.apiService.delete<any>([this.apiContactGroupControllerPath, contactGroupId, 'contact'].join('/'), contactId + '')
  }

  filterByFields(filter: Array<IFilterField>, paging: IPaging, operation: IOperation['type'] = 'All', sortOptions: ISortObject = { sortField: null, sort: null } ) {
    const query = this.utilsService.queryBuilder([
      { key: 'operation', value: operation},
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
      { key: 'pageSize', value: paging.pageSize },
      { key: 'pageNumber', value: paging.pageNumber },
    ]);
    return this.apiService.post<any>([this.apiContactGroupControllerPath, 'filter', query].join('/'), filter)
      .pipe(map((response: HttpResponse<any>) => {
        return response.body; }))
      .subscribe(response => this._subscribe.next(response));
  }

  filterContactsByFIeldsByContactGroupId(id: number, filter: Array<IFilterField>) {
    return this.apiService.post<any>([this.apiContactGroupControllerPath, id, 'contact', 'filter'].join('/'), filter)
      .pipe(map((response: HttpResponse<any>) => {
        return response.body; }));
  }

  getContactsByContactGroupId(params: HttpParams = null, id: number) {
    return this.apiService.get([this.apiContactGroupControllerPath, id, 'contact'].join('/'), null, params)
      .pipe(map((response: HttpResponse<any>) => response.body));
  }

   getContactGroupById(id: number) {
    return this.apiService.get([this.apiContactGroupControllerPath, id].join('/'))
      .pipe(map( (response: HttpResponse<any>) => response.body))
      .subscribe(response => this._subscribe.next(response));
  }

  postContactGroup(data: ContactGroup) {
    return this.apiService.post(this.apiContactGroupControllerPath, data)
      .pipe(tap(() => this.getContactGroups()),
        map( (response: HttpResponse<any>) => response.body));
  }

  deleteContactGroup(id: number) {
    return this.apiService.delete(this.apiContactGroupControllerPath, '' + id);
  }

  putContactGroup(data: ContactGroup) {
    return this.apiService.update([this.apiContactGroupControllerPath, data.contactGroupId].join('/'), data)
      .pipe(tap(response => this.getContactGroups()), map( (response: HttpResponse<any>) => response.body));
  }

}
