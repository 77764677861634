import {Injectable} from '@angular/core';
import {SettingSection} from '../../shared/models/settings/setting-section';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';

@Injectable()
export class DynamicFormBuilder {

  constructor(private _fb: FormBuilder) { }

  public createForm(section: SettingSection): FormGroup {
    const settings = section.settings;
    const formObj = {};

    for (const obj in settings) {
      if (settings[obj]) {
        const field = settings[obj];
        const value = field.value ? field.value : field.defaultValue;
        const validators = validatorsBuilder(field);
        formObj[field.settingKey] = [value, Validators.compose(validators)];
      }
    }

    function validatorsBuilder(config: any): Array<any> {
      const array = [];
      if (config.required) {
        array.push(Validators.required);
      }
      if (config.settingType === 4) {
        array.push(CustomValidators.email);
      }
      return array;
    }
    return  this._fb.group(formObj);
  }
}
