import { Injectable } from '@angular/core';
import { HttpRequestService } from '..';
import { FormCreateDto } from '../../shared/models/server/DataTransferObject/Objects/Forms/Create';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class FormService {

  constructor(
    private apiService: HttpRequestService
  ) { }

  public post(form: FormCreateDto) : Promise<FormCreateDto> {
    return this.apiService.post<FormCreateDto>(['form'].join('/'), form).pipe(map((response: HttpResponse<FormCreateDto>) => response.body)).toPromise();
  }
  public get(formId: number) : Promise<FormCreateDto> {
    return this.apiService.get<FormCreateDto>(['form', formId.toString()].join('/')).pipe(map((response: HttpResponse<FormCreateDto>) => response.body)).toPromise();
  }
  public getList() : Promise<FormCreateDto> {
    return this.apiService.get<FormCreateDto>('form').pipe(map((response: HttpResponse<FormCreateDto>) => response.body)).toPromise();
  }
}
