import { Injectable } from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class HttpRequestBaseService {

  constructor(protected httpClient: HttpClient, protected httpBackend: HttpBackend) {}

  public get<T>(
    path: string,
    headers: HttpHeaders = null,
    params: HttpParams = null,
    authorized: boolean = true,
    apiPath: string = null): Observable<HttpResponse<T>> {
    const apiPreperedPath = apiPath ? apiPath : environment.apiUrl.orga;
    return this.getHttpClient(authorized)
      .get<T>([apiPreperedPath, path].join('/'), { headers: headers, observe: 'response', params: params });
  }

  public post<T>(
    path: string,
    body: any,
    headers: HttpHeaders = null,
    authorized: boolean = true,
    apiPath: string = null, params: HttpParams = null): Observable<HttpResponse<T>> {
    const apiPreperedPath = apiPath ? apiPath : environment.apiUrl.orga;
    const postUrl = [apiPreperedPath, path].join('/');
    return this.getHttpClient(authorized)
      .post<T>(postUrl, body, { headers: headers, observe: 'response', params: params });
  }

  public delete<T>(
    path: string,
    id: string,
    headers: HttpHeaders = null,
    authorized: boolean = true,
    apiPath: string = null): Observable<HttpResponse<T>> {
    const apiPreperedPath = apiPath ? apiPath : environment.apiUrl.orga;
    let deleteUrl;
    if (id) {
      deleteUrl = [apiPreperedPath, path, id].join('/');
    } else {
      deleteUrl = [apiPreperedPath, path].join('/');
    }
    return this.getHttpClient(authorized)
      .delete<T>(deleteUrl, { headers: headers, observe: 'response' });
  }

  public update<T>(
    path: string,
    object: T,
    headers: HttpHeaders = null,
    authorized: boolean = true,
    apiPath: string = null): Observable<HttpResponse<T>> {
    const apiPreperedPath = apiPath ? apiPath : environment.apiUrl.orga;
    return this.getHttpClient(authorized)
      .put<T>([apiPreperedPath, path].join('/'), object, { headers: headers, observe: 'response' });
  }

  protected getHttpClient(authorized: boolean = true) {
    if (authorized === true) {
      return this.httpClient;
    } else {
      return new HttpClient(this.httpBackend);
    }
  }
}
