import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../../services';
import { LocalStorageKeys } from '../../../shared/constants';
import { Translation } from '../../../shared/models';
import { ApiTranslateHttpLoader } from './ApiTranslateHttpLoader';




@Injectable()
export class TranslatorService {

  defaultLanguage: string = 'de-DE';
  availablelangs: any;
  currentLang: string = this.defaultLanguage;
  public translatedTexts: Translation;


  constructor(
    private translate: TranslateService,
    private storage: LocalStorageService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.setLang();

    this.availablelangs = [
      { code: 'en-US', text: 'English' },
      { code: 'de-DE', text: 'German' },
      { code: 'pl-PL', text: 'Polish' }
    ];

  }

  static loadLanguage(http: HttpClient): TranslateHttpLoader {
    return new ApiTranslateHttpLoader(http, [environment.apiUrl.orga, environment.apiUrl.translatePath].join('/'), '');
  }

  setLang() {
    if (localStorage.getItem(LocalStorageKeys.activeLanguage)) {
      this.translate.setDefaultLang(localStorage.getItem(LocalStorageKeys.activeLanguage));
    } else {
      this.translate.setDefaultLang(this.defaultLanguage);
    }
  }

  setActiveLanguage(lang: string) {
    this.storage.set(LocalStorageKeys.activeLanguage, lang);
  }

  getActiveLanguage(): string {
    return localStorage.getItem(LocalStorageKeys.activeLanguage);
  }

  getActiveOrDefaultLanguage(): string {
    let activeLanguage = this.getActiveLanguage();
    if (activeLanguage) {
      return activeLanguage;
    }
    else {
      return this.defaultLanguage;
    }
  }

  useLanguage(lang: string = this.defaultLanguage) {
    this.translate.use(lang);
    this.currentLang = lang;
    localStorage.setItem(LocalStorageKeys.activeLanguage, this.currentLang);
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

  getCurrentLang() {
    for (let i in this.availablelangs) {
      if (this.availablelangs[i].code === this.currentLang) {
        return this.availablelangs[i].text;
      }
    }
  }

}
