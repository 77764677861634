import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteBase } from '../../classes/autocomplete-base';
import { Subject } from 'rxjs';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-holidayset-autocomplete',
  templateUrl: './holidayset-autocomplete.component.html',
  styleUrls: ['./holidayset-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class HolidaysetAutocompleteComponent extends AutocompleteBase implements OnInit {

  @Input() control: FormControl = new FormControl('');

  @Input()
  set items(value) {
    this._items = [...this._items, ...value];
  }

  @Input() public readonly: boolean;
  @Input() public initId: number;

  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();
  public selected: number;
  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(
    protected autocompleteService: AutocompleteService
  ) {
    super(autocompleteService);
    this.url = 'holidayset';
  }

  async ngOnInit() {
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter('name', value);
    });
    await this.getAll();
    if (!this.selected && this._items && this._items.length > 0 && this.initId) {
      this.selected = this.initId;
      const item = this._items.find(x => x.holidaySetId == this.selected);
      this.changeValue.next(item);
    }
  }

  onChange(element) {
    this.changeValue.next(element);
  }

}
