//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AppEntityType { 
	None = 0, 
	Ticket = 1, 
	Resource = 3, 
	Address = 4, 
	Company = 5, 
	Contact = 6, 
	ContactGroup = 7, 
	Location = 8, 
	Stereotype = 9, 
	Role = 10, 
	LocationGroup = 11, 
	Setting = 12, 
	User = 14, 
	Info = 15, 
	KnowledgeArticle = 16, 
	Form = 17, 
	Task = 18, 
	TaskJob = 19
}
export enum AttachmentTypes { 
	Base = 0, 
	File = 1, 
	FilledForm = 2, 
	Knowledge = 3, 
	Folder = 4, 
	Link = 5
}
export enum CharGroups { 
	LowerCase = 1, 
	UpperCase = 2, 
	NumberCase = 4
}
export enum ChatMemberType { 
	Base = 0, 
	Location = 1, 
	Contact = 2, 
	ContactGroup = 3, 
	External = 4
}
export enum CodeStatus { 
	UnUsed = 0, 
	InUse = 1, 
	Expired = 2
}
export enum ConditionTypes { 
	Base = 0, 
	Percentage = 1, 
	Time = 2, 
	ChangedStatus = 3
}
export enum ContentType { 
	AAB = 0, 
	AAC = 1, 
	AAM = 2, 
	AAS = 3, 
	ABW = 4, 
	AC = 5, 
	ACC = 6, 
	ACE = 7, 
	ACU = 8, 
	ACUTC = 9, 
	ADP = 10, 
	AEP = 11, 
	AFM = 12, 
	AFP = 13, 
	AHEAD = 14, 
	AI = 15, 
	AIF = 16, 
	AIFC = 17, 
	AIFF = 18, 
	AIR = 19, 
	AIT = 20, 
	AMI = 21, 
	APK = 22, 
	APPCACHE = 23, 
	APPLICATION = 24, 
	APR = 25, 
	ARC = 26, 
	ASC = 27, 
	ASF = 28, 
	ASM = 29, 
	ASO = 30, 
	ASX = 31, 
	ATC = 32, 
	ATOM = 33, 
	ATOMCAT = 34, 
	ATOMSVC = 35, 
	ATX = 36, 
	AU = 37, 
	AVI = 38, 
	AW = 39, 
	AZF = 40, 
	AZS = 41, 
	AZW = 42, 
	BAT = 43, 
	BCPIO = 44, 
	BDF = 45, 
	BDM = 46, 
	BED = 47, 
	BH2 = 48, 
	BIN = 49, 
	BLB = 50, 
	BLORB = 51, 
	BMI = 52, 
	BMP = 53, 
	BOOK = 54, 
	BOX = 55, 
	BOZ = 56, 
	BPK = 57, 
	BTIF = 58, 
	BZ = 59, 
	BZ2 = 60, 
	C = 61, 
	C11AMC = 62, 
	C11AMZ = 63, 
	C4D = 64, 
	C4F = 65, 
	C4G = 66, 
	C4P = 67, 
	C4U = 68, 
	CAB = 69, 
	CAF = 70, 
	CAP = 71, 
	CAR = 72, 
	CAT = 73, 
	CB7 = 74, 
	CBA = 75, 
	CBR = 76, 
	CBT = 77, 
	CBZ = 78, 
	CC = 79, 
	CCT = 80, 
	CCXML = 81, 
	CDBCMSG = 82, 
	CDF = 83, 
	CDKEY = 84, 
	CDMIA = 85, 
	CDMIC = 86, 
	CDMID = 87, 
	CDMIO = 88, 
	CDMIQ = 89, 
	CDX = 90, 
	CDXML = 91, 
	CDY = 92, 
	CER = 93, 
	CFS = 94, 
	CGM = 95, 
	CHAT = 96, 
	CHM = 97, 
	CHRT = 98, 
	CIF = 99, 
	CII = 100, 
	CIL = 101, 
	CLA = 102, 
	CLASS = 103, 
	CLKK = 104, 
	CLKP = 105, 
	CLKT = 106, 
	CLKW = 107, 
	CLKX = 108, 
	CLP = 109, 
	CMC = 110, 
	CMDF = 111, 
	CML = 112, 
	CMP = 113, 
	CMX = 114, 
	COD = 115, 
	COM = 116, 
	CONF = 117, 
	CPIO = 118, 
	CPP = 119, 
	CPT = 120, 
	CRD = 121, 
	CRL = 122, 
	CRT = 123, 
	CRYPTONOTE = 124, 
	CSH = 125, 
	CSML = 126, 
	CSP = 127, 
	CSS = 128, 
	CST = 129, 
	CSV = 130, 
	CU = 131, 
	CURL = 132, 
	CWW = 133, 
	CXT = 134, 
	CXX = 135, 
	DAE = 136, 
	DAF = 137, 
	DART = 138, 
	DATALESS = 139, 
	DAVMOUNT = 140, 
	DBK = 141, 
	DCR = 142, 
	DCURL = 143, 
	DD2 = 144, 
	DDD = 145, 
	DEB = 146, 
	DEF = 147, 
	DEPLOY = 148, 
	DER = 149, 
	DFAC = 150, 
	DGC = 151, 
	DIC = 152, 
	DIF = 153, 
	DIR = 154, 
	DIS = 155, 
	DIST = 156, 
	DISTZ = 157, 
	DJV = 158, 
	DJVU = 159, 
	DLL = 160, 
	DMG = 161, 
	DMP = 162, 
	DMS = 163, 
	DNA = 164, 
	DOC = 165, 
	DOCM = 166, 
	DOCX = 167, 
	DOT = 168, 
	DOTM = 169, 
	DOTX = 170, 
	DP = 171, 
	DPG = 172, 
	DRA = 173, 
	DSC = 174, 
	DSSC = 175, 
	DTB = 176, 
	DTD = 177, 
	DTS = 178, 
	DTSHD = 179, 
	DUMP = 180, 
	DV = 181, 
	DVB = 182, 
	DVI = 183, 
	DWF = 184, 
	DWG = 185, 
	DXF = 186, 
	DXP = 187, 
	DXR = 188, 
	ECELP4800 = 189, 
	ECELP7470 = 190, 
	ECELP9600 = 191, 
	ECMA = 192, 
	EDM = 193, 
	EDX = 194, 
	EFIF = 195, 
	EI6 = 196, 
	ELC = 197, 
	EMF = 198, 
	EML = 199, 
	EMMA = 200, 
	EMZ = 201, 
	EOL = 202, 
	EOT = 203, 
	EPS = 204, 
	EPUB = 205, 
	ES3 = 206, 
	ESA = 207, 
	ESF = 208, 
	ET3 = 209, 
	ETX = 210, 
	EVA = 211, 
	EVY = 212, 
	EXE = 213, 
	EXI = 214, 
	EXT = 215, 
	EXTENSIONS = 216, 
	EZ = 217, 
	EZ2 = 218, 
	EZ3 = 219, 
	F = 220, 
	F4V = 221, 
	F77 = 222, 
	F90 = 223, 
	FBS = 224, 
	FCDT = 225, 
	FCS = 226, 
	FDF = 227, 
	FE_LAUNCH = 228, 
	FG5 = 229, 
	FGD = 230, 
	FH = 231, 
	FH4 = 232, 
	FH5 = 233, 
	FH7 = 234, 
	FHC = 235, 
	FIG = 236, 
	FLAC = 237, 
	FLI = 238, 
	FLO = 239, 
	FLV = 240, 
	FLW = 241, 
	FLX = 242, 
	FLY = 243, 
	FM = 244, 
	FNC = 245, 
	FOR = 246, 
	FPX = 247, 
	FRAME = 248, 
	FSC = 249, 
	FST = 250, 
	FTC = 251, 
	FTI = 252, 
	FVT = 253, 
	FXP = 254, 
	FXPL = 255, 
	FZS = 256, 
	G2W = 257, 
	G3 = 258, 
	G3W = 259, 
	GAC = 260, 
	GAM = 261, 
	GBR = 262, 
	GCA = 263, 
	GDL = 264, 
	GEO = 265, 
	GEX = 266, 
	GGB = 267, 
	GGT = 268, 
	GHF = 269, 
	GIF = 270, 
	GIM = 271, 
	GML = 272, 
	GMX = 273, 
	GNUMERIC = 274, 
	GPH = 275, 
	GPX = 276, 
	GQF = 277, 
	GQS = 278, 
	GRAM = 279, 
	GRAMPS = 280, 
	GRE = 281, 
	GRV = 282, 
	GRXML = 283, 
	GSF = 284, 
	GTAR = 285, 
	GTM = 286, 
	GTW = 287, 
	GV = 288, 
	GXF = 289, 
	GXT = 290, 
	H = 291, 
	H261 = 292, 
	H263 = 293, 
	H264 = 294, 
	HAL = 295, 
	HBCI = 296, 
	HDF = 297, 
	HH = 298, 
	HLP = 299, 
	HPGL = 300, 
	HPID = 301, 
	HPS = 302, 
	HQX = 303, 
	HTKE = 304, 
	HTM = 305, 
	HTML = 306, 
	HVD = 307, 
	HVP = 308, 
	HVS = 309, 
	I2G = 310, 
	IC = 311, 
	ICC = 312, 
	ICE = 313, 
	ICM = 314, 
	ICO = 315, 
	ICS = 316, 
	IEF = 317, 
	IFB = 318, 
	IFM = 319, 
	IGES = 320, 
	IGL = 321, 
	IGM = 322, 
	IGS = 323, 
	IGX = 324, 
	IIF = 325, 
	IMP = 326, 
	IMS = 327, 
	IN = 328, 
	INK = 329, 
	INKML = 330, 
	INSTALL = 331, 
	IOTA = 332, 
	IPFIX = 333, 
	IPK = 334, 
	IRM = 335, 
	IRP = 336, 
	ISO = 337, 
	ITP = 338, 
	IVP = 339, 
	IVU = 340, 
	JAD = 341, 
	JAM = 342, 
	JAR = 343, 
	JAVA = 344, 
	JISP = 345, 
	JLT = 346, 
	JNLP = 347, 
	JODA = 348, 
	JP2 = 349, 
	JPE = 350, 
	JPEG = 351, 
	JPG = 352, 
	JPGM = 353, 
	JPGV = 354, 
	JPM = 355, 
	JS = 356, 
	JSON = 357, 
	JSONML = 358, 
	KAR = 359, 
	KARBON = 360, 
	KFO = 361, 
	KIA = 362, 
	KML = 363, 
	KMZ = 364, 
	KNE = 365, 
	KNP = 366, 
	KON = 367, 
	KPR = 368, 
	KPT = 369, 
	KPXX = 370, 
	KSP = 371, 
	KTR = 372, 
	KTX = 373, 
	KTZ = 374, 
	KWD = 375, 
	KWT = 376, 
	LASXML = 377, 
	LATEX = 378, 
	LBD = 379, 
	LBE = 380, 
	LES = 381, 
	LHA = 382, 
	LINK66 = 383, 
	LIST = 384, 
	LIST3820 = 385, 
	LISTAFP = 386, 
	LNK = 387, 
	LOG = 388, 
	LOSTXML = 389, 
	LRF = 390, 
	LRM = 391, 
	LTF = 392, 
	LVP = 393, 
	LWP = 394, 
	LZH = 395, 
	M13 = 396, 
	M14 = 397, 
	M1V = 398, 
	M21 = 399, 
	M2A = 400, 
	M2V = 401, 
	M3A = 402, 
	M3U = 403, 
	M3U8 = 404, 
	M4A = 405, 
	M4B = 406, 
	M4P = 407, 
	M4U = 408, 
	M4V = 409, 
	MA = 410, 
	MAC = 411, 
	MADS = 412, 
	MAG = 413, 
	MAKER = 414, 
	MAN = 415, 
	MAR = 416, 
	MATHML = 417, 
	MB = 418, 
	MBK = 419, 
	MBOX = 420, 
	MC1 = 421, 
	MCD = 422, 
	MCURL = 423, 
	MDB = 424, 
	MDI = 425, 
	ME = 426, 
	MESH = 427, 
	META4 = 428, 
	METALINK = 429, 
	METS = 430, 
	MFM = 431, 
	MFT = 432, 
	MGP = 433, 
	MGZ = 434, 
	MID = 435, 
	MIDI = 436, 
	MIE = 437, 
	MIF = 438, 
	MIME = 439, 
	MJ2 = 440, 
	MJP2 = 441, 
	MK3D = 442, 
	MKA = 443, 
	MKS = 444, 
	MKV = 445, 
	MLP = 446, 
	MMD = 447, 
	MMF = 448, 
	MMR = 449, 
	MNG = 450, 
	MNY = 451, 
	MOBI = 452, 
	MODS = 453, 
	MOV = 454, 
	MOVIE = 455, 
	MP2 = 456, 
	MP21 = 457, 
	MP2A = 458, 
	MP3 = 459, 
	MP4 = 460, 
	MP4A = 461, 
	MP4S = 462, 
	MP4V = 463, 
	MPC = 464, 
	MPE = 465, 
	MPEG = 466, 
	MPG = 467, 
	MPG4 = 468, 
	MPGA = 469, 
	MPKG = 470, 
	MPM = 471, 
	MPN = 472, 
	MPP = 473, 
	MPT = 474, 
	MPY = 475, 
	MQY = 476, 
	MRC = 477, 
	MRCX = 478, 
	MS = 479, 
	MSCML = 480, 
	MSEED = 481, 
	MSEQ = 482, 
	MSF = 483, 
	MSH = 484, 
	MSI = 485, 
	MSL = 486, 
	MSTY = 487, 
	MTS = 488, 
	MUS = 489, 
	MUSICXML = 490, 
	MVB = 491, 
	MWF = 492, 
	MXF = 493, 
	MXL = 494, 
	MXML = 495, 
	MXS = 496, 
	MXU = 497, 
	N3 = 498, 
	NB = 499, 
	NBP = 500, 
	NC = 501, 
	NCX = 502, 
	NFO = 503, 
	NGDAT = 504, 
	NITF = 505, 
	NLU = 506, 
	NML = 507, 
	NND = 508, 
	NNS = 509, 
	NNW = 510, 
	NPX = 511, 
	NSC = 512, 
	NSF = 513, 
	NTF = 514, 
	NZB = 515, 
	OA2 = 516, 
	OA3 = 517, 
	OAS = 518, 
	OBD = 519, 
	OBJ = 520, 
	ODA = 521, 
	ODB = 522, 
	ODC = 523, 
	ODF = 524, 
	ODFT = 525, 
	ODG = 526, 
	ODI = 527, 
	ODM = 528, 
	ODP = 529, 
	ODS = 530, 
	ODT = 531, 
	OGA = 532, 
	OGG = 533, 
	OGV = 534, 
	OGX = 535, 
	OMDOC = 536, 
	ONEPKG = 537, 
	ONETMP = 538, 
	ONETOC = 539, 
	ONETOC2 = 540, 
	OPF = 541, 
	OPML = 542, 
	OPRC = 543, 
	ORG = 544, 
	OSF = 545, 
	OSFPVG = 546, 
	OTC = 547, 
	OTF = 548, 
	OTG = 549, 
	OTH = 550, 
	OTI = 551, 
	OTP = 552, 
	OTS = 553, 
	OTT = 554, 
	OXPS = 555, 
	OXT = 556, 
	P = 557, 
	P10 = 558, 
	P12 = 559, 
	P7B = 560, 
	P7C = 561, 
	P7M = 562, 
	P7R = 563, 
	P7S = 564, 
	P8 = 565, 
	PAS = 566, 
	PAW = 567, 
	PBD = 568, 
	PBM = 569, 
	PCAP = 570, 
	PCF = 571, 
	PCL = 572, 
	PCLXL = 573, 
	PCT = 574, 
	PCURL = 575, 
	PCX = 576, 
	PDB = 577, 
	PDF = 578, 
	PFA = 579, 
	PFB = 580, 
	PFM = 581, 
	PFR = 582, 
	PFX = 583, 
	PGM = 584, 
	PGN = 585, 
	PGP = 586, 
	PIC = 587, 
	PICT = 588, 
	PKG = 589, 
	PKI = 590, 
	PKIPATH = 591, 
	PLB = 592, 
	PLC = 593, 
	PLF = 594, 
	PLS = 595, 
	PML = 596, 
	PNG = 597, 
	PNM = 598, 
	PNT = 599, 
	PNTG = 600, 
	PORTPKG = 601, 
	POT = 602, 
	POTM = 603, 
	POTX = 604, 
	PPAM = 605, 
	PPD = 606, 
	PPM = 607, 
	PPS = 608, 
	PPSM = 609, 
	PPSX = 610, 
	PPT = 611, 
	PPTM = 612, 
	PPTX = 613, 
	PQA = 614, 
	PRC = 615, 
	PRE = 616, 
	PRF = 617, 
	PS = 618, 
	PSB = 619, 
	PSD = 620, 
	PSF = 621, 
	PSKCXML = 622, 
	PTID = 623, 
	PUB = 624, 
	PVB = 625, 
	PWN = 626, 
	PYA = 627, 
	PYV = 628, 
	QAM = 629, 
	QBO = 630, 
	QFX = 631, 
	QPS = 632, 
	QT = 633, 
	QTI = 634, 
	QTIF = 635, 
	QWD = 636, 
	QWT = 637, 
	QXB = 638, 
	QXD = 639, 
	QXL = 640, 
	QXT = 641, 
	RA = 642, 
	RAM = 643, 
	RAR = 644, 
	RAS = 645, 
	RCPROFILE = 646, 
	RDF = 647, 
	RDZ = 648, 
	REP = 649, 
	RES = 650, 
	RGB = 651, 
	RIF = 652, 
	RIP = 653, 
	RIS = 654, 
	RL = 655, 
	RLC = 656, 
	RLD = 657, 
	RM = 658, 
	RMI = 659, 
	RMP = 660, 
	RMS = 661, 
	RMVB = 662, 
	RNC = 663, 
	ROA = 664, 
	ROFF = 665, 
	RP9 = 666, 
	RPSS = 667, 
	RPST = 668, 
	RQ = 669, 
	RS = 670, 
	RSD = 671, 
	RSS = 672, 
	RTF = 673, 
	RTX = 674, 
	S = 675, 
	S3M = 676, 
	SAF = 677, 
	SBML = 678, 
	SC = 679, 
	SCD = 680, 
	SCM = 681, 
	SCQ = 682, 
	SCS = 683, 
	SCURL = 684, 
	SDA = 685, 
	SDC = 686, 
	SDD = 687, 
	SDKD = 688, 
	SDKM = 689, 
	SDP = 690, 
	SDW = 691, 
	SEE = 692, 
	SEED = 693, 
	SEMA = 694, 
	SEMD = 695, 
	SEMF = 696, 
	SER = 697, 
	SETPAY = 698, 
	SETREG = 699, 
	SFS = 700, 
	SFV = 701, 
	SGI = 702, 
	SGL = 703, 
	SGM = 704, 
	SGML = 705, 
	SH = 706, 
	SHAR = 707, 
	SHF = 708, 
	SID = 709, 
	SIG = 710, 
	SIL = 711, 
	SILO = 712, 
	SIS = 713, 
	SISX = 714, 
	SIT = 715, 
	SITX = 716, 
	SKD = 717, 
	SKM = 718, 
	SKP = 719, 
	SKT = 720, 
	SLDM = 721, 
	SLDX = 722, 
	SLT = 723, 
	SM = 724, 
	SMF = 725, 
	SMI = 726, 
	SMIL = 727, 
	SMV = 728, 
	SMZIP = 729, 
	SND = 730, 
	SNF = 731, 
	SO = 732, 
	SPC = 733, 
	SPF = 734, 
	SPL = 735, 
	SPOT = 736, 
	SPP = 737, 
	SPQ = 738, 
	SPX = 739, 
	SQL = 740, 
	SRC = 741, 
	SRT = 742, 
	SRU = 743, 
	SRX = 744, 
	SSDL = 745, 
	SSE = 746, 
	SSF = 747, 
	SSML = 748, 
	ST = 749, 
	STC = 750, 
	STD = 751, 
	STF = 752, 
	STI = 753, 
	STK = 754, 
	STL = 755, 
	STR = 756, 
	STW = 757, 
	SUB = 758, 
	SUS = 759, 
	SUSP = 760, 
	SV4CPIO = 761, 
	SV4CRC = 762, 
	SVC = 763, 
	SVD = 764, 
	SVG = 765, 
	SVGZ = 766, 
	SWA = 767, 
	SWF = 768, 
	SWI = 769, 
	SXC = 770, 
	SXD = 771, 
	SXG = 772, 
	SXI = 773, 
	SXM = 774, 
	SXW = 775, 
	T = 776, 
	T3 = 777, 
	TAGLET = 778, 
	TAO = 779, 
	TAR = 780, 
	TCAP = 781, 
	TCL = 782, 
	TEACHER = 783, 
	TEI = 784, 
	TEICORPUS = 785, 
	TEX = 786, 
	TEXI = 787, 
	TEXINFO = 788, 
	TEXT = 789, 
	TFI = 790, 
	TFM = 791, 
	TGA = 792, 
	THMX = 793, 
	TIF = 794, 
	TIFF = 795, 
	TMO = 796, 
	TORRENT = 797, 
	TPL = 798, 
	TPT = 799, 
	TR = 800, 
	TRA = 801, 
	TRM = 802, 
	TSD = 803, 
	TSV = 804, 
	TTC = 805, 
	TTF = 806, 
	TTL = 807, 
	TWD = 808, 
	TWDS = 809, 
	TXD = 810, 
	TXF = 811, 
	TXT = 812, 
	U32 = 813, 
	UDEB = 814, 
	UFD = 815, 
	UFDL = 816, 
	ULX = 817, 
	UMJ = 818, 
	UNITYWEB = 819, 
	UOML = 820, 
	URI = 821, 
	URIS = 822, 
	URLS = 823, 
	USTAR = 824, 
	UTZ = 825, 
	UU = 826, 
	UVA = 827, 
	UVD = 828, 
	UVF = 829, 
	UVG = 830, 
	UVH = 831, 
	UVI = 832, 
	UVM = 833, 
	UVP = 834, 
	UVS = 835, 
	UVT = 836, 
	UVU = 837, 
	UVV = 838, 
	UVVA = 839, 
	UVVD = 840, 
	UVVF = 841, 
	UVVG = 842, 
	UVVH = 843, 
	UVVI = 844, 
	UVVM = 845, 
	UVVP = 846, 
	UVVS = 847, 
	UVVT = 848, 
	UVVU = 849, 
	UVVV = 850, 
	UVVX = 851, 
	UVVZ = 852, 
	UVX = 853, 
	UVZ = 854, 
	VCARD = 855, 
	VCD = 856, 
	VCF = 857, 
	VCG = 858, 
	VCS = 859, 
	VCX = 860, 
	VIS = 861, 
	VIV = 862, 
	VOB = 863, 
	VOR = 864, 
	VOX = 865, 
	VRML = 866, 
	VSD = 867, 
	VSF = 868, 
	VSS = 869, 
	VST = 870, 
	VSW = 871, 
	VTU = 872, 
	VXML = 873, 
	W3D = 874, 
	WAD = 875, 
	WAV = 876, 
	WAX = 877, 
	WBMP = 878, 
	WBMXL = 879, 
	WBS = 880, 
	WBXML = 881, 
	WCM = 882, 
	WDB = 883, 
	WDP = 884, 
	WEBA = 885, 
	WEBM = 886, 
	WEBP = 887, 
	WG = 888, 
	WGT = 889, 
	WKS = 890, 
	WM = 891, 
	WMA = 892, 
	WMD = 893, 
	WMF = 894, 
	WML = 895, 
	WMLC = 896, 
	WMLS = 897, 
	WMLSC = 898, 
	WMV = 899, 
	WMX = 900, 
	WMZ = 901, 
	WOFF = 902, 
	WPD = 903, 
	WPL = 904, 
	WPS = 905, 
	WQD = 906, 
	WRI = 907, 
	WRL = 908, 
	WSDL = 909, 
	WSPOLICY = 910, 
	WTB = 911, 
	WVX = 912, 
	X32 = 913, 
	X3D = 914, 
	X3DB = 915, 
	X3DBZ = 916, 
	X3DV = 917, 
	X3DVZ = 918, 
	X3DZ = 919, 
	XAML = 920, 
	XAP = 921, 
	XAR = 922, 
	XBAP = 923, 
	XBD = 924, 
	XBM = 925, 
	XDF = 926, 
	XDM = 927, 
	XDP = 928, 
	XDSSC = 929, 
	XDW = 930, 
	XENC = 931, 
	XER = 932, 
	XFDF = 933, 
	XFDL = 934, 
	XHT = 935, 
	XHTML = 936, 
	XHVML = 937, 
	XIF = 938, 
	XLA = 939, 
	XLAM = 940, 
	XLC = 941, 
	XLF = 942, 
	XLM = 943, 
	XLS = 944, 
	XLSB = 945, 
	XLSM = 946, 
	XLSX = 947, 
	XLT = 948, 
	XLTM = 949, 
	XLTX = 950, 
	XLW = 951, 
	XM = 952, 
	XML = 953, 
	XO = 954, 
	XOP = 955, 
	XPI = 956, 
	XPL = 957, 
	XPM = 958, 
	XPR = 959, 
	XPS = 960, 
	XPW = 961, 
	XPX = 962, 
	XSL = 963, 
	XSLT = 964, 
	XSM = 965, 
	XSPF = 966, 
	XUL = 967, 
	XVM = 968, 
	XVML = 969, 
	XWD = 970, 
	XYZ = 971, 
	XZ = 972, 
	YANG = 973, 
	YIN = 974, 
	Z1 = 975, 
	Z2 = 976, 
	Z3 = 977, 
	Z4 = 978, 
	Z5 = 979, 
	Z6 = 980, 
	Z7 = 981, 
	Z8 = 982, 
	ZAZ = 983, 
	ZIP = 984, 
	ZIR = 985, 
	ZIRZ = 986, 
	ZMM = 987, 
	DEFAULT = 988
}
export enum ContextCloak { 
	Default = 0, 
	User = 1, 
	Location = 2
}
export enum ContextPart { 
	Tenant = 1, 
	Location = 2, 
	LocationGroup = 3, 
	Contact = 4, 
	ContactGroup = 5, 
	UserInContact = 6, 
	SystemRole = 7, 
	LocationRelated = 8, 
	Permission = 9
}
export enum ControllerOperationId { 
	ContactControllerList = 1, 
	TicketControllerList = 2
}
export enum CustomPropertyType { 
	Base = 0, 
	Text = 1, 
	Multiline = 2, 
	Numeric = 3, 
	Email = 4, 
	Dropdown = 5, 
	Checkbox = 6, 
	Date = 7, 
	TimeOfDay = 8
}
export enum DashboardItemTypes { 
	Base = 0, 
	DataTable = 1
}
export enum DataTableColumnType { 
	Base = 0, 
	ByCustomProperty = 1, 
	ByTransfer = 2
}
export enum DataTableFilterType { 
	Base = 0, 
	ByCustomProperty = 1, 
	ByTransfer = 2
}
export enum DeviceActionTypes { 
	Logout = 1, 
	Ping = 2, 
	Restart = 3
}
export enum DeviceStatus { 
	Created = 0, 
	Registered = 1, 
	Deleted = 2
}
export enum DropDownStyles { 
	Default = 0, 
	ChooseList = 1
}
export enum ExecutionTypes { 
	Base = 0, 
	Manual = 1, 
	Planned = 2, 
	Triggered = 4
}
export enum ExternalApp { 
	SemcoOrgaManual = 1
}
export enum FieldRating { 
	Ok = 0, 
	Error = 1, 
	Tolerant = 2
}
export enum FilterOperations { 
	Include = 0, 
	Exclude = 1, 
	All = 2
}
export enum FilterOperators { 
	Equal = 0, 
	GreaterThan = 1, 
	LessThan = 2, 
	NotEqual = 3, 
	Contains = 4, 
	Default = -1
}
export enum FilterTypes { 
	DataTransferObject = 0, 
	Custom = 1
}
export enum FormFieldTypes { 
	Base = 0, 
	Info = 1, 
	Text = 2, 
	Multiline = 3, 
	Number = 4, 
	Date = 5, 
	Time = 6, 
	Picture = 7, 
	Signature = 8, 
	Checkbox = 9, 
	Dropdown = 10, 
	Attachment = 11
}
export enum FormRowTypes { 
	Base = 0, 
	Repeatable = 1, 
	Single = 2
}
export enum FormTypes { 
	Base = 0, 
	Task = 1, 
	Chat = 2
}
export enum GeneralTerms { 
	None = 0, 
	All = 1
}
export enum HistoryTypes { 
	Base = 0, 
	FormRow = 1, 
	Form = 2, 
	FormField = 3
}
export enum ImpactTypes { 
	Base = 0, 
	Sanction = 1, 
	Alert = 2, 
	Status = 3
}
export enum ImportRelationBehaviour { 
	Ignore = 0, 
	Update = 1, 
	Patch = 2
}
export enum ImportType { 
	User = 0
}
export enum LocationGroupType { 
	Base = 0, 
	Display = 1
}
export enum LoginChallengeTypes { 
	Base = 0, 
	DeviceCode = 1
}
export enum MessagePatternElementType { 
	Base = 0, 
	Constant = 1, 
	Field = 2
}
export enum MessageStatus { 
	Read = 0, 
	Send = 1
}
export enum NoteType { 
	Text = 0, 
	Mail = 1, 
	Caller = 2, 
	Action = 3, 
	ChangedStatus = 4, 
	EscalatedByEvent = 5, 
	EscalatedByUser = 6, 
	CanceledEscalation = 7, 
	Base = -1
}
export enum NotificationType { 
	Email = 0
}
export enum NotificationTypes { 
	TaskState = 1, 
	ChatMessage = 2, 
	DeviceAction = 3
}
export enum Orders { 
	Ascending = 0, 
	Descending = 1
}
export enum OrgaFolderItemType { 
	Document = 1, 
	Folder = 2
}
export enum OverviewTaskKind { 
	Task = 0, 
	Location = 1, 
	General = 2
}
export enum PadDirection { 
	Left = 0, 
	Right = 1
}
export enum Platforms { 
	iOS = 1, 
	Android = 2
}
export enum ProductEditions { 
	OrgaBusiness = 1, 
	OrgaEnterprise = 2, 
	OrgaPremium = 3, 
	OrgaCard = 4, 
	TechEnterprise = 11, 
	DataDefault = 20
}
export enum Products { 
	Base = 0, 
	Orga = 1, 
	Tech = 2, 
	Data = 4, 
	System = 256
}
export enum ProductSubscriptionTypes { 
	Base = 0, 
	Trial = 1, 
	Full = 2
}
export enum RecordStatus { 
	Untouched = 0, 
	New = 1, 
	Updated = 2, 
	Removed = 3, 
	Failed = 4
}
export enum RefreshTokenTypes { 
	Base = 0, 
	User = 1, 
	UserInLocation = 2, 
	Device = 3
}
export enum ReportLevel { 
	Info = 0, 
	Error = 1, 
	Warning = 2
}
export enum RoleGeneralTerms { 
	User = 0, 
	Admin = 1, 
	Manager = 2, 
	DeviceUser = 4, 
	SystemAdmin = 5, 
	Custom = 6, 
	Reseller = 7, 
	TechAdmin = 8, 
	OrgaDashboardUser = 9
}
export enum RoleKinds { 
	Tenant = 1, 
	Location = 2, 
	Contact = 4
}
export enum SettingType { 
	Base = 0, 
	General = 1
}
export enum SettingValueType { 
	Base = 0, 
	Text = 1, 
	HexColor = 2, 
	Picture = 3, 
	Culture = 4
}
export enum SqlDatePart { 
	Year = 1, 
	Quarter = 2, 
	Month = 3, 
	DayOfYear = 4, 
	Day = 5, 
	Week = 6, 
	WeekDay = 7, 
	Hour = 8, 
	Minute = 9, 
	Second = 10, 
	Millisecond = 11, 
	Microsecond = 12, 
	Nanosecond = 13, 
	TZoffset = 14, 
	ISO_WEEK = 15
}
export enum SystemDirections { 
	None = 0, 
	In = 1, 
	Out = 2
}
export enum TaskJobConditionType { 
	Base = 0, 
	Time = 1
}
export enum TaskJobState { 
	Pending = 1, 
	Start = 2, 
	Started = 3, 
	Done = 4, 
	Canceled = 5, 
	Overdue = 6, 
	Escalated = 7
}
export enum TaskJobStateTerm { 
	Undefined = 0, 
	Pending = 1, 
	Open = 2, 
	Closed = 4
}
export enum TaskJobStatusGenre { 
	System = 1, 
	Client = 2
}
export enum TaskState { 
	Inactive = 0, 
	False = 0, 
	Active = 1, 
	True = 1, 
	Archived = 2, 
	Deleted = 3
}
export enum TaskTypes { 
	Task = 0, 
	Confirmation = 1, 
	Document = 2, 
	Collection = 3
}
export enum TicketEventType { 
	Base = 0, 
	Escalation = 1
}
export enum TimeGraphKind { 
	Year = 1, 
	Month = 2, 
	Week = 3, 
	Day = 4
}
export enum TimePatterns { 
	None = 0, 
	Single = 1, 
	Daily = 2, 
	Weekly = 3, 
	MonthlyDay = 4, 
	MonthlyWeekDay = 5
}
export enum UserTypes { 
	Device = 1, 
	Api = 2, 
	Contact = 4
}
