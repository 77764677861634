import {Component, Input, OnInit} from '@angular/core';
import {AutocompleteBase} from '../../classes/autocomplete-base';
import {AutocompleteService} from '../../../services/autocomplete/autocomplete.service';
import {HttpRequestService} from '../../../services';
import {FilterDto} from '../../models/server/DataTransferObject/Filters';

const mapIds = {
  location: 'locationId',
  locationgroup: 'locationGroupId',
  contact: 'contactId',
  form: 'formId',
  contactgroup: 'contactGroupId',
  holidayset: 'holidaySetId',
  taskjobstatereasonset: 'taskJobStateReasonSetId',
};

const mapNames = {
  location: 'name',
  locationgroup: 'name',
  contact: 'displayName',
  contactgroup: 'name',
  holidayset: 'name',
  taskjobstatereasonset: 'title',
  form: 'title',
  task: 'title'
};

@Component({
  selector: 'app-autocomplete',
  templateUrl: './data-autocomplete.component.html',
  styleUrls: ['./data-autocomplete.component.scss'],
  providers: [
    AutocompleteService,
    {
      useClass: HttpRequestService,
      provide: HttpRequestService
    }]
})
export class DataAutocompleteComponent extends AutocompleteBase implements OnInit {

  timer: any;

  clicked: boolean;

  @Input() propertyFilterName = 'name';

  @Input() defaultFilter: FilterDto[];

  @Input() sort: string;

  @Input() placeholderLabel: string;

  @Input()
  set selectedItems(value) {
    if (value) {
      this.selected = value;
    }
  }

  mapIds = mapIds;

  mapNames = mapNames;

  constructor(protected autocompleteService: AutocompleteService) {
    super(autocompleteService);
  }

  async onSelectClick() {
    if (this.defaultFilter) {
      this._filter = this.defaultFilter;
    }

    if (!this.clicked || this._items.length === 0) {
      this._loading = true;
      this.clicked = true;

      if (this.initOnStart) {
        await this.getAll();
        this.filterItems(this._items, this.mapIds[this.types]);
        this._loading = false;
      }

      if (!this.selected && this._items && this._items.length > 0) {
        if (this.initOnStart && (this.selectFirst || this.initId )) {
          this.selected = this.initId || this._items[0][this.mapIds[this.types]];
        }
        this.typeaheadHandler(this._items, this.mapIds[this.types]);
      }

      if (this.selected && this.initId) {
        this.selected = this._items.find(el => el[this.mapIds[this.types]] === this.initId);
      }

    }
  }

  ngOnInit() {
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter(this.propertyFilterName, value);
      if (this.defaultFilter) {
        this._filter.push(...this.defaultFilter);
      }
    });
  }


  onChange(element) {
    this.changeValue.next(element);
  }

}
