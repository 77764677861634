import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteBase } from '../../classes/autocomplete-base';
import { Subject } from 'rxjs';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import {FilterOperators, FormTypes, TaskState, TaskTypes} from '../../models/server/Enums';
import {FilterDto} from '../../models/server/DataTransferObject/Filters';


@Component({
  selector: 'app-task-autocomplete',
  templateUrl: './task-autocomplete.component.html',
  styleUrls: ['./task-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class TaskAutocompleteComponent extends AutocompleteBase implements OnInit {

  @Input()
  set items(value) {
    this._items = [...this._items, ...value];
  }

  @Input() public initId: number;

  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();
  public selected: number;
  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(
    protected autocompleteService: AutocompleteService
  ) {
    super(autocompleteService);
    this.url = 'task';
  }

  async ngOnInit() {
    if (this.defaultFilter) {
      this._filter = [...this.defaultFilter];
    }
    this.typeaheadValue$.subscribe(value => {
      if (this.defaultFilter) {
        this._filter = [...this.defaultFilter];
      }
      this.buildFilter('title', value)
    });
    await this.getAll();
    if (!this.selected && this._items && this._items.length > 0) {
      this.selected = this.initId || this._items[0].taskId;
      const item = this._items.find(x => x.taskId == this.selected);
      this.changeValue.next(item);
    }
  }

  onChange(element) {
    this.changeValue.next(element);
  }

}
