import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http-request/http-request.service';
import { Ticket, Post } from '../../shared/models';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {map, tap} from 'rxjs/operators';
import {IFilterField, IOperation, IPageableReq, IPaging, ISortObject} from '../../shared/interfaces/http';
import {UtilsService} from "../../shared/utils/utils.service";
import {TicketDto} from '../../shared/models/server/DataTransferObject/Objects/Tickets';


@Injectable()
export class TicketService {

  apiTicketControllerPath = 'ticket';
  avialableFilter: Array<any> = [];
  tickets$: Subject<IPageableReq> = new Subject<IPageableReq>();
  ticket$: Subject<any> = new Subject<any>();
  constructor(private apiService: HttpRequestService,
                      private utilsService: UtilsService) { }

  subscription$() {
    return this.tickets$.asObservable();
  }

  subscriptionOne$() {
    return this.ticket$.asObservable();
  }

  filterByFields(filter: Array<IFilterField>, paging: IPaging, operation: IOperation['type'] = 'All', sortOptions: ISortObject = { sortField: null, sort: null } ) {
    const query = this.utilsService.queryBuilder([
      { key: 'operation', value: operation},
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
      { key: 'pageSize', value: paging.pageSize },
      { key: 'pageNumber', value: paging.pageNumber },
    ]);
    return this.apiService.post<any>([this.apiTicketControllerPath, 'filter', query].join('/'), filter)
      .pipe(map((response: HttpResponse<any>) => {
          return response.body; }))
      .subscribe(response => this.tickets$.next(response));
  }

  getTickets(params: HttpParams = null,  sortOptions: ISortObject = { sortField: null, sort: null }) {
    const query = this.utilsService.queryBuilder([
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
    ]);
      this.apiService.get<Post[]>([this.apiTicketControllerPath, query].join('/'), null, params)
        .pipe(map((response: HttpResponse<any>) => {
            this.avialableFilter = response.body.filters;
            return response.body; }))
        .subscribe(response => this.tickets$.next(response));
  }

  getTicketbyId(id: number) {
    return this.apiService.get<Ticket>([this.apiTicketControllerPath, id].join('/'))
      .pipe(tap(res => this.ticket$.next(res.body)),
        map(response => {
          return response.body;
        }));
  }

  postTicket(ticket: TicketDto) {
    return this.apiService.post(this.apiTicketControllerPath, ticket)
      .pipe(map((response: HttpResponse<any>) => {
        this.ticket$.next(response.body);
        return response.body
      }));
  }

  postCustomProperty(ticket: TicketDto) {
    return this.apiService.update([this.apiTicketControllerPath, ticket.ticketId].join('/'), ticket)
      .pipe(map((response: HttpResponse<any>) => {
        this.ticket$.next(response.body);
        return response.body;
      }));
  }

  putTicket(ticket: TicketDto) {
    return this.apiService.update([this.apiTicketControllerPath, ticket.ticketId].join('/'), ticket)
      .pipe(map((response: HttpResponse<any>) => {
        this.ticket$.next(response.body);
        return response.body
      }));
  }
}
