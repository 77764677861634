import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appNumberLimit]'
})
export class NumberLimitDirective  {
  @Input() ngModel: number;

  @Input() max: number;

  @Input() min: number;

  @Output() valueChange = new EventEmitter();

  temp: number;

  constructor(private el: ElementRef) {
  }

  @HostListener('ngModelChange')
  onChange() {
    const val = (this.el.nativeElement as HTMLInputElement).value;

    if (+val < this.min || +val > this.max) {
      this.ngModel = this.temp;
      (this.el.nativeElement as HTMLInputElement).value = this.temp + '';
      return;
    }
    this.valueChange.emit(val);
    this.ngModel = +val;
    this.temp = +val;
  }
}
