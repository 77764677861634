import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {ResourceItem, Translation} from '../../../shared/models';

export class ApiTranslateHttpLoader extends TranslateHttpLoader {
  getTranslation(lang: string) : any {
    return super.getTranslation(lang).map((trans: ResourceItem[]) => {
      let dic = [];
      for (let i = 0; i < trans.length; i++) {
        dic[trans[i].key] = trans[i].value;
      }
      const addedKey = {
        Menu_Settings: 'Settings',
        Menu_Knowledge: 'Knowledge',
        Company_Contacts: 'Company contacts',
        Address_Title: 'Addresses',
        Address_Edit: 'Edit exist address',
        Table_emailAddress: 'Email address',
        Table_Action: 'Action',
        Company_Detail: 'Company detail',
        Company_Info: 'Company info',
        Company_Description: 'Select company for view/edit',
        Form_Address: 'Address',
        Form_Contact: 'Contact',
        Form_Contact_Group: 'Contact Group',
        Form_Location: 'Location',
        Form_Company: 'Company',

        Table_appRoleId: 'Role Id',
        Table_tenantId: 'Tenant Id',
        Table_concurrencyStamp: 'Concurrency Stamp',
        Table_isSystemRole: 'Role type',
        Table_displayName: 'Display name',
        Table_azureGroupName: 'Azure GroupName',
        Table_loginProviderRoleId: 'LoginProvider RoleId',
        Table_roleId: 'RoleId',
        Table_role: 'Role',
        Table_loginProviderId: 'LoginProvider Id',
        Table_provider: 'Provider',
        Table_entityCustomValues: 'Entity Custom Values',
        Table_password: 'Password',
        Table_permissions: 'Permissions',
        Table_defaultRoleId: 'Default Role Id',
        Table_defaultRole: 'Default Role',
        Table_azureTenantId: 'Azure Tenant Id',
        Stereotype_Settings: 'Stereotype settings',
        Location_Info: 'Location detail',
        Contact_Info: 'Contact detail',
        Roles_Title: 'Roles',
        Permission_Title: 'Permissions',
        Table_Filter_Clear: 'Remove',
        Table_Filter_Apply: 'Apply ',
        Form_Role: 'Form_Role',
        Role_Create: 'Create new role',
        Role_Edit: 'Edit exist role',
        ContactGroup_Add: 'Overview Contact',
        Contact_Role_List: 'Overview Roles List',
        Contact_Detail: 'Contact Detail',
        Form_DisplayName: 'Display name',

        LoginProvider_Group: 'Group Assigment',
        LoginProviders: 'Login Providers',
        LoginProvider_List: 'LoginProviders list',
        LoginProvider_Create: 'Create provider',
        LoginProvider_Form_CreateGroup: 'New Group Assigment',
        LoginProvider_Form_EditGroup: 'Edit exist group assigment',
        LoginProvider_Form_Role: 'Role',
        LoginProvider_Detail: 'Basic',
        LoginProvider_Groups: 'Groups',

        Stereotype: 'Stereotype',
        Stereotypes_List: 'Stereotypes List',
        Stereotype_Setting_Title: 'Stereotypes settings',
        Stereotypes_Title: 'Stereotypes',
        Stereotypes_Create: 'Create new stereotype',
        Stereotypes_Edit: 'Edit stereotype',
        Stereotypes_Panel: 'Stereotype settings',

        Setting_TItle: 'Settings',
        CustomProperty_Create: 'Create custom property',
        Resource_Create: 'Create new resource',
        Resource_List: 'List of resources',
        ResourceGroup_Create: 'Create new resource group',
        ResourceGroup_List: 'List of resource group',

        Form_azureTenantId: 'Azure TenantId',
        Form_defaultRole: 'Default Role',
        Form_name: 'Name',
        Table_roleName: 'Role',
        Form_roleId: 'Role',
        Form_azureGroupName: 'Group name',
        Form_defaultRoleId: 'Default RoleId',
        Form_loginProviderId: 'Login ProviderId',
        Form_type: 'Type',
        Form_defaultValue: 'Default value',
        Form_defaultValues: 'Default values',
        Form_maxValue: 'Max Value',
        Form_minValue: 'Min Value',
        Form_maxLength: 'Max Length',
        Form_minLength: 'Min Length',
        Form_numericType: 'Numeric property',
        Form_textType: 'Text property',

        Form_provider: 'Provider',
        Form_tenantId: 'TenantId',
        Form_SaveProperties: 'Save properties',

        TableConfig_tickets: 'Configure ticket table',
        TableConfig_addresses: 'Configure addresses table',
        TableConfig_contacts: 'Configure contacts table',
        TableConfig_contactgroups: 'Configure contact groups table',
        TableConfig_loginproviders: 'Configure login providers table',
        TableConfig_locations: 'Configure locations table',
        TableConfig_roles: 'Configure roles table',

        ValidationError_FieldMinValue: 'Please enter more value, min: ',
        ValidationError_FieldMaxValue: 'Please enter less value, max: ',
        ValidationError_FieldMinLength: 'Please enter more character, min: ',
        ValidationError_FieldMaxLength: 'Please enter less character, max: ',
        ValidationError_FieldEmail: 'Please enter valid email',
        ModelError_maxLength: 'max length incorrect',
        ModelError_minLength: 'min length incorrect',
        MinLengthMustBeLowerThenMaxLength_MaxLength: 'The min length must be lower then the max length',
        MinValueMustBeLowerThenMaxValue_MaxValue: 'The min value must be lower then the max value',
        GivenTextIsToShort_Value: 'Given text is to short',
        ValueCannotBeNull_Value: 'Value cannot be null',

        LocationGroup_Title: 'Location groups',

        NotesForm_Description: 'Description',
        NotesForm_Text: 'Text',
        NotesForm_Type: 'Type',
        NotesForm_Create: 'Create Note',
        NotesForm_Incoming: 'Incoming',
        NotesForm_Outcoming: 'Outcoming',
        NotesForm_Direction: 'Direction',
        NotesForm_Phone: 'Phone number',
      };
      const tempDir = {...dic , ...addedKey};
      // dic = [ ...dic, ...addedKey];
       return { ...tempDir };
       // return { ...dic };
    });
  }
}
