import { NgModule } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { AuthConfig, AuthHttp } from 'angular2-jwt';
import { SharedModule } from '../shared/shared.module';
import { AuthorizationService } from './auth/authorization.service';
import { ErrorHandlingService } from './error-handling/error-handling.service';
import { HttpRequestDataService } from './http-request-data/http-request-data.service';
import { HttpRequestLoginService } from './http-request-login/http-request-login.service';
import { HttpRequestService } from './http-request/http-request.service';
import { LocalStorageService } from './local-storage/local-storage.service';
import { TicketService } from './ticket/ticket.service';


export function authHttpServiceFactory(http: Http, options: RequestOptions) {
  return new AuthHttp(new AuthConfig(), http, options);
}

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    HttpRequestService,
    HttpRequestLoginService,
    HttpRequestDataService,
    LocalStorageService,
    ErrorHandlingService,
    {
      provide: AuthHttp,
      useFactory: authHttpServiceFactory,
      deps: [Http, RequestOptions]
    },
    AuthorizationService,
    TicketService
  ]
})
export class ServicesModule { }
