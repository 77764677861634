import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '../auth/authorization.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { CustomPropertySetDto, CustomPropertyDto } from '../../shared/models/server/DataTransferObject/Objects/Stereotyping/CustomProperties';
import { AppEntityType } from '../../shared/models/server/Enums';
import { StereotypeDto } from '../../shared/models/server/DataTransferObject/Objects/Stereotyping/Stereotypes';


@Injectable()
export class StereotypeService {

  apiStereotypesControllerPath = 'stereotype';

  stereotypes: Array<any> = [];

  constructor(
    private apiService: HttpRequestService,
    private authservice: AuthorizationService
  ) {
  }

  getStereotype(id: string) {
    return this.apiService.get<StereotypeDto>([this.apiStereotypesControllerPath, id].join('/'))
      .pipe(map((response: HttpResponse<StereotypeDto>) => response.body));
  }

  postCustomPropertySet(body: any) {
    body.tenantId = this.authservice.selectedTenant.tenantId;
    return this.apiService.post<CustomPropertySetDto>('custompropertyset', body)
      .pipe(map((response: HttpResponse<CustomPropertySetDto>) => response.body));
  }

  putCustomPropertySet(body: CustomPropertySetDto) {
    body.tenantId = this.authservice.selectedTenant.tenantId;
    return this.apiService.update<CustomPropertySetDto>(['custompropertyset', body.customPropertySetId].join('/'), body)
      .pipe(map((response: HttpResponse<CustomPropertySetDto>) => response.body));
  }

  getCustomPropertySet(id: number) {
    return this.apiService.get<any>(['custompropertyset', id].join('/'));
  }

  deleteCustomPropertySet(id: number) {
    return this.apiService.delete<CustomPropertySetDto>('custompropertyset', id + '')
      .pipe(map((response: HttpResponse<CustomPropertySetDto>) => response.body));
  }

  putCustomProperty(body: CustomPropertyDto) {
    return this.apiService.update<CustomPropertyDto>(['customproperty', body.customPropertyId].join('/'), body)
      .pipe(map((response: HttpResponse<CustomPropertyDto>) => response.body));
  }

  removeCustomProperty(id: number) {
    return this.apiService.delete<CustomPropertySetDto>('customproperty', id + '')
      .pipe(map((response: HttpResponse<CustomPropertySetDto>) => response.body));
  }

  postCustomProperty(body: any) {
    body.properties.tenantId = this.authservice.selectedTenant.tenantId;
    return this.apiService.post<CustomPropertySetDto>('customproperty', body)
      .pipe(map((response: HttpResponse<CustomPropertySetDto>) => response.body));
  }

  getStereotypeSets(type: string) {
    return this.apiService.get<StereotypeDto>([this.apiStereotypesControllerPath, type].join('/'))
      .pipe(map((response: HttpResponse<StereotypeDto>) => {
        return response.body;
      }));
  }

  getStereotypesList() {
    return this.apiService.get<AppEntityType>(this.apiStereotypesControllerPath)
      .pipe(map((response: HttpResponse<AppEntityType>) => response.body));
  }

  fetchStereotypes() {
    return of([]);
  }

  getCustomPropertyByIds(ids: Array<number>) {
    const arr = [];
    if (ids) {
      ids.forEach(num => arr.push(this.apiService.get(['customproperty', num].join('/'))
        .pipe(map(res => res.body))));
    }
    return forkJoin(arr);
  }

  getStereotypesListByEntityType(type: string) {
    return this.apiService.get<StereotypeDto>(this.apiStereotypesControllerPath)
      .pipe(map((response: HttpResponse<StereotypeDto>) => (response.body as any).filter(el => el.entityType.toLowerCase() === type.toLowerCase())),
        map(body => body[0].stereotypes));
  }
}
