import {Component, Input, OnInit, Output} from '@angular/core';
import {DataDetailBase} from '../../../shared/classes/data-detail-base';
import {ICreateComponent} from '../../../shared/interfaces/ui';
import {DatatableService} from '../../../services/datatable/datatable.service';
import {AuthorizationService} from '../../../services';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertBlockService} from '../../../core/services/alert-block/alert-block.service';
import {BreadcrumbsService} from '../../../core/services/breadcrumbs/breadcrumbs.service';
import {ToasterService} from 'angular2-toaster';
import {ActionBarService} from '../../../core/services/action-bar/action-bar.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-folder-create',
  templateUrl: './folder-create.component.html',
  styleUrls: ['./folder-create.component.scss']
})
export class FolderCreateComponent extends DataDetailBase {

  @Input() formControlObj: FormControl;

  isCreateMode: boolean;

  isLoading: boolean;

  constructor(protected datatableService: DatatableService,
              protected toasterService: ToasterService,
              protected breadcrumbsService: BreadcrumbsService,
              protected _fb: FormBuilder,
              protected translate: TranslateService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected actionBarService: ActionBarService,
              protected authService: AuthorizationService,
              protected alertBlockService: AlertBlockService) {
    super(datatableService, toasterService, breadcrumbsService, _fb, translate, router, route, actionBarService, authService, alertBlockService);

    this.config = {
      resourceIdName: '',
      resource: '',
      title: 'TITLE.addFolder',
      editMode: false
    };

  }

  editMode() {
    this.config.editMode = true;
    this.config.viewMode = false;
    this.isCreateMode = true;
  }

  viewMode() {
    this.config.editMode = false;
    this.config.viewMode = true;
    this.isCreateMode = true;
  }

  hide() {
    this.formControlObj.setValue('');
    this.config.editMode = false;
    this.config.viewMode = false;
    this.isCreateMode = false;
  }

}
