import { HttpBackend, HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { HttpRequestService } from '..';

@Injectable()
export class HttpRequestDataService extends HttpRequestService {

  constructor(protected httpClient: HttpClient, protected httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  public get<T>(
    path: string,
    headers: HttpHeaders = null,
    params: HttpParams = null,
    authorized: boolean = true,
    apiPath: string = null): Observable<HttpResponse<T>> {
    return this.getHttpClient(authorized)
      .get<T>([environment.apiUrl.data, path].join('/'), { headers: headers, observe: 'response', params: params });
  }

  public post<T>(
    path: string,
    body: any,
    headers: HttpHeaders = null,
    authorized: boolean = true,
    apiPath: string = null, params: HttpParams = null): Observable<HttpResponse<T>> {
    const postUrl = [environment.apiUrl.data, path].join('/');
    return this.getHttpClient(authorized)
      .post<T>(postUrl, body, { headers: headers, observe: 'response', params: params });
  }

  public delete<T>(
    path: string,
    id: string,
    headers: HttpHeaders = null,
    authorized: boolean = true,
    apiPath: string = null): Observable<HttpResponse<T>> {
    return this.getHttpClient(authorized)
      .delete<T>([environment.apiUrl.data, path, id].join('/'), { headers: headers, observe: 'response' });
  }

  public update<T>(
    path: string,
    object: T,
    headers: HttpHeaders = null,
    authorized: boolean = true,
    apiPath: string = null): Observable<HttpResponse<T>> {
    return this.getHttpClient(authorized)
      .put<T>([environment.apiUrl.data, path].join('/'), object, { headers: headers, observe: 'response' });
  }

  protected getHttpClient(authorized: boolean = true) {
    if (authorized === true) {
      return this.httpClient;
    } else {
      return new HttpClient(this.httpBackend);
    }
  }
}
