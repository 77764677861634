import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appClicked]'
})
export class ClickedDirective {
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('blur') onMouseBlur() {
    this.clicked.next(true);
  }

  constructor(el: ElementRef) {
  }

}
