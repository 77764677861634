import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AfterContentInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ToasterService } from 'angular2-toaster';
import { isArray } from 'util';
import { ActionBarService } from '../../core/services/action-bar/action-bar.service';
import { AlertBlockService } from '../../core/services/alert-block/alert-block.service';
import { DatatableService } from '../../services/datatable/datatable.service';
import { StereotypeService } from '../../services/stereotype/stereotype.service';
import { AlertService } from '../../services/ui/alerts/alert.service';
import { TableConfig } from '../constants/table-config';
import { IFilterField, IPageableReq, IPaging, ISortObject } from '../interfaces/http';
import { INgxPagination, ITableConfig } from '../interfaces/ui';
import { UtilsService } from '../utils/utils.service';
import * as Attr from './../../shared/models/server/Attributes';

export class DatatableBase implements AfterContentInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() dataId: any;
  @Input() config: ITableConfig = {
    title: '',
    resource: 'role',
    resourceIdName: '',
    toggleMode: false,
    operation: 'All',
    pageSize: TableConfig.pageSize,
    excludedColumns: {
      stereotype: true,
      stereotypeId: true,
      entityCustomValues: true,
      rowVersion: true,
      isInRelation: true,
      answerEnabled: true
    },
    buttons: {
      edit: true,
      delete: true,
      view: true
    }
  };

  trackById(index, item) {
    return item[this.config.resourceIdName];
  }

  @Output() selectResourceEmitter: EventEmitter<any> = new EventEmitter<any>();
  protected excludedColumns = {
    stereotype: true,
    stereotypeId: true,
    entityCustomValues: true,
    rowVersion: true,
    isInRelation: true,
    answerEnabled: true,
  };
  protected colsArray: Array<{ key: string; value: string }> = [];
  protected tableObject: any;
  protected temp = [];
  protected paging: IPaging = { pageSize: TableConfig.pageSize, pageNumber: 1 };
  protected colsFiltered: any;

  public rows = [];
  public stereotypes: Array<any> = [];
  public lastAction: any;
  public errorDescriptions: string[] = [];
  public typeString: string;
  public currentParams: HttpParams = null;
  public currentSortOption: ISortObject = { sortField: null, sort: null };
  public currentFilter: Array<IFilterField> = null;
  public checkedFilter = false;
  public dateColumns;

  private _entityProperty: Set<string> = new Set();
  private _timer: any;
  private _entityPropertyArray: Array<string> = [];
  private _idsCustomPropertyMap = {};
  public columns: Array<{ name: string, notTranslate?: boolean }> = [];

  protected filterList: Array<IFilterField> = [];
  protected filterArray: Array<any> = [];
  protected animationActionArray: Array<any> = [];
  protected originalColumns: Array<{ name: string, notTranslate?: boolean }> = [];
  protected timer: any;

  public isLoading = true;
  public pageable: IPaging = {
    pageNumber: 0,
    pageSize: TableConfig.pageSize,
    totalPages: 0,
    totalItems: 0
  };

  matchCase: object = {
    address: 'addresses_stereotype',
    contact: 'contacts_stereotype',
    ticket: 'tickets_stereotype',
    contactgroup: 'contactgroups_stereotype',
    location: 'locations_stereotype'
    // ...
  };

  constructor(protected utilsService: UtilsService,
              protected router: Router,
              protected datatableService: DatatableService,
              protected toasterService: ToasterService,
              protected stereotypeService: StereotypeService,
              protected ts: TranslateService,
              protected alertService: AlertService,
              protected actionbarService: ActionBarService,
              protected alertBlockService: AlertBlockService,
              protected defaultFilter: Array<IFilterField> = [],
              protected apiPath: string = null) {
    this.datatableService.setApiPath(this.apiPath);

    if (this.defaultFilter && this.defaultFilter.length > 0) {
      this.currentFilter = [ ...this.defaultFilter ];
    }
  }

  buildColsArray(key: string = 'cols', strArray: Array<string | number>) {
    return strArray.map(el => ({ key: key, value: capitalizeFirstLetter(el) }));

    function capitalizeFirstLetter(string) {
      if (Number.isInteger(string)) {
        return string;
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  }

  isOriginalColumn(name: string) {
    return !!this.originalColumns.find(el => el.name === name);
  }

  getTranslateRow(row: any, column: any) {
    const translation = this.config.translationPrefix;

    for (const obj in translation) {
      if (translation.hasOwnProperty(obj) && column.name === obj) {
        return [translation[obj], this.getValueFromEnum(obj, row[obj])].join('.');
      }
    }

    return '';
  }

  private getValueFromEnum(type: string, value: any) {
    if (typeof value === 'boolean') {
      type = 'boolean';
    }

    switch (type) {
      case 'boolean':
        return `${value}`;
      default:
        return `${value}`;
    }
  }

  protected createColumns(tableObject: any = this.tableObject) {
    const self = this;
    if (tableObject && !(!!this.filterList.length)) {
      const cols = Attr.getColumns(this.tableObject);
      const filterCols = Attr.getFilterableColumns(this.tableObject);
      this.dateColumns = Attr.getDateColumns(this.tableObject);

      this.filterList = this.buildFilter(filterCols);
      this.filterList = [...this.filterList, ...this._entityPropertyArray.map(item => ({ propertyName: item }))];
      this.originalColumns = buildCols(cols);
      this.columns = buildCols(cols);
      this._buildEntityColumn();
    }

    function buildCols(cols: object): Array<{ name: string }> {
      const colsArray = [];

      if (self.config.columns) {
        self.config.columns
          .filter(el => !self.config.excludedColumns.hasOwnProperty(el))
          .forEach(col => {
            colsArray.push({ name: col });
        });
        return colsArray;
      }

      for (const obj in cols) {
        if (cols.hasOwnProperty(obj) && !self.config.excludedColumns.hasOwnProperty(obj)) {
          colsArray.push({ name: obj });
        }
      }
      return colsArray;
    }
  }

  private buildFilter(array: object): Array<{ propertyName: string }> {
    const arr = [];
    for (const obj in array) {
      if (array.hasOwnProperty(obj)) {
        arr.push({ propertyName: obj });
      }
    }
    return arr;
  }

  protected _buildEntityColumn() {
    const extColumns = [...this.originalColumns, ...this.stereotypes.map(el => ({ name: el.name.replace(/\s/g, '_'), notTranslate: true }))];
    if (this.columns.length < extColumns.length) {
      this.columns = extColumns;
    }
    this.rows = [...this.rows.map(el => {
      const stereotypesElements = {};
      if (el.entityCustomValues) {
        el.entityCustomValues.forEach(value => {
          const type = this.stereotypes.find(stereotype => stereotype.customPropertyId === value.propertyId);
          const name = type && type.name || '';
          stereotypesElements[name.replace(/\s/g, '_')] = value.customValues[0].customValue.value;
          this._idsCustomPropertyMap[name.replace(/\s/g, '_')] = value.propertyId;
        });
      }
      this._entityPropertyArray = this._buildCustomPropertyField(stereotypesElements) || [];
      this.filterList = [...this.filterList, ...this._entityPropertyArray.map(item => ({ propertyName: item }))];
      return { ...el, ...stereotypesElements };
    })];
  }

  protected _clone(array: Array<any>) {
    return JSON.parse(JSON.stringify(array));
  }

  protected _getDatatable(params: HttpParams = this.currentParams, sortOptions: ISortObject = this.currentSortOption, filter: boolean = true) {
    this.isLoading = true;

    const request = () => {
      if (!this.dataId) {
        this.datatableService.get(this._getRequestControllerPath(),
          params,
          sortOptions,
          this.colsArray,
          this.config.pageSize,
          this.config.resourceIdName);
      } else {
        this.datatableService.getByParentResource(this.config.parentResource,
          this.dataId,
          params,
          this._getRequestControllerPath(),
          this.config.operation,
          this.currentSortOption,
          this.config.pageSize,
          this.config.resourceIdName);
      }
    };
    const filterRequest = () => {
      if (!this.dataId) {
        this.datatableService.getFilterByFields(this._getRequestControllerPath(),
          this.currentFilter,
          this.paging,
          this.config.operation,
          this.currentSortOption,
          this.colsArray,
          this.config.pageSize,
          this.config.resourceIdName);
      } else {
        this.datatableService.getFilterByFieldsByParentResource(this.config.parentResource,
          this.dataId,
          this._getRequestControllerPath(),
          this.currentFilter,
          this.paging,
          this.config.operation,
          this.currentSortOption,
          this.colsArray,
          this.config.pageSize,
          this.config.resourceIdName);
      }
    };
    if (!this.currentFilter || !filter) {
      request();
    } else {
      filterRequest();
    }

    this.lastAction = this._getDatatable.bind(this);

  }

  protected _getRequestControllerPath(): string {
    return this.config.resource;
  }

  protected async _errorHandler(httpError: HttpErrorResponse) {
    if (httpError.error && httpError.error.Errors) {
      this.errorDescriptions = (<{
        Description: string;
      }[]>httpError.error.Errors)
        .filter(x => x && x.Description && x.Description.length > 0)
        .map(x => x.Description);
    }
    if (httpError.error && isArray(httpError.error)) {
      this.errorDescriptions = (<{
        Description: string;
      }[]>httpError.error)
        .filter(x => x && x.Description && x.Description.length > 0)
        .map(x => x.Description);
    }

    if (httpError.error && httpError.error.error) {
      this.errorDescriptions = [...this.errorDescriptions, httpError.error.error];
    }


    if (httpError.message && (!this.errorDescriptions || this.errorDescriptions.length <= 0)) {
      this.errorDescriptions = [httpError.message];
    }

    if (httpError.status === 403) {
      this.errorDescriptions = [ await this.ts.get('ERROR.HTTP.403').toPromise() ];
    }

    if (httpError.status === 404) {
      this.errorDescriptions = [ await this.ts.get('ERROR.HTTP.404').toPromise() ];
    }

    if (httpError.status === 500) {
      this.errorDescriptions = [ await this.ts.get('ERROR.HTTP.500').toPromise() ];
    }

    this.isLoading = false;
    this.alertBlockService.setAlert({ error: this.errorDescriptions.join(''), callback: this.lastAction });
  }

  protected _responseHandling(response: IPageableReq, resourceIdName: string = '') {
    this.isLoading = false;
    this.pageable = response.paging;
    response.items = this.filterResponse(response.items);
    if (!this.filterList.length || !this.rows.length) {
      this.createColumns();
    }
    if (!this.columns.length) {
      if (this.tableObject) {
        this.createColumns();
      } else {
        this.columns = this.utilsService.createColums(response.items);
      }
    }

    response.items.forEach(el => {
      this.setAnimationArray(el[resourceIdName]);
    });

    this.rows = this._clone(response.items);
    this.temp = this._clone(response.items);
    this._buildEntityColumn();
    this.afterResponse();
    this.alertBlockService.clearAlerts();
    this.pageable.pageNumber--;
  }

  protected filterResponse(items: Array<any>) {
    return items;
  }

  protected setAnimationArray(id: any) {
    this.animationActionArray[id] = {
      isLoading: false,
      isDeleting: false,
      isAdding: false,
      isDownloading: false,
      isRestarting: false,
      isEditing: false
    };
  }

  protected afterResponse() {

  }

  protected _buildCustomPropertyField(stereotypesElements: object): Array<string> {
    for (const obj in stereotypesElements) {
      if (stereotypesElements.hasOwnProperty(obj) && obj.length) {
        this._entityProperty.add(obj);
      }
    }
    return Array.from(this._entityProperty);
  }

  public getDirectionClass(dir: string) {
    if (dir) {
      return `fa fa-sort-${dir} text-success`;
    } else {
      return 'fa fa-sort';
    }
  }

  public hasFilter(field: string, filterList: Array<IFilterField> = []) {
    if (this.filterList) {
      return this.filterList.find(el => el.propertyName.toLowerCase() === field.toLowerCase());
    }
  }

  public hasRelation(status: boolean) {
    if (this.config.operation === 'All') {
      return status;
    } else {
      return true;
    }
  }

  public rebuildTable(e: INgxPagination) {
    this.isLoading = true;

    this.currentParams = (this.currentParams || (new HttpParams()))
      .set('pageSize', e.pageSize.toString())
      .set('pageNumber', (e.offset + 1).toString());

    this.paging = { pageSize: e.pageSize, pageNumber: e.offset + 1 };
    this._getDatatable(this.currentParams, this.currentSortOption);
  }

  public getSearchType(name: string) {
    const formatedName = name.toLowerCase();

    if (formatedName.match(/(email|description|phone|name|mobile|title|tags|table|contact|pin)/)) { return 'text'; }
    if (this.dateColumns[name]) { return 'date'; }
    if (formatedName.match(/(isenabled|can|isinherited|answerenabled|requestread)/)) { return 'boolean'; }

    return 'number';
  }

  public buildSortFilter(column, event) {
    const req = [ ...this.defaultFilter ];
    const col = this._entityPropertyArray.includes(column) ? this._idsCustomPropertyMap[column] : column;

    this.filterArray[column] = this.utilsService
      .buildFilterBody(col, event, this._entityPropertyArray.includes(column));

    if (!this.filterArray[column]) {
      delete this.filterArray[column];
    }
    for (const obj in this.filterArray) {
      if (obj) {
        req.push(this.filterArray[obj]);
      }
    }
    this.currentFilter = req;
  }

  public clearFilter() {
    this.currentFilter = [ ...this.defaultFilter ];
    this.isLoading = true;
    const elements = this.table.element.querySelectorAll('input');
    for (const obj in elements) {
      if (elements.hasOwnProperty(obj)) {
        elements[obj].value = '';
      }
    }

    this._getDatatable();
  }

  onSort(event) {
    this.isLoading = true;
    const direction = event.sorts[0].dir === 'asc' ? 'Ascending' : 'Descending';
    this.currentSortOption = { sortField: event.sorts[0].prop, sort: direction };
    this._getDatatable(this.currentParams, this.currentSortOption);
  }

  async delete(resourceData: any, path: string = this.config.resource) {
    event.stopPropagation();

    if (await this.alertService.confirmDelete() == false)
      return;

    this.animationActionArray[resourceData[this.config.resourceIdName]].isDeleting = true;
    this.datatableService.delete(path, resourceData[this.config.resourceIdName] + '')
      .subscribe(response => {
        this.showToaster('success', 'SUCCESS.deleted');
        this.animationActionArray[resourceData[this.config.resourceIdName]].isDeleting = false;
        this.removeFromList(this.config.resourceIdName, resourceData);
      }, error => {
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          this.actionbarService.addHttpErrors(error);
        }
        this.showToaster('error', 'ERROR.notDeleted');
        this.animationActionArray[resourceData[this.config.resourceIdName]].isDeleting = false;
      });
  }

  removeFromList(fieldName: string, resourceData: any) {
    event.stopPropagation();
    this.temp = this.temp.filter(item => item[fieldName] !== resourceData[fieldName]);
    this.rows = this.rows.filter(item => item[fieldName] !== resourceData[fieldName]);
    this.pageable.totalItems--;
  }

  showToaster(type: string, msg: string, title: string = '') {
    this.ts.get(msg).subscribe(response => {
      this.toasterService.pop(type, title, response);
    });
  }

  searchBy(column: string = null, event: any = null) {
    this.paging.pageNumber = 0;
    clearTimeout(this.timer);
    this.isLoading = true;
    this.checkedFilter = true;
    this.timer = setTimeout(() => {
      if (column) {
        this.buildSortFilter(column, event);
      }
      this._getDatatable(this.currentParams, this.currentSortOption);
    }, 400);
  }

  addResourceToParent<T>(resourceData: T & { isInRelation: boolean }, parentId: string) {
    event.stopPropagation();
    this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = true;
    if (this.config.operation === 'All') {
      this.datatableService.addToParent(this.config.resource, resourceData[this.config.resourceIdName], this.config.parentResource, parentId)
        .subscribe(response => {
          this.showToaster('success', 'SUCCESS.added');
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
          resourceData.isInRelation = true;
          // this.datatableService.putToLocal(resourceData, this.config.resourceIdName);
        }, err => {
          this.showToaster('error', 'ERROR.notAdded');
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
        });
    } else {
      this.datatableService.addToParent(this.config.resource, resourceData[this.config.resourceIdName], this.config.parentResource, parentId)
        .subscribe(response => {
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
          this.removeFromList(this.config.resourceIdName, resourceData);
          this.showToaster('success', 'SUCCESS.added');
        }, err => {
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
          this.showToaster('error', 'ERROR.notAdded');
        });
    }
  }

  removeResourceFromParent<T>(resourceData: T & { isInRelation: boolean; }, parentId: string) {
    event.stopPropagation();
    this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = true;
    if (this.config.operation === 'All') {
      this.datatableService.removeFromParent(this.config.resource, resourceData[this.config.resourceIdName], this.config.parentResource, parentId)
        .subscribe(response => {
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
          resourceData.isInRelation = false;
          // this.datatableService.putToLocal(resourceData, this.config.resourceIdName);
          this.showToaster('success', 'SUCCESS.removed');
        }, err => {
          this.showToaster('error', 'ERROR.notRemoved');
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
        });
    } else {
      this.datatableService.removeFromParent(this.config.resource, resourceData[this.config.resourceIdName], this.config.parentResource, parentId)
        .subscribe(response => {
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
          this.removeFromList(this.config.resourceIdName, resourceData);
          this.showToaster('success', 'SUCCESS.removed');
        }, err => {
          this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
          this.showToaster('error', 'ERROR.notRemoved');
        });
    }
  }

  ngAfterContentInit() {
    this.datatableService.subscription(this.config.resourceIdName)
      .subscribe(res => {
        this._responseHandling(res, this.config.resourceIdName);
      });

    this.datatableService.errorSubscription(this.config.resourceIdName)
      .subscribe(err => {
        this._errorHandler(err);
      });

    const type = this.router.url.match('\\/\\w+$') && this.router.url.match('\\/\\w+$')[0].replace('/', '');
    let storageCols = [], storageCustomCols = [];
    if (type) {
      storageCols = JSON.parse(localStorage.getItem(type)) || [];
      storageCustomCols = JSON.parse(localStorage.getItem(type + '_stereotype')) || [];
      this.colsArray = [];
    }
    if (storageCols.length) {
      this.colsArray = this.buildColsArray('cols', storageCols);
    }
    if (storageCustomCols.length) {
      const cols = this.buildColsArray('customCols', storageCustomCols);
      this.colsArray = [...this.colsArray, ...cols];
    }
    if (!this.config.notRefreshAfterInit) {
      this._getDatatable(this.currentParams, this.currentSortOption);
    }
  }
}
