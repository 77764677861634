import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { BreadcrumbsService } from '../../core/services/breadcrumbs/breadcrumbs.service';
import { DatatableService } from '../../services/datatable/datatable.service';
import { StereotypeService } from '../../services/stereotype/stereotype.service';
import { DatatableBase } from '../../shared/classes/datatable-base';
import { ISortObject, IFilterField } from '../../shared/interfaces/http';
import { UtilsService } from '../../shared/utils/utils.service';
import { AlertService } from '../../services/ui/alerts/alert.service';
import { ActionBarService } from '../../core/services/action-bar/action-bar.service';
import {AlertBlockService} from '../../core/services/alert-block/alert-block.service';

export class MasterDataTable extends DatatableBase implements AfterContentInit {

  @Output() selectResourceEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() addResourceEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() removeResourceEmitter: EventEmitter<any> = new EventEmitter<any>();

  resourceId: string;

  resourceData: any;

  constructor(protected utilsService: UtilsService,
    protected toasterService: ToasterService,
    protected datatableService: DatatableService,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected stereotypesService: StereotypeService,
    protected ts: TranslateService,
    protected alertService: AlertService,
    protected actionbarService: ActionBarService,
    protected alertBlockService: AlertBlockService,
    protected defaultFilter: Array<IFilterField> = []) {
    super(utilsService, router, datatableService, toasterService, stereotypesService, ts, alertService, actionbarService, alertBlockService, defaultFilter);
    this.datatableService.subscription(this.config.resource)
      .subscribe(response => {
        this._responseHandling(response, this.config.resourceIdName);
        this._buildEntityColumn();
      });
  }

  protected _getResource(params: HttpParams = this.currentParams, sortOptions: ISortObject = this.currentSortOption, filter: boolean = true) {
    this._getDatatable(params, sortOptions, filter);
  }

  async deleteResource(resourceData: any) {
    event.stopPropagation();

    if (await this.alertService.confirmDelete() == false)
      return;


    this.animationActionArray[resourceData[this.config.resourceIdName]].isDeleting = true;
    this.datatableService.delete(this.config.resource, resourceData[this.config.resourceIdName] + '')
      .subscribe(response => {
        this.showToaster('success', 'SUCCESS.deleted');
        this.animationActionArray[resourceData[this.config.resourceIdName]].isDeleting = false;
        this.removeFromList(this.config.resourceIdName, resourceData);
      }, err => {
        this.showToaster('error', 'ERROR.notDeleted');
        if (err instanceof HttpErrorResponse) {
          this.actionbarService.addHttpErrors(err);
        }
        this.animationActionArray[resourceData[this.config.resourceIdName]].isDeleting = false;
      });
  }

  editMode(resourceData: any = this.resourceData) {
    this.resourceData = resourceData;
    this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = true;
    this.datatableService.getOne(this.config.resource, resourceData[this.config.resourceIdName] + '')
      .subscribe({
        next: value => this.successFetchOneHandler(resourceData, value),
        error: err => this.errorFetchOneHandler(resourceData, err)
      });
    this.lastAction = this.fetchOne.bind(this);
  }

  fetchOne() {
    this.editMode();
  }

  successFetchOneHandler(resourceData, response) {
    this.isLoading = false;
    this.selectResourceEmitter.next(response);
    this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
  }

  errorFetchOneHandler(resourceData, err) {
    this.animationActionArray[resourceData[this.config.resourceIdName]].isLoading = false;
    this._errorHandler(err);
  }

  toDetailPage(path: Array<string> | string, resourceName: string, resourceId: string = null) {
    this.breadcrumbsService.setResourceName(resourceName);
    path = Array.isArray(path) ? path.toString() : path;
    if (resourceId) {
      this.router.navigate([path + resourceId]);
    } else {
      this.router.navigate([path]);
    }
  }

  reset() {
    this.rows = [];
    this.temp = [];
  }
}
