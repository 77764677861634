import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpLangInterceptor implements HttpInterceptor {

    constructor(
        private trans: TranslateService,
    ) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var currentLang = this.trans.currentLang || this.trans.defaultLang;

        if (
            request.url.toLowerCase().includes(`/assets/i18n/`) ||
            request.url.startsWith('./')
        ) {
            return next.handle(request);
        }


        var requestWithLang = request.clone({
            setHeaders: {
                'Accept-Language': currentLang
            }
        });

        return next.handle(requestWithLang);
    }
}
