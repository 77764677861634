import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { AuthorizationService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  bsModalRef: BsModalRef;
  loginForm: FormGroup;
  loginProvider: any;
  loginIsLoading: boolean = false;

  @ViewChild('email') emailField: ElementRef;
  @ViewChild('password') passwordField: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthorizationService,
    private activatedRoute: ActivatedRoute) {
  }

  submitForm($ev, form: FormGroup) {
    $ev.preventDefault();
    for (let c in form.controls) {
      form.controls[c].markAsTouched();
    }
    if (form.valid) {
      let email = form.controls['email'].value;
      let password = btoa(form.controls['password'].value);

      this.loginIsLoading = true;
      this.authService.login(email, password).then(() => {
        this.loginIsLoading = false;
        const prevUrl = this.authService.previousUrl ? this.authService.previousUrl : '/';

        this.router.navigate([prevUrl]);
      }).catch((err) => {
        this.loginIsLoading = false;

        if (err.status == 404) {
          form.setErrors({ 'invalid-login': true });
        } else if (err.status == 403) {
          form.setErrors({ 'login-forbidden': true });
        } else if (err.status == 422) {
          this.router.navigate(['/change-password'], { queryParams: { email: encodeURIComponent(email) } });
        } else {
          form.setErrors({ 'login-error': true });
        }
      });
    }
  }

  ngOnInit() {
    let email = this.activatedRoute.snapshot.queryParamMap.get('email');

    if (email) {
      email = decodeURIComponent(email);
      this.passwordField.nativeElement.focus();
    } else {
      this.emailField.nativeElement.focus();
    }

    this.loginForm = this.fb.group({
      'email': [email, Validators.compose([Validators.required, CustomValidators.email])],
      'password': [null, Validators.required],
    });
  }

}
