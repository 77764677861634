import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IActionButton } from '../../../shared/interfaces/ui';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ActionBarService {
  buttonArray: Array<IActionButton> = [];
  alerts: Array<string> = [];
  actions$: BehaviorSubject<Array<IActionButton>> = new BehaviorSubject<Array<IActionButton>>(this.buttonArray);
  alerts$: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(this.alerts);
  constructor() { }

  setActionButtons(buttons: Array<IActionButton>) {
    this.buttonArray = [...buttons];
    this.actions$.next(buttons);
  }

  addActionButtons(buttons: Array<IActionButton>) {
    this.buttonArray = [...this.buttonArray, ...buttons];
    this.actions$.next(this.buttonArray);
  }

  removeActionButton(button: IActionButton) {
    this.buttonArray = this.buttonArray.filter(el => el.label !== button.label);
    this.actions$.next(this.buttonArray);
  }
  
  addAlert(alert: string) {
    this.alerts = [...this.alerts, alert];
    this.alerts$.next(this.alerts);
  }
  
  addHttpErrors(httpError: HttpErrorResponse) {
    let errors = [];
    if(httpError.error.Errors) {
      errors = (<{ Description: string }[]>httpError.error.Errors)
        .filter(x => x && x.Description && x.Description.length > 0)
        .map(x => x.Description);
    }
    if(httpError.error.error) {
      errors = [ httpError.error.error ];
    }

    errors.forEach(error => this.addAlert(error));
  }

  setState(label: string, state: 'loading' | 'enabled' | 'disabled') {
    const button = this.buttonArray.find(el => el.label === label);
    if (button) {
      switch (state) {
        case 'disabled':
          button.isLoading = false;
          button.isDisabled = true;
          break;
        case 'enabled':
          button.isLoading = false;
          button.isDisabled = false;
          break;
        case 'loading':
          button.isDisabled = false;
          button.isLoading = true;
          break;
      }
    }
    this.actions$.next(this.buttonArray);
  }

  reset() {
    this.actions$.next([]);
    this.alerts$.next([]);
  }

}

