import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appLoadingIcon]'
})
export class LoadingIconDirective {
  protected _elementClass: string[] = [];
  protected _class: string;
  protected _loadingClass: string = 'fa fa-circle-o-notch fa-spin mr-1';

  @Input()
  set appLoadingIcon(status: boolean) {
    this.changeLoading(status);
  }

  @HostBinding('class')
  get elementClass(): string {
    return this._elementClass.join(' ');
  }

  constructor(el: ElementRef) {
    this._class = el.nativeElement.classList.toString();
    this._elementClass.push(el.nativeElement.classList.toString());
  }

  private changeLoading(status: boolean) {
    this._elementClass = [];
    if (status) {
      this._elementClass.push(this._loadingClass);
    } else {
      this._elementClass.push(this._class);
    }
  }
}
