import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteBase } from '../../classes/autocomplete-base';
import { Subject } from 'rxjs';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';

@Component({
  selector: 'app-locations-autocomplete',
  templateUrl: './locations-autocomplete.component.html',
  styleUrls: ['./locations-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class LocationsAutocompleteComponent extends AutocompleteBase implements OnInit {

  @Input()
  set items(value) {
    this._items = [...this._items, ...value];
  }

  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();
  public selected: number[] = [];
  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(
    protected autocompleteService: AutocompleteService
  ) {
    super(autocompleteService);
    this.url = 'location';
  }

  async ngOnInit() {
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter('name', value)
    });
    await this.getAll();
  }

  onChange(element) {
    this.changeValue.next(element);
  }

}
