import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { IFilterField, IOperation, IPageableReq, IPaging, ISortObject } from '../../shared/interfaces/http';
import { UtilsService } from '../../shared/utils/utils.service';
import { HttpRequestService } from '../index';
import { StereotypeService } from '../stereotype/stereotype.service';
import {TableConfig} from '../../shared/constants/table-config';
import {TaskJobState} from '../../shared/models/server/Enums';
import {HttpRequestBaseService} from '../http-request-base/http-request-base.service';


export abstract class  DatatableServiceBase {

  apiPath: string = null;

  resources$: Array<Subject<any>> = [];

  errors$: Array<Subject<any>> = [];

  resource: IPageableReq;

  stereotypes: Array<any> = [];

  errorSubscriptionName: string;

  constructor(protected apiService: HttpRequestBaseService,
              protected stereotypeService: StereotypeService,
              protected utilsService: UtilsService) {
    this.fetchStereotypes();
  }

  setApiPath(path: string) {
    this.apiPath = path;
  }

  subscription(name: string) {
    this.resources$[name] = new Subject<any>();
    return this.resources$[name].asObservable();
  }

  errorSubscription(name: string) {
    this.errorSubscriptionName = name;
    this.errors$[name] = new Subject<any>();
    return this.errors$[name].asObservable();
  }

  addToLocal(data: any, name: string) {
    if (this.resource) {
      this.resource.items.push(data);
      this.resource.paging.totalItems++;
      this.successHandler(this.resource, name);
    }
  }

  putToLocal(data: any, resourceId: string) {
    if (this.resource) {
      const index = this.resource.items.findIndex(el => el[resourceId] === data[resourceId]);
      this.resource.items[index] = data;
      this.successHandler(this.resource, resourceId);
    }
  }

  post<T>(controllerPath: string, data: any) {
    return this.apiService.post<T>(controllerPath,  data, null, true, this.apiPath)
      .pipe(map((response: HttpResponse<T>) => {
        return response.body;
      }));
  }

  getOne<T>(controllerPath: string, resourceId: string, localSort: string = null, localFieldSort: string = null): Observable<T> {
    return this.apiService.get<T>([controllerPath, resourceId].join('/'))
      .pipe(map((response: HttpResponse<T>) => response.body))
      .pipe(map((response) => {
        if (localSort && localFieldSort) {
          response[localFieldSort] = response[localFieldSort].sort((a, b) => {
            if (localSort === 'asc') {
              return a.content.localeCompare(b.content);
            } else {
              return b.content.localeCompare(a.content);
            }
          });

        }
        return response;
      }));
  }

  get(controllerPath: string,
      params: HttpParams = null,
      sortOptions: ISortObject  = { sortField: null, sort: null },
      cols: Array<{ key: string, value: string}>,
      pageSize: number = TableConfig.pageSize,
      name: string) {
    const query = this.utilsService.queryBuilder([
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
      ...cols
      ], pageSize);

    this.apiService.get<IPageableReq>([controllerPath, query].join('/'), null, params, true, this.apiPath)
      .pipe(
        map((response: HttpResponse<IPageableReq>) => this.handleRequest(response, controllerPath)),
        tap(response => this.resource = response))
      .subscribe({
        next: response => this.successHandler(response, name),
        error: err => this.errorRequestHandler(err)
      });
  }

  getByParentResource(parent: string,
                      parentId: string,
                      params: HttpParams = null,
                      controllerPath: string,
                      operation: IOperation['type'] = 'All',
                      sortOptions: ISortObject = { sortField: null, sort: null },
                      pageSize: number = TableConfig.pageSize,
                      name: string) {
    const query = this.utilsService.queryBuilder([
      { key: 'operation', value: operation},
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
    ], pageSize);
    return this.apiService.get<IPageableReq>([parent, parentId, controllerPath, query].join('/'), null, params, true, this.apiPath)
      .pipe(
        map((response: HttpResponse<IPageableReq>) => this.handleRequest(response, controllerPath) ),
        tap(response => this.resource = response))
      .subscribe({
        next: response => this.successHandler(response, name),
        error: err => this.errorRequestHandler(err)
      });
  }

  getFilterByFieldsByParentResource(parent: string,
                                    parentId: string,
                                    controllerPath: string,
                                    filter: Array<IFilterField>,
                                    paging: IPaging,
                                    operation: IOperation['type'] = 'All',
                                    sortOptions: ISortObject = { sortField: null, sort: null },
                                    cols: Array<{ key: string, value: string }>,
                                    pageSize: number = TableConfig.pageSize,
                                    name: string) {
    const query = this.utilsService.queryBuilder([
      { key: 'operation', value: operation},
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
      { key: 'pageSize', value: paging.pageSize },
      { key: 'pageNumber', value: paging.pageNumber },
      ...cols
    ], pageSize);
    this.apiService.post<IPageableReq>([parent, parentId, controllerPath, 'filter', query].join('/'), filter, null, true, this.apiPath)
      .debounceTime(400)
      .pipe(
        map((response: HttpResponse<IPageableReq>) => this.handleRequest(response, controllerPath) ),
        tap(response => this.resource = response))
      .subscribe({
        next: response => this.successHandler(response, name),
        error: err => this.errorRequestHandler(err)
      });
  }

  getFilterByFields(controllerPath: string,
                    filter: Array<IFilterField>,
                    paging: IPaging,
                    operation: IOperation['type'] = 'Include',
                    sortOptions: ISortObject = { sortField: null, sort: null },
                    cols: Array<{ key: string, value: string }>,
                    pageSize: number = TableConfig.pageSize,
                    name: string) {
    const query = this.utilsService.queryBuilder([
      // { key: 'operation', value: operation},
      { key: 'operation', value: operation },
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
      { key: 'pageSize', value: paging.pageSize },
      { key: 'pageNumber', value: paging.pageNumber },
      ...cols
    ], pageSize);

    this.apiService.post<IPageableReq>([controllerPath, 'filter', query].join('/'), filter, null, true, this.apiPath)
      .debounceTime(400)
      .pipe(
        map((response: HttpResponse<IPageableReq>) => this.handleRequest(response, controllerPath)),
        tap(response => this.resource = response))
      .subscribe({
        next: response => this.successHandler(response, name),
        error: err => this.errorRequestHandler(err)
      });
  }

  put<T>(controllerPath: string,
         resourceId: string,
         data: any): Observable<T> {
    return this.apiService.update<T>([controllerPath, resourceId].join('/'), data, null, true, this.apiPath)
      .pipe(
        map((response: HttpResponse<T>) => response.body ));
  }

  delete<T>(controllerPath: string, resourceId: string): Observable<T> {
    return this.apiService.delete<T>(controllerPath, resourceId, null, true, this.apiPath)
      .pipe(map((response: HttpResponse<T>) => {
        return response.body;
      }));
  }

  removeFromParent<T>(controllerPath: string,
                      resourceId: string,
                      parentResource: string,
                      parentId: string) {
    return this.apiService.delete<T>([parentResource, parentId, controllerPath].join('/'), resourceId, null, true, this.apiPath)
      .pipe(map((response: HttpResponse<T>) => {
        return response.body;
      }));
  }

  addToParent<T>(controllerPath: string,
                 resourceId: string,
                 parentResource: string,
                 parentId: string) {
    // return this.apiService.delete([this.apiCompanyControllerPath, companyId, 'address'].join('/'), addressId + '');
    return this.apiService.post<T>([parentResource, parentId, controllerPath, resourceId].join('/'), {}, null, true, this.apiPath)
      .pipe(map((response: HttpResponse<T>) => {
        return response.body;
      }));
  }

  successHandler(response: any, name: string) {
    this.resources$[name].next(response);
  }

  errorRequestHandler(err: any) {
    this.errors$[this.errorSubscriptionName].next(err);
  }

  handleRequest(response: HttpResponse<IPageableReq>, str: string = '') {
    this.serializeResponse(response);

    return response.body;
  }

  public serializeResponse(response): void {
    if (response.body.items && response.body.items.find(el => el.jobStatus)) {
      const items = response.body.items;
      items.forEach(el => {
        if (el.hasOwnProperty('jobStatus')) {
          const status = el['jobStatus'].find(data => data.status === TaskJobState.Canceled);
          el['jobStatus'] = status && status.reason ? status.reason.content : '';
        }
      });
    }
  }

  fetchStereotypes() {
    return of([]);
    // this.apiService.get('stereotype')
    //   .pipe(map((response: HttpResponse<any>) => {
    //     const ar = [];
    //     const res = response.body;
    //     const stereotypes = res.map( item => item.stereotypes.map(el => ( { ...el } )) );
    //     stereotypes.forEach(el => ar.push(...el));
    //     return ar;
    //   }))
    //   .subscribe(stereotypes => {
    //     this.stereotypes = stereotypes;
    //   });
  }

}
