import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ToasterService } from 'angular2-toaster';
import { ActionBarService } from '../../../../core/services/action-bar/action-bar.service';
import { BreadcrumbsService } from '../../../../core/services/breadcrumbs/breadcrumbs.service';
import { DatatableService } from '../../../../services/datatable/datatable.service';
import { MasterDataService } from '../../../../services/masterdata/masterdata.service';
import { StereotypeService } from '../../../../services/stereotype/stereotype.service';
import { AlertService } from '../../../../services/ui/alerts/alert.service';
import { LocationListDto } from '../../../../shared/models/server/DataTransferObject/Objects/MasterData/Locations';
import { UtilsService } from '../../../../shared/utils/utils.service';
import { MasterDataTable } from '../../master-data-table';
import {AlertBlockService} from '../../../../core/services/alert-block/alert-block.service';
import {TableConfig} from '../../../../shared/constants/table-config';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent extends MasterDataTable implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('myTable') tableExp: any;

  constructor(protected masterDataService: MasterDataService,
              protected toasterService: ToasterService,
              protected utilsService: UtilsService,
              protected router: Router,
              protected datatableService: DatatableService,
              protected breadcrumbsService: BreadcrumbsService,
              protected stereotypesService: StereotypeService,
              protected ts: TranslateService,
              protected alertService: AlertService,
              protected actionbarService: ActionBarService,
              protected alertBlockService: AlertBlockService) {
    super(utilsService, toasterService, datatableService, router, breadcrumbsService, stereotypesService, ts, alertService, actionbarService, alertBlockService);
    this.tableObject = new LocationListDto();
    this.config = {
      title: 'TITLE.locationTable',
      resource: 'location',
      resourceIdName: 'locationId',
      operation: 'All',
      pageSize: TableConfig.pageSize,
      excludedColumns: {
        entityCustomValues: true,
        isInRelation: true,
        geoLatitude: true,
        geoLongitude: true,
        tenantId: true,
        stereotypeId: true,
        stereotypeRowVersion: true,
        customPropertyValues: true,
        rowVersion: true
      },
      buttons: {
        view: true,
        edit: true,
        delete: true
      }
    };
    this.createColumns();
  }

  ngOnInit() {
  }

  setLocations(locations: Array<any>, id: string) {
    if (id) {
      this.dataId = id;
    }
    this._getResource(this.currentParams, this.currentSortOption);
  }

  onSelect(e) {
    if (e.type === 'click') {
      const data = e.row;
      this.toDetailPage(['masterdata/locations/location/'], data.name, data.locationId);
    }
  }

  toEdit(locationName: string, locationId: string) {
    this.breadcrumbsService.setResourceName(`${locationName}`);
    this.router.navigate([`masterdata/locations/location/${locationId}`], { queryParams: { edit: 1 } });
  }

}

