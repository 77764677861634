import { Component, Input, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { AutocompleteBase } from '../../classes/autocomplete-base';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';

@Component({
  selector: 'app-contactgroup-autocomplete',
  templateUrl: './contactgroup-autocomplete.component.html',
  styleUrls: ['./contactgroup-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class ContactgroupAutocompleteComponent extends AutocompleteBase implements OnInit {

  @Input() public initId: number;

  public selected: number | number[];

  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(protected autocompleteService: AutocompleteService) {
    super(autocompleteService);
    this.url = 'contactgroup';
  }

  async ngOnInit() {
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter('name', value);
    });
    await this.getAll();
    if (!this.selected && this._items && this._items.length > 0) {
      this.selected = this.initId || this._items[0].contactGroupId;
      const item = this._items.find(x => x.contactGroupId == this.selected);
      this.changeValue.next(item);
      // this.typeaheadHandler(this._items, 'contactGroupId');
    }
  }

  onChange(element) {
    this.changeValue.next(element);
  }

}
