import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appDisabled]'
})
export class DisabledDirective implements OnInit {
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isDisableAttr: any;
  @Input() isDisable: boolean;

  @HostListener('click') onMouseBlur() {
    this.clicked.next(true);
  }

  constructor(private  el: ElementRef) {

  }

  ngOnInit() {
    if (this.isDisableAttr) {
      this.el.nativeElement.disabled = this.isDisable;
    }
  }

}
