import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteBase } from '../../classes/autocomplete-base';
import { Subject } from 'rxjs';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';
import {IFilterField} from '../../interfaces/http';
import {FormTypes} from '../../models/server/Enums';

@Component({
  selector: 'app-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class FormAutocompleteComponent extends AutocompleteBase implements OnInit {

  @Input() public initId: number;

  @Output() public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();

  isValidForm = true;

  private clicked: boolean;

  public selected: number;

  additionalFilter: Array<IFilterField> = [{Property: 'type', Value: FormTypes.Task as any}];

  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(
    protected autocompleteService: AutocompleteService
  ) {
    super(autocompleteService);
    this.url = 'form';
  }

  async onSelectClick() {
    if (!this.clicked || this._items.length === 0) {
      this._loading = true;
      this.clicked = true;

      if (this.initOnStart) {
        await this.getAll();
        this.filterItems(this._items, 'formId');
        this._loading = false;
      }

      if (!this.selected && this._items && this._items.length > 0) {
        this.selected = this.initId || this._items[0].formId;
        const item = this._items.find(x => x.formId == this.selected);
        this.isValid.next(!!item);
        this.isValidForm = !!item;
        this.changeValue.next(item);
      }

    }
  }

  async ngOnInit() {
    this.extendFilter();
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter('title', value);
      this.extendFilter();
    });
  }

  extendFilter() {
    this._filter = [...this._filter, ...this.additionalFilter];
  }

  onChange(element) {
    this.isValidForm = true;
    this.isValid.next(!!element);
    this.changeValue.next(element);
  }

}
