import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  constructor() { }

  set(key: string, storageObject: Object)
  {
    localStorage.setItem(key, JSON.stringify(storageObject));
  }

  get(key: string): any
  {
    var storageObject = localStorage.getItem(key);

    if(storageObject == "undefined" || storageObject == null || storageObject == undefined)
    {
      return null;
    }
    else
    {
      return JSON.parse(storageObject);
    }
  }

  remove(key: string)
  {
    localStorage.removeItem(key);
  }
}
