import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import {FormsModule, NgSelectOption, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { KnobDirective } from './directives/knob/knob.directive';

import { SettingsService } from './settings/settings.service';
import { PeityDirective } from './directives/peity/peity.directive';
import { ModalsMessageComponent } from './modals/modals-message/modals-message.component';
import { UpperFirstPipe } from './pipes/upper-first.pipe';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
// import {SelectModule} from 'ng2-select';
import { PriorityDropdownComponent } from './components/priority-dropdown/priority-dropdown.component';
import { StateDropdownComponent } from './components/state-dropdown/state-dropdown.component';
import { SpinnerBlockComponent } from './components/spinner-block/spinner-block.component';
import { LoadingDirective } from './directives/loading/loading.directive';
import { LoadingComponent } from './components/loading/loading.component';
import { ClickedDirective } from './directives/clicked/clicked.directive';
import { DisabledDirective } from './directives/disabled/disabled.directive';
import { PermissionDirective } from './directives/permission/permission.directive';
import { StereotypesDropdownSelectComponent } from './components/stereotypes-dropdown-select/stereotypes-dropdown-select.component';
import { ResourceAutocompleteComponent } from './components/resource-autocomplete/resource-autocomplete.component';
import {UtilsService} from './utils/utils.service';
import { LoadingIconDirective } from './directives/loading-icon/loading-icon.directive';
import { SortDirectionDirective } from './directives/sort-direction/sort-direction.directive';
import { RolesAutocompleteComponent } from './components/roles-autocomplete/roles-autocomplete.component';
import { PanelComponent } from './components/panel/panel.component';
import { TabComponent } from './components/panel/tab/tab.component';
import { TableConfigComponent } from './components/table-config/table-config.component';
import { ContactgroupAutocompleteComponent } from './components/contactgroup-autocomplete/contactgroup-autocomplete.component';
import { LocationsAutocompleteComponent } from './components/locations-autocomplete/locations-autocomplete.component';
import { LocationgroupsAutocompleteComponent } from './components/locationgroups-autocomplete/locationgroups-autocomplete.component';
import { FormAutocompleteComponent } from './components/form-autocomplete/form-autocomplete.component';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { SummernoteComponent } from './components/summernote/summernote.component';
import { ContactsGroupSelectComponent } from './components/contacts-group-autocomplete/contacts-group-select.component';
import { FormsSelectComponent } from './components/forms-select/forms-select.component';
import { LocationAutocompleteComponent } from './components/location-autocomplete/location-autocomplete.component';
import { FileService } from '../services/files/file.service';
import { AlertService } from '../services/ui/alerts/alert.service';
import { RolesDropdownComponent } from './components/roles-dropdown/roles-dropdown.component';
import { KnowledgearticleAutocompleteComponent } from './components/knowledgearticle-autocomplete/knowledgearticle-autocomplete.component';
import { TaskAutocompleteComponent } from './components/task-autocomplete/task-autocomplete.component';
import { HolidaysetAutocompleteComponent } from './components/holidayset-autocomplete/holidayset-autocomplete.component';
import { ContactAutocompleteComponent } from './components/contact-autocomplete/contact-autocomplete.component';
import {LocalDateTimePipe} from './pipes/local-date-time.pipe';
import {ContactsListComponent} from '../routes/master-data/contacts/contacts-list/contacts-list.component';
import {LocationListComponent} from '../routes/master-data/location/location-list/location-list.component';
import { BooleanDropdownComponent } from './components/boolean-dropdown/boolean-dropdown.component';
import { TypesDropdownComponent } from './components/types-dropdown/types-dropdown.component';
import { DataAutocompleteComponent } from './components/data-autocomplete/data-autocomplete.component';
import { IdentityDatatableBaseComponent } from './components/identity-datatable-base/identity-datatable-base.component';
import { FolderManagerComponent } from './components/folder-manager/folder-manager.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import {TextMaskModule} from 'angular2-text-mask';
import { NumberLimitDirective } from './directives/number-limit.directive';
import { DataTableDetailButtonComponent } from './components/data-table-detail-button/data-table-detail-button.component';
import { NgxSelectModule } from 'ngx-select-ex';
// https://angular.io/styleguide#!#04-10
const modules = [
  CommonModule,
  FormsModule,
  NgSelectModule,
  ReactiveFormsModule,
  TranslateModule,
  NgxDatatableModule,
  NgxSelectModule
  // SelectModule1
];

const components = [
  LocationListComponent,
  ContactsListComponent,
  EasypiechartDirective,
  FlotDirective,
  SparklineDirective,
  VectormapDirective,
  KnobDirective,
  PeityDirective,
  ClickedDirective,
  PermissionDirective,
  ModalsMessageComponent,
  UpperFirstPipe,
  LocalDateTimePipe,
  DisabledDirective,
  LoadingDirective,
  SortDirectionDirective,
  LoadingComponent,
  LoadingIconDirective,
  NumberLimitDirective,
  PriorityDropdownComponent,
  StateDropdownComponent,
  SpinnerBlockComponent,
  StereotypesDropdownSelectComponent,
  ResourceAutocompleteComponent,
  SpinnerBlockComponent,
  RolesAutocompleteComponent,
  PanelComponent,
  TabComponent,
  ContactgroupAutocompleteComponent,
  LocationsAutocompleteComponent,
  LocationAutocompleteComponent,
  LocationgroupsAutocompleteComponent,
  FormAutocompleteComponent,
  KnowledgearticleAutocompleteComponent,
  DatetimePickerComponent,
  SummernoteComponent,
  ContactsGroupSelectComponent,
  FormsSelectComponent,
  RolesDropdownComponent,
  TaskAutocompleteComponent,
  HolidaysetAutocompleteComponent,
  ContactAutocompleteComponent,
  BooleanDropdownComponent,
  TypesDropdownComponent,
  DataAutocompleteComponent,
  FolderManagerComponent,
  SearchInputComponent,
  DataTableDetailButtonComponent
];
@NgModule({
    imports: [
        ...modules,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        PopoverModule.forRoot(),
        TextMaskModule
    ],
    providers: [
        SettingsService,
        UtilsService,
        FileService,
        AlertService
    ],
    declarations: [
      ...components,
      TableConfigComponent,
      IdentityDatatableBaseComponent,
    ],
    exports: [
        ...modules,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule,
        TimepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        NgSelectOption,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        TypeaheadModule,
        PopoverModule,
        ...components,
    ],
  entryComponents: [LoadingComponent]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
