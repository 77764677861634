import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutocompleteService} from '../../../services/autocomplete/autocomplete.service';
import {AutocompleteBase} from '../../classes/autocomplete-base';
import {Subject} from 'rxjs';
import {HttpRequestService} from '../../../services';

@Component({
  selector: 'app-contact-autocomplete',
  templateUrl: './contact-autocomplete.component.html',
  styleUrls: ['./contact-autocomplete.component.scss'],
  providers: [AutocompleteService, HttpRequestService]
})
export class ContactAutocompleteComponent extends AutocompleteBase implements OnInit {

  public selected: number | number[];

  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(protected autocompleteService: AutocompleteService) {
    super(autocompleteService);
    this.url = 'contact';
  }

  async ngOnInit() {
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter('displayName', value);
    });
    await this.getAll();
    if (!this.selected && this._items && this._items.length > 0) {
      this.typeaheadHandler(this._items, 'contactId');
    }
  }

  onChange(element) {
    this.changeValue.next(element);
  }

}
