import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { SigninComponent } from './signin/signin.component';
import { SigninProvidersComponent } from './signin-providers/signin-providers.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ModalsMessageComponent } from '../../shared/modals/modals-message/modals-message.component';
import { SigninContainerComponent } from './signin-container/signin-container.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthRedirectComponent } from './auth-redirect/auth-redirect.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        SigninComponent,
        SigninProvidersComponent,
        ChangePasswordComponent,
        SigninContainerComponent,
        ResetPasswordComponent,
        AuthRedirectComponent
    ],
    entryComponents: [ModalsMessageComponent]
})
export class UserModule { }
