import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SearchComponent } from './header/search/search.component';
import { SettingsComponent } from './settings/settings.component';
import {BreadcrumbsComponent} from "../core/components/breadcrumbs/breadcrumbs.component";
import {ActionBarComponent} from "../core/components/action-bar/action-bar.component";
import { TranslateModule } from '@ngx-translate/core';
import {AlertBlockComponent} from '../core/components/alert-block/alert-block.component';
import {AlertBlockService} from '../core/services/alert-block/alert-block.service';


@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        TranslateModule
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        SearchComponent,
        SettingsComponent,
        BreadcrumbsComponent,
        ActionBarComponent,
        AlertBlockComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        SearchComponent,
        SettingsComponent,
    ]
})
export class LayoutModule { }
