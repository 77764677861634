import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {HttpRequestBaseService} from '../http-request-base/http-request-base.service';


@Injectable()
export class HttpRequestService extends HttpRequestBaseService {

  constructor(protected httpClient: HttpClient, protected httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }
}
