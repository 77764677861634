import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {StereotypeService} from '../../../services/stereotype/stereotype.service';

@Component({
  selector: 'app-stereotypes-dropdown-select',
  templateUrl: './stereotypes-dropdown-select.component.html',
  styleUrls: ['./stereotypes-dropdown-select.component.scss']
})
export class StereotypesDropdownSelectComponent implements OnInit {
  @Input() typeId: number;
  @Input() shotOnInit = true;
  @Input()
  set setTypeId(value) {
    this.typeId = value;
    this.setDefaultValue(this.typeId);
    this.selectType.next(this.typeId);
  };
  @Input() type: string | null;
  @Input() tableDropdown: boolean;
  @Input() readonly = false;
  @Output() selectType: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('select') select: ElementRef;
  stereotypes: Array<any> = [];

  constructor(private stereotypeService: StereotypeService) {
  }

  ngOnInit() {
    if (this.type) {
      this.stereotypeService.getStereotypesListByEntityType(this.type)
        .subscribe(types => {
          if(types.length !== 0) {
            const stereotypeId = this.typeId ? this.typeId : types[0].stereotypeId;
            this.setDefaultValue(stereotypeId);
            if (this.shotOnInit) {
              this.selectType.next(stereotypeId);
            }
            this.stereotypes = types;
            if (this.tableDropdown) {
              this.stereotypes.unshift({ name: 'All', stereotypeId: null});
              this.setDefaultValue(stereotypeId);
              if (this.shotOnInit) {
                this.selectType.next(stereotypeId);
              }
            }
          }
        });
    }
  }

  setDefaultValue(value: number | null) {
    if (value) {
      this.typeId = this.typeId ? this.typeId : value;
      this.select.nativeElement.value = value;
      return;
    }
    this.typeId = null;
    this.select.nativeElement.value = null;
  }

  emitChange() {
    this.selectType.next(this.typeId);
  }

}
