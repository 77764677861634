import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface TypesDropdownConfig {
  prfx: string;
  readonly: boolean;
}

export interface DropDownValues {
  label: string;
  value: string | boolean;
}

@Component({
  selector: 'app-types-dropdown',
  templateUrl: './types-dropdown.component.html',
  styleUrls: ['./types-dropdown.component.scss']
})
export class TypesDropdownComponent implements OnInit {

  @Input() typesDropdownConfig: TypesDropdownConfig = {
    prfx: 'TASKS.TYPES',
    readonly: false
  };

  @Input()
  set dropdownValues(data: any) {
    this.getKeys(data);
  }

  @Output() selected: EventEmitter<string | boolean> = new EventEmitter<string | boolean>();

  values: Array<DropDownValues> = [];

  selectedItem: DropDownValues = this.values[0];

  constructor() { }

  ngOnInit() {
    this.setDefaultValue();
    this.addNullValue();
  }

  getKeys(data: any) {
    this.values = [];
    for (const obj in data) {
      if (data.hasOwnProperty(obj) && typeof data[obj] === 'number' && obj !== 'Base') {
        this.values.push({label: `${this.typesDropdownConfig.prfx}.${obj}`, value: obj});
      }
    }
    this.setDefaultValue();
    this.addNullValue();
  }

  setDefaultValue() {
    if (!this.selectedItem) {
      this.selectedItem = { label: 'BOOLEAN.empty', value: '' };
    }
  }

  addNullValue() {
    if (this.values.filter((el) => el.value === '').length === 0) {
      this.values.unshift(this.selectedItem);
    }
  }

  selectedValue(item: DropDownValues) {
    this.selected.next(item.value + '');
  }
}
