import { AppPermissions } from "../shared/models/server/Enums/Permissions";

const headingMenu = {
  order: 0,
  name: 'MENU',
  heading: true
};

const Dashboard = {
  name: 'MENU.dashboard',
  link: '/dashboard',
  iconclass: 'fa fa-bar-chart',
  order: 1,
  permission: AppPermissions.DisplayDashboard,
  subitems: [
    {
      name: 'MENU.dashboardOverview',
      link: '/dashboard/overview',
      permission: AppPermissions.DisplayDashboard,
    },
    {
      name: 'MENU.dashboardTasks',
      link: '/dashboard/tasks',
      permission: AppPermissions.DisplayDashboard,
    }
  ]
};

const MasterData = {
  name: 'MENU.masterData',
  link: '/masterdata',
  iconclass: 'fa fa-database',
  order: 2,
  permission: AppPermissions.DisplayMasterData,
  subitems: [
    {
      name: 'MENU.contacts',
      link: '/masterdata/contacts',
      permission: AppPermissions.DisplayContacts
    },
    {
      name: 'MENU.contactsGroups',
      link: '/masterdata/contactgroups',
      permission: AppPermissions.DisplayContactGroups
    },
    {
      name: 'MENU.locations',
      link: '/masterdata/locations',
      permission: AppPermissions.DisplayLocations
    },
    {
      name: 'MENU.locationGroups',
      link: '/masterdata/locationgroups',
      permission: AppPermissions.DisplayLocationGroups
    },
    {
      name: 'MENU.holidays',
      link: '/holidays',
      permission: AppPermissions.DisplayHolidays
    }
  ]
};

const Forms = {
  name: 'MENU.forms',
  link: '/forms',
  iconclass: 'fa fa-list-alt',
  order: 3,
  permission: AppPermissions.DisplayForms
};

const Tasks = {
  name: 'MENU.tasks',
  link: '/tasks',
  iconclass: 'fa fa-check',
  order: 4,
  permission: AppPermissions.DisplayTasks
};

const Documents = {
  name: 'MENU.documents',
  link: '/documents',
  iconclass: 'fa fa-file',
  order: 5,
  permission: AppPermissions.DisplayDocuments
};

const Infos = {
  name: 'MENU.infos',
  iconclass: 'fa fa-info',
  link: '/infos',
  order: 6,
  permission: AppPermissions.DisplayInfos
};

const Knowledge = {
  name: 'MENU.knowledge',
  link: '/knowledge',
  iconclass: 'fa fa-question',
  order: 7,
  permission: AppPermissions.DisplayKnowledge
};

const Chats = {
  name: 'MENU.chat',
  link: '/chats/dashboard',
  iconclass: 'fa fa-comments',
  order: 8,
  permission: AppPermissions.DisplayChats
};


const CancelReasons = {
  name: 'MENU.cancelreasons',
  link: '/cancel-reasons',
  iconclass: 'fa fa-times',
  order: 9,
  permission: AppPermissions.DisplayTasks
};

const Devices = {
  name: 'MENU.devices',
  link: '/devices',
  iconclass: 'fa fa-tablet',
  order: 10,
  permission: AppPermissions.DisplayDevices
};


const GuestCards = {
  name: 'MENU.guestCards',
  link: '/guestcards',
  iconclass: 'fa fa-users',
  order: 11,
  permission: AppPermissions.DisplayGuestCard
};

const Help = {
  name: 'MENU.help',
  href: '/external/app/1',
  iconclass: 'fa fa-question-circle',
  order: 12,
  permission: AppPermissions.DisplayManual
};

export const menu = [
  headingMenu,
  Dashboard,
  MasterData,
  Forms,
  Tasks,
  Documents,
  Infos,
  Knowledge,
  Chats,
  CancelReasons,
  Devices,
  GuestCards,
  Help
];
