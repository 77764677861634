import {Component, ElementRef, OnInit, Output, ViewChild} from '@angular/core';
import {ContactGroupService} from '../../../services/contactgroup/contactgroup.service';
import {ContactGroupDto} from '../../models/server/DataTransferObject/Objects/MasterData/ContactGroups';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-contacts-group-select',
  templateUrl: './contacts-group-select.component.html',
  styleUrls: ['./contacts-group-select.component.scss']
})
export class ContactsGroupSelectComponent implements OnInit {
  @Output() onChanged: Subject<number> = new Subject<number>();
  contactGroup;
  contactGroups;
  constructor(private contactGroupService: ContactGroupService) {
    this.contactGroupService.getContactGroupList()
      .subscribe((groups: Array<ContactGroupDto>) => {
        this.contactGroups = groups;
        this.contactGroups.unshift({ name: 'All', contactGroupId: null })
        this.contactGroup = this.contactGroups[0].contactGroupId;
      });
  }

  selectedGroup(contactGroup: number) {
    this.onChanged.next(contactGroup);
  }

  ngOnInit() {
  }

}
