import { Component, OnInit } from '@angular/core';
import {IBreadCrumb} from '../../../shared/interfaces/ui';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadcrumbsService} from "../../services/breadcrumbs/breadcrumbs.service";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  resourceName: string;
  breadcrumbs$;
  list: Array<IBreadCrumb> = [];
  customPath: Array<any> = [];

  constructor(private activatedRoute: ActivatedRoute,
              private breadcrumbsService: BreadcrumbsService,
              private router: Router) {
    this.breadcrumbsService.subscription$('resourceName')
      .subscribe(name => {
        this.resourceName = name;
      });

    this.breadcrumbsService.subscription$('customPath')
      .subscribe(customPathArray => {
        this.customPath = customPathArray;
      });
    this.breadcrumbs$ = this.router.events
      .filter(event => {
          this.breadcrumbsService.resetCustomPath();
          if (event[0] && event[0].hasOwnProperty('label')) {
            return true;
          }
          return event instanceof NavigationEnd;
      })
      .map(event => {
        this.resourceName = '';
        if (event[0] && event[0].hasOwnProperty('label')) {
          return event;
        }

        return this.buildBreadCrumb(this.activatedRoute.root);
      });
  }

  ngOnInit() {
    if (localStorage.getItem('urls')) {
      this.list = JSON.parse(localStorage.getItem('urls'));
      setTimeout(() => {
        this.breadcrumbs$.next(this.list);
      }, 300);
    }
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '',
                  breadcrumbs: Array<IBreadCrumb> = []): Array<IBreadCrumb> {
    const label = route.routeConfig ? route.routeConfig.data[ 'breadcrumb' ] : 'Home';
    const path = route.routeConfig ? route.routeConfig.path : '';
    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: label,
      url: nextUrl,
      name: ''
    };
    //&& !(!!breadcrumbs.find(element => element.label.search(this.resourceName) > 0)) && this.resourceName
    if (breadcrumb.url.search(':') > 0) {
      const reg = /:(\w+)/g;
      const nameId = reg.exec(breadcrumb.url)[1];
      const params = route.snapshot.params[nameId];
      breadcrumb.url = breadcrumb.url.replace(/:\w+/g, params);
      if (params) {
        // breadcrumb.name = `"${this.resourceName}"`;
      }
    }
    let newBreadcrumbs = [ ...breadcrumbs ];
    if (breadcrumb.label.length) {
      newBreadcrumbs = [ ...newBreadcrumbs, breadcrumb ];
    }
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    const list = newBreadcrumbs.filter(el => el.label !== 'Home');
    list[list.length - 1].url = '';
    localStorage.setItem('urls', JSON.stringify(list));
    return list;
  }
}
