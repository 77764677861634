import {Component, Input, OnInit} from '@angular/core';
import {of} from 'rxjs/observable/of';

@Component({
  selector: 'app-spinner-block',
  templateUrl: './spinner-block.component.html',
  styleUrls: ['./spinner-block.component.scss']
})
export class SpinnerBlockComponent implements OnInit {
  @Input() loader;
  @Input() icon  = 'ball-pulse-sync';

  constructor() { }

  ngOnInit() {

  }

}
