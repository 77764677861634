import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutocompleteBase } from '../../classes/autocomplete-base';
import { Subject } from 'rxjs';
import { AutocompleteService } from '../../../services/autocomplete/autocomplete.service';

@Component({
  selector: 'app-locationgroups-autocomplete',
  templateUrl: './locationgroups-autocomplete.component.html',
  styleUrls: ['./locationgroups-autocomplete.component.scss'],
  providers: [AutocompleteService]
})
export class LocationgroupsAutocompleteComponent extends AutocompleteBase implements OnInit {

  public selected: number[] = [];

  typeaheadValue$: Subject<string> = new Subject<string>();

  constructor(
    protected autocompleteService: AutocompleteService
  ) {
    super(autocompleteService);
    this.url = 'locationgroup';
  }

  async ngOnInit() {
    this.typeaheadValue$.subscribe(value => {
      this.buildFilter('name', value);
    });
    await this.getAll();
    if (this.selected.length === 0 && this._items && this._items.length > 0) {
      this.typeaheadHandler(this._items, 'locationGroupId');
    }
  }

  onChange(element) {
    this.changeValue.next(element);
  }

}
