import {AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appSortDirection]'
})
export class SortDirectionDirective implements AfterViewInit {
  protected _sortDefaultClass = 'fa-sort';
  protected _ascClass = 'fa-sort-asc';
  protected _descClass = 'fa-sort-desc';
  protected _elementClass: string[] = [];
  protected _class: string;
  protected _direction: 'asc' | 'desc' | null = 'asc';
  protected icon;

  @Input()
  set appSortDirection(sortObj: { direction: 'asc' | 'desc' | null, status: boolean } ) {
    if (sortObj) {
      this._direction = sortObj.direction;
      this.changeSorting(sortObj.direction);
    } else {
      this.changeSorting(null);
    }
  }

  @HostListener('click', ['$event']) onCLick(event: Event) {
    if (this.icon) {
      this.icon.classList = 'fa';
      this.changeSorting(this._direction);
    }
  }

  constructor(private el: ElementRef,  private renderer: Renderer2) {
  }

  private changeSorting(direction: 'asc' | 'desc' | null ) {
      this._elementClass = [];
      switch (direction) {
        case 'asc':
          this.renderer.addClass(this.icon, this._ascClass);
          break;
        case 'desc':
          this.renderer.addClass(this.icon, this._descClass);
          break;
        default:
          this.renderer.addClass(this.icon, this._sortDefaultClass);
      }
  }

    ngAfterViewInit() {
      this._class = this.el.nativeElement.classList.toString();
      this._elementClass.push(this.el.nativeElement.classList.toString());
      this.icon = this.el.nativeElement.querySelector('i');
      this.renderer.addClass(this.icon, this._sortDefaultClass);
    }
}

