import { Injectable } from "@angular/core";
import { HttpRequestService } from "..";
import { UtilsService } from "../../shared/utils/utils.service";
import { StereotypeService } from "../stereotype/stereotype.service";
import { DatatableServiceBase } from "./datatable-base.service";

@Injectable()
export class DatatableService extends DatatableServiceBase {

    constructor(
        protected apiService: HttpRequestService,
        protected stereotypeService: StereotypeService,
        protected utilsService: UtilsService
    ) {
        super(apiService, stereotypeService, utilsService);
        this.fetchStereotypes();
    }
}
