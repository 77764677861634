import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {UtilsService} from '../../shared/utils/utils.service';
import {HttpRequestService} from '../';
import {IFilterField} from '../../shared/interfaces/http';

@Injectable()
export class AutocompleteService {

  queryString: string;

  filterField: Array<IFilterField> = [];

  constructor(protected apiService: HttpRequestService,
                        protected utilsService: UtilsService) { }

  getByCount<T>(controllerPath, page: number, filter: Array<IFilterField>, pageSize: number = 25, sort: string = null) {
    if (!this.filterField.length) {
      this.filterField = [...filter];
    }
    if (this.filterField.length > 0 && this.filterField[0].value !== filter[0].value && filter[0].value) {
      page = 1;
      this.filterField = [...filter];
    }
    this.filterField =  [...filter];
    this.queryString = this.utilsService.queryBuilder([
      { key: 'pageSize', value: pageSize },
      { key: 'pageNumber', value: page },
      { key: 'sort', value: sort }
    ]);
    return this.apiService.post<T>([controllerPath, 'filter', this.queryString].join('/'), this.filterField)
      .pipe(map((response: HttpResponse<T>) => response.body));
  }

}
