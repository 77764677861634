import { LayoutComponent } from '../layout/layout.component';
import { TabComponent } from '../shared/components/panel/tab/tab.component';
import { TableConfigComponent } from '../shared/components/table-config/table-config.component';
import { AuthGuard } from '../shared/guards';
import { HelpProxyGuard } from '../shared/guards/help-proxy.guard';
import { ChangePasswordComponent, SigninComponent } from "./user";
import { AuthRedirectComponent } from './user/auth-redirect/auth-redirect.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';

export const routes = [

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard/overview', pathMatch: 'full' },
      { path: 'masterdata', loadChildren: './master-data/master-data.module#MasterDataModule', data: { breadcrumb: 'MENU.masterData' } },
      { path: 'forms', loadChildren: './forms/forms.module#FormsModule', data: { breadcrumb: 'MENU.forms' } },
      { path: 'tasks', loadChildren: './tasks/tasks.module#TasksModule', data: { breadcrumb: 'MENU.tasks' } },
      { path: 'infos', loadChildren: './infos/infos.module#InfosModule', data: { breadcrumb: 'MENU.infos' } },
      { path: 'knowledge', loadChildren: './knowledge/knowledge.module#KnowledgeModule', data: { breadcrumb: 'MENU.knowledge' } },
      { path: 'holidays', loadChildren: './holidays/holidays.module#HolidaysModule', data: { breadcrumb: 'MENU.holidays' } },
      { path: 'cancel-reasons', loadChildren: './cancel-reasons/cancel-reasons.module#CancelReasonsModule', data: { breadcrumb: 'MENU.cancelreasons' } },
      { path: 'devices', loadChildren: './devices/devices.module#DevicesModule', data: { breadcrumb: 'MENU.devices' } },
      { path: 'chats', loadChildren: './chats/chats.module#ChatsModule', data: { breadcrumb: 'MENU.chats' } },
      { path: 'documents', loadChildren: './documents/documents.module#DocumentsModule', data: { breadcrumb: 'MENU.documents' } },
      { path: ':type/table-config', component: TableConfigComponent, data: { breadcrumb: 'MENU.tableConfig' } },
      { path: 'help', canActivate: [HelpProxyGuard], component: TabComponent, data: { breadcrumb: 'MENU.help' }},
      { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'MENU.dashboard' } },
      { path: 'external', loadChildren: './external/external.module#ExternalModule', data: { breadcrumb: 'MENU.external' } },
      { path: 'guestcards', loadChildren: './guestcards/guestcards.module#GuestcardsModule', data: { breadcrumb: 'MENU.guestCards' } },
    ],
    data: {
      breadcrumb: 'Home'
    }
  },
  { path: 'error', loadChildren: './pages/errors/errors.module#ErrorsModule' },
  { path: 'signin', canActivate: [AuthGuard], component: SigninComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'auth-redirect', component: AuthRedirectComponent },

  // Not found
  { path: '**', redirectTo: '/' } // TODO default
];
