import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { NgxSelectModule } from 'ngx-select-ex';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import { JwtInterceptor } from './services/auth/jwt-interceptor.service';
import { HttpLangInterceptor } from './services/http-request/http-lang-interceptor.service';
import { HttpTimeoutInterceptor } from './services/http-request/http-timeout-interceptor.service';
import { LoaderService } from './services/loader/loader.service';
import { ServicesModule } from './services/services.module';
import { DynamicFormBuilder } from './services/utils/dynamic-form-builde';
import { SharedModule } from './shared/shared.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


enableProdMode();
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        ToasterModule,
        ServicesModule,
        RoutesModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          }
        }),
      NgxSelectModule
    ],
    providers: [
        LoaderService,
        ToasterService,
        DynamicFormBuilder,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLangInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTimeoutInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
