import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { IActionButton } from '../../../shared/interfaces/ui';
import { ActionBarService } from '../../services/action-bar/action-bar.service';
import { AuthorizationService } from '../../../services';
import { AppPermissions } from '../../../shared/models/server/Enums/Permissions';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit {
  public actionArray: Array<IActionButton> = [];
  public alerts: string[] = [];
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  constructor(
    private actionBarService: ActionBarService,
    private router: Router,
    private auth: AuthorizationService) {

    this.actionBarService.actions$.subscribe(x => this.loadButtons(x));

    this.actionBarService.alerts$
      .subscribe((alerts: Array<string>) => {
        this.alerts = alerts;
      });

    this.router.events
      .filter(res => res instanceof ActivationStart)
      .subscribe(res => {
        this.actionBarService.reset();
      });
  }

  private async loadButtons(buttons: IActionButton[]) {
    for (let i = 0; i < (buttons || []).length; i++) {
      const button = buttons[i];

      if (button.permission) {
        const hasPermission = await this.auth.hasPermission(AppPermissions[button.permission]);

        button.hidden = hasPermission == false;
      }

      if (button.buttons && button.buttons.length > 0) {
        for (let c = 0; c < button.buttons.length; c++) {
          if(button.buttons[c].permission) {
            const hasChildPermission = await this.auth.hasPermission(AppPermissions[button.buttons[c].permission]);

            button.buttons[c].hidden = hasChildPermission == false;
          }
        }
      }
    }

    this.actionArray = buttons;
  }

  ngOnInit() {
  }

}
