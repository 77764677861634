import { Injectable } from '@angular/core';
import {IService} from '../../shared/interfaces/ui-services';
import {Subject} from 'rxjs/Subject';
import {KnowledgeSection} from '../../shared/models/knowledge/knowledge-section';
import {of} from 'rxjs/observable/of';
import {delay} from 'rxjs/operators';
import {KnowledgeArticle} from '../../shared/models/knowledge/knowledge-article';
import {LoaderService} from '../loader/loader.service';

@Injectable()
export class KnowledgeService implements IService{
  _subscribe: Subject<any> = new Subject<any>();
  _knowledge: Array<any> = [
    {
      id: 1,
      name: 'Section 1',
      topics: [
        {
          name: 'Topic name 1',
          knowledgeSectionId: 1,
          shortDescription: 'Shord description topic lorem 2',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 35
        },
        {
          name: 'Topic name 2',
          knowledgeSectionId: 4,
          shortDescription: 'Shord description topic lorem 5',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 65
        },
        {
          name: 'Topic name 3',
          knowledgeSectionId: 7,
          shortDescription: 'Shord description topic lorem 8',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 95
        },
        {
          name: 'Topic name 4',
          knowledgeSectionId: 10,
          shortDescription: 'Shord description topic lorem 11',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 125
        },
        {
          name: 'Topic name 5',
          knowledgeSectionId: 13,
          shortDescription: 'Shord description topic lorem 14',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 155
        },
        {
          name: 'Topic name 6',
          knowledgeSectionId: 16,
          shortDescription: 'Shord description topic lorem 17',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 185
        },
      ]
    },
    {
      id: 12,
      name: 'Section 2',
      topics: [
        {
          name: 'Topic name 7',
          knowledgeSectionId: 8,
          shortDescription: 'Shord description topic lorem 9',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 96
        },
        {
          name: 'Topic name 8',
          knowledgeSectionId: 11,
          shortDescription: 'Shord description topic lorem 12',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 126
        },
        {
          name: 'Topic name 9',
          knowledgeSectionId: 14,
          shortDescription: 'Shord description topic lorem 15',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 156
        },
        {
          name: 'Topic name 10',
          knowledgeSectionId: 17,
          shortDescription: 'Shord description topic lorem 18',
          icon: 'ion-ios-paper-outline icon-lg',
          articleCount: 186
        },
      ]
    },
  ];
  _articles: Array<any> = [
    {
      id: 1,
      name: 'First article',
      parentKnowledgeArticleId: null,
    },
    {
      id: 2,
      name: '1 article',
      parentKnowledgeArticleId: 1,
    },
    {
      id: 3,
      name: '2 article',
      parentKnowledgeArticleId: 1,
    },
    {
      id: 4,
      name: '1 article',
      parentKnowledgeArticleId: 3,
    },
    {
      id: 5,
      name: 'Main article',
      parentKnowledgeArticleId: null,
    },
    {
      id: 6,
      name: 'Last article',
      parentKnowledgeArticleId: 5,
    },
    {
      id: 7,
      name: 'SIngle article',
      parentKnowledgeArticleId:  null,
    },
    {
      id: 8,
      name: 'SIngle second article',
      parentKnowledgeArticleId: null,
    },
    {
      id: 9,
      name: '2 article',
      parentKnowledgeArticleId: 5,
    }
  ];
  article: KnowledgeArticle = {
    id: 1,
    name: 'Article Name',
    content: `<span class="small text-muted">Small description about new page</span>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam asperiores audantium nihil tempore voluptate?</p>
    <ul>
      <li>1 tesis</li>
      <li>2 tesis</li>
      <li>3 tests</li>
    </ul>`,
    parentKnowledgeArticleId: 'parentKnowledgeArticleId',
    knowledgeTopicId: 'knowledgeTopicId',
    creatorId: 3,
    creator: {
      userId: 3,
      name: 'Mary Jayne'
    }
  };

  constructor() {}

  subscription$() {
    return this._subscribe.asObservable().pipe(delay(1000));
  }

  getKnowledges() {
    this._subscribe.next(this._knowledge);
  }

  getArticleList() {
    this._subscribe.next(this._articles);
  }

  getArticle(id: number) {
    this._subscribe.next(this.article);
  }

}
