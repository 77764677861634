import { Injectable } from "@angular/core";
import { BaseSettingService } from "./base-setting.service";
import { GeneralSettingDto } from "../../shared/models/server/DataTransferObject/Objects/Settings";
import { HttpRequestService, AuthorizationService } from "..";


@Injectable()
export class GeneralSettingService extends BaseSettingService<GeneralSettingDto> {
    constructor(
        protected http: HttpRequestService,
        protected auth: AuthorizationService
    ) {
        super(http, auth, 1);
    }
}

