import * as moment from 'moment';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'localDateTime'
})
export class LocalDateTimePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value) {
      return moment.utc(value).local().format();
    } else {
      return '';
    }
  }

}
