import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { AuthorizationService } from '../../../services';
import { AlertService } from '../../../services/ui/alerts/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  regForm: FormGroup;
  loading: boolean = false;
  passwordChanged: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private authService: AuthorizationService) {

    let loginPassword = new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)]));
    let loginPasswordConfirm = new FormControl('', CustomValidators.equalTo(loginPassword));

    const userEmail = decodeURI(this.route.snapshot.queryParamMap.get('email'));

    this.regForm = fb.group({
      'email': [userEmail, Validators.compose([Validators.required, CustomValidators.email])],
      'password': loginPassword,
      'confirmPassword': loginPasswordConfirm
    });
  }

  back() {
    this.router.navigate(['/']);
  }

  submitForm($ev, form: FormGroup) {
    $ev.preventDefault();
    let value = form.value;
    for (let c in form.controls) {
      form.controls[c].markAsTouched();
    }
    if (form.valid) {

      const email = form.controls['email'].value;
      const confirmPassword = form.controls['confirmPassword'].value;
      const token = decodeURI(this.route.snapshot.queryParamMap.get('token'));

      this.loading = true;
      this.authService.resetPassword(email, confirmPassword, token).subscribe(async response => {
        this.loading = false;
        if (response instanceof HttpResponse) {
          if (response.status == 200) {
            this.loading = true;

            this.authService.clearToken();
            this.authService.login(email, btoa(confirmPassword))
              .then(() => {
                this.loading = false;
                this.router.navigate(['/']);
              })
              .catch((err) => {
                this.loading = false;
                this.router.navigate(['/signin'], { queryParams: { email: encodeURIComponent(email) } });
              });

          } else {
            console.error(response);
            await this.alertService.alert('LOGIN.RESETFAILED.TITLE', 'LOGIN.RESETFAILED.MSG');
          }
        }
      }, async err => {
        this.loading = false;
        console.error(err);
        await this.alertService.alert('LOGIN.RESETFAILED.TITLE', 'LOGIN.RESETFAILED.MSG');
      });
    }
  }
}
