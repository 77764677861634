import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import { distinct, map, mergeMap, shareReplay, toArray } from 'rxjs/operators';
import { LocalStorageKeys } from '../../shared/constants';
import { PermissionAreaDto, ResetPasswordDto, TokenDto } from '../../shared/models/server/DataTransferObject/Objects/Authentication';
import { AppRoleInfoDto, MeDto, TenantInfoDto } from '../../shared/models/server/DataTransferObject/Objects/MeObjects';
import { RoleGeneralTerms } from '../../shared/models/server/Enums';
import { AppPermissions } from '../../shared/models/server/Enums/Permissions';
import { HttpRequestLoginService } from '../http-request-login/http-request-login.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { PermissionService } from '../permission/permission.service';

const jwtHelper = new JwtHelperService();

export interface RoleTenantData {
  tenantId: number;
  tenantName?: string;
  tenantDomain?: string;
  id: number;
}
export interface SelectedRole extends AppRoleInfoDto, RoleTenantData {
  tenantId: number;
  tenantName?: string;
  tenantDomain?: string;
  allRole?: boolean;
}

@Injectable()
export class AuthorizationService {
  public loggedIn = false;
  public permissions: PermissionAreaDto[] = [];
  public token: string = null;
  public restrictedRoleids: number[] = [];
  public info: MeDto = null;
  public selectedTenant: RoleTenantData = null;

  public previousUrl: string;
  public ignoredUrls = ['/change-password', '/reset-password', '/auth-redirect', '/signin'];


  private changedRoleSubject: Subject<any> = new Subject();

  constructor(
    private storage: LocalStorageService,
    private router: Router,
    private loginService: HttpRequestLoginService,
    private permissionService: PermissionService
  ) {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .filter(event => !this.ignoredUrls.includes(event['url']))
      .subscribe(e => this.previousUrl = e['url']);
  }

  public onRoleChanged(): Observable<any> {
    return this.changedRoleSubject.asObservable();
  }

  public async login(email: string, passwordBase64: string): Promise<TokenDto> {
    let loginResponse = await this.loginService.post<TokenDto>('login/password', { email, passwordBase64 }).toPromise();
    return await this.handleLogin(loginResponse);
  }

  private async handleLogin(loginResponse: HttpResponse<TokenDto>): Promise<TokenDto> {
    if (loginResponse.status === 200) {
      const { token, refreshToken, restrictedRoleIds, info } = loginResponse.body;

      try {
        this.permissions = await this.checkPermissions(token);
      } catch (error) {
        throw { status: 403, message: "no permissions" };
      }

      this.filterRoles(info);

      if (loginResponse.body.restrictedRoleIds.length <= 0) {
        const refreshResponse = await this.restrictToken(loginResponse.body.info, loginResponse.body.refreshToken);
        return await this.handleLogin(refreshResponse);
      }

      this.saveToken(refreshToken);
      this.saveData(token, restrictedRoleIds, info);
      this.loggedIn = true;

      return Promise.resolve(loginResponse.body);
    } else {
      return Promise.reject(loginResponse);
    }
  }

  private async restrictToken(tokenInfo: MeDto, refreshToken: string) {
    const tenantWithRoles = tokenInfo.tenants.find(t => t.roles.length > 0);
    const restrictToRole = tenantWithRoles.roles.map(x => x.roleId);

    if (!restrictToRole || restrictToRole.length <= 0) {
      throw { status: 403, message: "could not find any role" };
    }

    const refreshResponse = new HttpResponse<TokenDto>({
      body: await this.refreshLogin(refreshToken, ...restrictToRole)
    });

    this.storage.set(LocalStorageKeys.selectedRoles, restrictToRole);
    this.saveData(refreshResponse.body.token, refreshResponse.body.restrictedRoleIds, refreshResponse.body.info);

    return refreshResponse;
  }

  public setPermissions(token): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.checkPermissions(token).then(permissions => {
        this.permissions = permissions;
        resolve();
      }).catch(reject);
    });
  }

  public saveData(token: string, restrictedRoleIds: number[], info: MeDto): void {
    this.token = token;
    this.restrictedRoleids = restrictedRoleIds;
    this.info = info;

    const roleIds = <number[]>this.storage.get(LocalStorageKeys.selectedRoles);

    if (roleIds && roleIds.length > 0) {
      const selectedTenant = info.tenants.find(x => x.roles.some(y => y.roleId === roleIds[0]));

      if (selectedTenant) {
        const selectedRoles = selectedTenant.roles.filter(x => roleIds.indexOf(x.roleId) > -1);

        if (selectedRoles.length == 1) {
          this.setSelectedTenant(selectedTenant, selectedRoles[0].roleId);
          return;
        } else if (selectedRoles.length > 1) {
          this.setSelectedTenant(selectedTenant);
          return;
        }
      }
    }

    this.storage.remove(LocalStorageKeys.selectedRoles);
    this.setSelectedTenant(info.tenants[0]);
  }

  private setSelectedTenant(tenant: TenantInfoDto, id?: number) {
    this.selectedTenant = <RoleTenantData>{
      tenantId: tenant.tenantId,
      tenantName: tenant.name,
      tenantDomain: tenant.domain,
      id: id || tenant.tenantId * -1
    };
    this.changedRoleSubject.next();
  }

  public async changeRole(...roleIds: number[]): Promise<void> {
    this.refreshLogin(this.storage.get(LocalStorageKeys.refreshToken), ...roleIds).then(async ({ token, restrictedRoleIds, info }) => {
      try {
        this.permissions = await this.checkPermissions(token);
      } catch (error) {
        this.logout();
      }

      this.filterRoles(info);

      let roleArray: SelectedRole[] = [];
      info.tenants.forEach((tenant: any) => roleArray.push(...tenant.roles.map(x => ({ ...x, tenantId: tenant.tenantId }))));
      roleArray = [...roleArray];
      this.selectedTenant = roleArray.find(x => x.roleId === roleIds[0]);

      this.storage.set(LocalStorageKeys.selectedRoles, roleIds);

      this.saveData(token, restrictedRoleIds, info);
      this.loggedIn = true;

      window.location.reload(true);
    });
  }

  public logout(): void {
    this.clearToken();
    this.loggedIn = false;
    this.router.navigate(['/signin']);
  }

  public refreshLogin$(refreshToken: string, ...roleIds: number[]): Observable<TokenDto> {
    return this.loginService.post<TokenDto>(
      'login/refresh',
      {
        refreshToken,
        roleIds
      },
      null,
      true
    ).pipe(
      map(response => response.body)
    );
  }

  public async refreshLogin(refreshToken: string, ...roleIds: number[]): Promise<TokenDto> {
    const refreshResponse = await this.loginService.post<TokenDto>(
      'login/refresh',
      {
        refreshToken,
        roleIds
      },
      null,
      true
    ).toPromise();

    if (refreshResponse.status && refreshResponse.status === 200) {
      return Promise.resolve(refreshResponse.body);
    } else {
      this.storage.remove(LocalStorageKeys.selectedRoles);
      return Promise.reject(null);
    }
  }

  public changePassword(email: string, password: string, confirmPassword: string): any {
    return this.loginService.post('login/changepassword', {
      email,
      passwordBase64: btoa(password),
      newPasswordBase64: btoa(confirmPassword)
    }, null, true);
  }

  public resetPassword(email: string, password: string, token: string): any {
    const body: ResetPasswordDto = {
      email: email,
      newPassword: null,
      token: token,
      newPasswordBase64: btoa(password)
    };
    return this.loginService.post('login/resetpassword', body);
  }

  public saveToken(refreshToken: string): void {
    this.storage.set(LocalStorageKeys.refreshToken, refreshToken);
  }

  public getToken(): string {
    return this.storage.get(LocalStorageKeys.refreshToken);
  }

  public clearToken(): void {
    this.storage.remove(LocalStorageKeys.refreshToken);
  }

  public async hasPermission(permission: AppPermissions, userPermissions: PermissionAreaDto[] = this.permissions): Promise<boolean> {
    return this.hasPermission$(permission, userPermissions).toPromise();
  }

  public hasPermission$(permission: AppPermissions, userPermissions: PermissionAreaDto[] = this.permissions): Observable<boolean> {
    return this.ownedPermissions(userPermissions).pipe(
      map(x => x.some(p => p == permission))
    );
  }

  private ownedPermissions(userPermissions: PermissionAreaDto[] = this.permissions): Observable<AppPermissions[]> {
    return this.permissionService.permissions$.pipe(
      map(x => x.filter(pp => userPermissions.some(a => a.permissions.some(ap => ap == pp.package)))),
      mergeMap(x => x.map(y => y.permissions as AppPermissions[])),
      mergeMap(x => x),
      distinct(),
      toArray(),
      shareReplay(1)
    );
  }

  private async checkPermissions(token: string): Promise<PermissionAreaDto[]> {
    const decodedToken: any = jwtHelper.decodeToken(token);
    const permissions: PermissionAreaDto[] = JSON.parse(decodedToken.permissions);

    if (await this.hasPermission(AppPermissions.DisplayBackend, permissions)) {
      return Promise.resolve(permissions);
    } else {
      throw new Error('NoPermission');
    }
  }

  public fetchUserMail(): string {
    try {
      const decodedToken = jwtHelper.decodeToken(this.token);
      return decodedToken['unique_name'];
    } catch (_) {
      return null;
    }
  }

  public filterRoles(info: MeDto): void {
    info.tenants.map(tenant => {
      tenant.roles = tenant.roles.filter(x => x.term !== RoleGeneralTerms.DeviceUser && x.term !== RoleGeneralTerms.User);
    });
  }

  public getSelectedRole() {
    return this.storage.get(LocalStorageKeys.selectedRoles);
  }
}
