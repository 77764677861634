import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { INgxSelectOption } from 'ngx-select-ex';
import { PagetitleService } from '../../core/services';
import { AuthorizationService, SelectedRole } from '../../services';
import { TenantInfoDto } from '../../shared/models/server/DataTransferObject/Objects/MeObjects';
import { SettingsService } from '../../shared/settings/settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.menu-links.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderComponent implements OnInit {
  sidebarVisible: true;
  private value: any = {};
  public currentRole: SelectedRole;
  public currentId;
  public tenants;

  constructor(
    public settings: SettingsService,
    public pt: PagetitleService,
    public authService: AuthorizationService,
    public router: Router,
    public translate: TranslateService
  ) {

    this.init();
  }

  private async init() {
    this.tenants = await this.getTenants();
    this.currentId = this.authService.selectedTenant.id;
    this.authService.onRoleChanged().subscribe(async () => {
      this.tenants = await this.getTenants();
    });
  }

  public selected(value: INgxSelectOption[]): void {
    if (!value || value.length != 1 || !value[0].data)
      return;

    const selected = <SelectedRole>value[0].data;

    if (!this.currentRole) {
      this.currentRole = selected;
      return;
    }

    if (this.currentRole.id == selected.id)
      return;

    this.currentRole = selected;

    if (selected.allRole) {
      const allRoleIds = this.authService.info.tenants
        .find(x => x.tenantId == selected.tenantId)
        .roles
        .map(r => r.roleId);

      this.authService.changeRole(...allRoleIds);
    } else {
      this.authService.changeRole(selected.roleId);
    }
  }

  public removed(value: any): void {
  }

  public typed(value: any): void {
  }

  public refreshValue(value: any): void {
  }

  searchTenantItem(search: string, item: INgxSelectOption): boolean {
    let itemtext = "";

    if (!search || search.length < 1)
      return true;

    if (item && item.data && item.data.name)
      itemtext += item.data.name.toLowerCase();

    if (item && item.data && item.data.tenantName)
      itemtext += item.data.tenantName.toLowerCase();

    return search.toLowerCase().split(' ').some(s => itemtext.includes(s));
  }

  logout() {
    this.authService.logout();
  }

  ngOnInit() {

  }

  toggleSidebarCoverModeVisible() {
    this.settings.app.sidebar.coverModeVisible = !this.settings.app.sidebar.coverModeVisible;
  }

  toggleSidebar(state = null) {
    //  state === true -> open
    //  state === false -> close
    //  state === null -> toggle
    this.settings.app.sidebar.visible = state !== null ? state : !this.settings.app.sidebar.visible;
  }

  openModalSearch() {

  }

  openModalBar() {

  }

  userSettings() {
    this.router.navigate(['/change-password']);
  }

  public isLoggedIn(): boolean {
    return this.authService.loggedIn;
  }

  public async getTenants(): Promise<TenantInfoDto[]> {
    const allText = await this.translate.get('LOGIN.ALL').toPromise();

    return this.authService.info.tenants.map(tenant => <TenantInfoDto>{
      ...tenant,
      roles: [
        <SelectedRole>{
          name: allText,
          allRole: true,
          id: tenant.tenantId * -1,
          tenantId: tenant.tenantId,
          tenantName: tenant.name
        },
        ...tenant.roles.map(role => <SelectedRole>{
          ...role,
          tenantId: tenant.tenantId,
          tenantName: tenant.name,
          id: role.roleId
        })
      ]
    });
  }
}
