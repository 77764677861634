import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signin-providers',
  templateUrl: './signin-providers.component.html',
  styleUrls: ['./signin-providers.component.scss']
})
export class SigninProvidersComponent implements OnInit {

  constructor(private router: Router) { }

  selectPassword() {
    this.router.navigate(['/signin']);
  }

  selectAzure() {

  }

  ngOnInit() {
  }
}

