import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-priority-dropdown',
  templateUrl: './priority-dropdown.component.html',
  styleUrls: ['./priority-dropdown.component.scss']
})
export class PriorityDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
