import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PermissionService } from '../services/permission/permission.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { ActionBarService } from './services/action-bar/action-bar.service';
import { BreadcrumbsService } from './services/breadcrumbs/breadcrumbs.service';
import { ColorsService } from './services/colors/colors.service';
// import { SharedModule } from '../shared/shared.module';
import { MenuService } from './services/menu/menu.service';
import { PagetitleService } from "./services/pagetitle/pagetitle.service";
import { TranslatorService } from "./services/translator/translator.service";
import { AlertBlockComponent } from './components/alert-block/alert-block.component';




@NgModule({
    imports: [
        // SharedModule
    ],
    providers: [
        MenuService,
        PagetitleService,
        TranslatorService,
        ColorsService,
        ActionBarService,
        BreadcrumbsService,
        PermissionService
    ],
    declarations: []
})
export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
